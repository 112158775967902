export enum ErrorType {
  NoError = 'NoError',
  CheckInTooEarly = 'CheckInTooEarly',
  NotFound = 'NotFound',
  Timeout = 'Timeout',
  DelayedFlightNotification = 'DelayedFlightNotification',
  WrongMilesNumber = 'WrongMilesNumber',
  ReferOutToAgent = 'ReferOutToAgent',
  ReferOutToAgentDontPrint = 'ReferOutToAgentDontPrint',
  FlightCancelled = 'FlightCanceled',
  CheckInTooLate = 'CheckInTooLate',
  CheckInTooLateWithPrintBoardingPass = 'CheckInTooLateWithPrintBoardingPass',
  TicketingIssue = 'TicketingIssue',
  PrintingIssue = 'PrintingIssue',
  BagTagPrintingIssue = 'BagTagPrintingIssue',
  DoNotPrintBoardingPass = 'DoNotPrintBoardingPass',
  SabreClosed = 'SabreClosed',
  PassportScan = 'PassportScan',
  RetryCard = 'RetryCard',
  SubsequentCardError = 'SubsequentCardError',
  PaymentCardError = 'PaymentCardError',
  PrintBagTagsError = 'PrintBagTagsError',
  GenerateBagTagError = 'GenerateBagTagError',
  GenerateDocumentError = 'GenerateDocumentError',
  FailedDocumentPrintError = 'FailedDocumentPrintError',
}

export interface InfoPiece {
  translationTag: string;
  params?: object;
}

export interface ErrorCodes {
  alertReasonCode?: string;
  alertMessageCode?: string;
  displayErrorCode: boolean;
}

export interface ButtonInfo {
  route?: string;
  translationTag?: string;
}

export interface ErrorModel {
  errorType: ErrorType;
  primaryButton: ButtonInfo;
  additionalButton?: ButtonInfo;
  info: Array<InfoPiece>;
  codes?: ErrorCodes;
  image?: object;
}
