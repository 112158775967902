import { TraceLevels } from '../../../../assets/js/embross-device-manager';
import {
  ATBPrinterStatusChange,
  ATBPrinterStatusIsOK,
  PectabFailed,
  PectabLoaded,
  PECTabLoadingComplete,
  TicketFailed,
  TicketPrinted,
  TicketPrintingComplete,
} from '../../../state/ha-cuss-device/atb-printer/atb-printer.actions';
import { leftToPrint, numberToPrint } from '../device.service';
import { take } from 'rxjs/operators';
import { currentlyPrinting, PRINTING_TYPES, socketAlive } from '../../emitters/session-event-emitters';
import { SetFailedDocumentPrintError, SetGenerateDocumentError } from 'src/app/state';
import { AppRoutes } from 'src/app/app-routing.module';

export function atbPrinterCallback(event) {
  this.applinkService
    .getDeviceManager()
    .sendLogMsg(
      TraceLevels.LOG_TRACE,
      'atbPrinterCallback: event.key ' + event.key + ' and value ' + JSON.stringify(event.value)
    );
  switch (event.key) {
    case 'pectabLoaded':
      this.atbPrinterStore.dispatch(new PectabLoaded(event.key, event.value));
      break;
    case 'pectabLoadingComplete':
      this.atbPrinterStore.dispatch(new PECTabLoadingComplete(event.key, event.value));
      break;
    case 'statusIsOK':
      this.atbPrinterStore.dispatch(new ATBPrinterStatusIsOK(event.key, event.value));
      break;
    case 'ticketPrinted':
      this.atbPrinterStore.dispatch(new TicketPrinted(event.key, event.value));
      this.logging.infoHardwareATBPrinterTicketPrinted(event.value);
      numberToPrint.emit(this.printRemaining - 1);
      break;
    case 'ticketPrintingComplete':
      this.atbPrinterStore.dispatch(new TicketPrintingComplete(event.key, event.value));
      leftToPrint.emit(0);
      break;
    case 'ticketFailed':
      this.atbPrinterStore.dispatch(new TicketFailed(event.key, event.value));
      this.logging.errorHardwareATBPrinterTicketFailed(event.value);
      currentlyPrinting.pipe(take(1)).subscribe((value) => {
        this.atbPrinterStore.dispatch(new SetGenerateDocumentError());
      });
      break;
    case 'pectabFailed':
      this.atbPrinterStore.dispatch(new PectabFailed(event.key, event.value));
      this.logging.errorHardwareATBPrinterPectabFailed(event.value);
      currentlyPrinting.pipe(take(1)).subscribe((value) => {
        this.atbPrinterStore.dispatch(new SetGenerateDocumentError());
      });
      break;
    case 'ticketRemoved':
      this.onTicketPrinting.emit(false);
      break;
    case 'statusChange':
      this.atbPrinterStore.dispatch(new ATBPrinterStatusChange(event.key, event.value));
      if (event.value[1] === false) {
        socketAlive.emit(true);
        this.enableATBPrinter();
      } else if (event.value[1] === true) {
        this.logging.errorHardwareATBPrinterError(event.value[0], event.value[1]);
        currentlyPrinting.pipe(take(1)).subscribe((value) => {
          this.atbPrinterStore.dispatch(new SetFailedDocumentPrintError());
        });
        if (this.router.url === AppRoutes.SPLASH_SCREEN) {
          socketAlive.emit(false);
        }
      }
      break;

    default:
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(TraceLevels.LOG_TRACE, 'default:atbPrinterCallback event' + event);
  }
}
