import { TraceLevels } from '../../../../assets/js/embross-device-manager';
import { HeadsetInserted, HeadsetRemoved, NavEnter, NavNext, NavPrevious } from '../../../state/';
import { SPEECH_STATUS, speechStatus } from '../device.service';

export function cussAccessibilityCallback(event) {
  this.applinkService
    .getDeviceManager()
    .sendLogMsg(TraceLevels.LOG_TRACE, 'cussAccessibilityCallback: event ' + event.key + ' and value ' + event.value);
  /**
   * This is where all of the accessibility events flow through. I wired up some basic NGRX events in case we decide
   * to use them.
   */
  switch (event.key) {
    // This event is triggered when an action is taken with headphones
    case 'enabled':
      switch (event.value) {
        // This event is triggered when the headphones are plugged in
        case true:
          this.cussAccessibilityStore.dispatch(new HeadsetInserted());
          this.enableCussAccessibilityKeyPad();
          break;
        // This event is triggered when the headphones are removed
        case false:
          this.cussAccessibilityStore.dispatch(new HeadsetRemoved());
          this.disableCussAccessibilityKeyPad();
          break;
      }
      break;
    // This event is triggered when an action is taken on the Keypad
    case 'keyPressed':
      switch (event.value) {
        // Up Arrow
        case 'NAVUP':
          // Unsure how to handle this action
          break;
        // Next Arrow
        case 'NAVNEXT':
          // Navigate to the NEXT relevant element
          this.cussAccessibilityStore.dispatch(new NavNext());
          break;
        // Down Arrow
        case 'NAVDOWN':
          // Unsure how to handle this action
          break;
        // Previous Arrow
        case 'NAVPREVIOUS':
          // Navigate to the PREVIOUS relevant element
          this.cussAccessibilityStore.dispatch(new NavPrevious());
          break;
        // Center Select
        case 'NAVENTER':
          // Select the current element
          this.cussAccessibilityStore.dispatch(new NavEnter());
          break;
      }
      break;
    case 'speechCompleted':
      speechStatus.next(SPEECH_STATUS.COMPLETED);
      break;
    default:
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(
          TraceLevels.LOG_TRACE,
          'default: cussAccessibilityCallback event ' + event.key + ' and value ' + JSON.stringify(event.value)
        );
  }
}
