import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
  CardReaderDisablePayment,
  CardReaderEnablePayment,
  Cart,
  AppState,
  selectCurrentTrip,
  Trip,
  selectCart,
} from '../../../state';
import { Logging } from '../../../services/logging/logging.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnDestroy, OnInit {
  cart$: Observable<Cart> = this.store.pipe(select(selectCart));
  trip$: Observable<Trip> = this.store.pipe(select(selectCurrentTrip));
  currency: String = 'USD';
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store<AppState>, private router: Router, private logging: Logging) {}

  ngOnInit(): void {
    this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      if (cart.grandTotal > 0) {
        this.store.dispatch(new CardReaderEnablePayment());
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new CardReaderDisablePayment());
  }

  modifyBaggageClicked() {
    this.logging.infoUiPaymentDueModifyBaggageSelected(0);
    this.router.navigate(['/bags']);
  }
}
