import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectCurrentTrip } from '../../state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isOhanaFlight = false;
  public tripSubscription;

  constructor(private store: Store<AppState>) {}

  ngOnDestroy() {
    if (this.tripSubscription) {
      this.tripSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.tripSubscription = this.store.pipe(select(selectCurrentTrip)).subscribe((trip) => {
      this.isOhanaFlight = trip.flights && trip.flights.length > 0 && trip.flights[0].isOaEmpire();
    });
  }
}
