import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState, selectCart, selectCurrentTrip, Trip } from 'src/app/state';
import { select, Store } from '@ngrx/store';
import { UpdateCartRequested } from 'src/app/state/cart/cart.actions';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { Cart } from 'src/app/state/cart/cart.model';
import { takeUntil } from 'rxjs/operators';
import { CartService } from 'src/app/services/api/cart/cart.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-bags-services',
  templateUrl: './bags-services.component.html',
  styleUrls: ['./bags-services.component.scss'],
})
export class BagsServicesComponent implements OnInit, OnDestroy {
  public showModal = false;
  public militaryModal = false;
  // placeholder for military bags
  private cart$: Observable<Cart>;
  private trip$: Observable<Trip>;
  public cart: Cart;
  public cartSubscription: Subscription;
  public tripSubscription: Subscription;
  unsubscribe$ = new Subject<void>();
  public tripId: string;
  @Input() totalOfBags: string;

  constructor(private store: Store<AppState>, private router: Router, private service: CartService) {}

  ngOnInit() {
    this.cart$ = this.store.pipe(select(selectCart));
    this.cartSubscription = this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      this.cart = cart;
    });
    this.trip$ = this.store.pipe(select(selectCurrentTrip));
    this.tripSubscription = this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.tripId = trip.id;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showMilitaryModal() {
    this.militaryModal = true;
  }

  sendBags() {
    if (this.cart && this.cart.items.length > 0) {
      this.store.dispatch(new UpdateCartRequested());
    }
  }

  displayModal(displayModal) {
    this.showModal = displayModal;
  }

  continue() {
    let cartItems = [];
    this.cart$ = this.store.pipe(select(selectCart));
    this.cartSubscription = this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      this.cart = cart;
      cartItems = this.cart.items.filter((item) => item.quantity > 0);
    });
    if (cartItems.length > 0) {
      this.service.updateCart(this.cart, this.tripId).then((data) => {
        this.cart = data.results[0];
        this.showModal = true;
      });
    } else {
      this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
    }
  }
}
