import { LoadingFlowActionTypes } from './loading-flow.action';

export interface LoadingFlowState {
  trips: boolean | false;
  flightStatus: boolean | false;
  tripauths: boolean | false;
  documents: boolean | false;
  hawaiianmiles: boolean | false;
  status: boolean | false;
  carts: boolean | false;
  checkout: boolean | false;
  catalogs: boolean | false;
  passport: boolean | false;
}

export const loadingFLowInitialState: LoadingFlowState = {
  trips: false,
  flightStatus: false,
  tripauths: false,
  documents: false,
  hawaiianmiles: false,
  status: false,
  carts: false,
  checkout: false,
  catalogs: false,
  passport: false,
};

export function loadingFlowReducer(state = loadingFLowInitialState, action): LoadingFlowState {
  switch (action.type) {
    case LoadingFlowActionTypes.ToggleState:
      for (const key in state) {
        if (state.hasOwnProperty(key)) {
          if (action.payload.url.match(key)) {
            state[key] = action.payload.state;
          }
        }
      }

      return {
        ...state,
      };

    default:
      return state;
  }
}
