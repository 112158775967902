import { Component, OnDestroy } from '@angular/core';
import { isCardInReaderTooLong } from '../../services/emitters/session-event-emitters';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-remove-card',
  templateUrl: './remove-card.component.html',
  styleUrls: ['./remove-card.component.scss'],
})
export class RemoveCardComponent implements OnDestroy {
  public timeLimitSubscribe: Subscription;

  public showPopup = false;

  constructor() {
    this.timeLimitSubscribe = isCardInReaderTooLong.subscribe((value) => {
      this.showPopup = value;
    });
  }

  ngOnDestroy() {
    if (this.timeLimitSubscribe) {
      this.timeLimitSubscribe.unsubscribe();
    }
  }
}
