import { Cart } from './cart.model';
import { CartActions, CartActionTypes } from './cart.actions';
import { CatalogIdTypes } from '../trip/passenger.catalog.model';

export interface CartState {
  cart: Cart;
}

export function cartReducer(state = { cart: new Cart() }, action: CartActions): CartState {
  switch (action.type) {
    case CartActionTypes.CartCreated: {
      const { cart } = action.payload;

      return { cart };
    }

    case CartActionTypes.RelatedCartRetrieved:
    case CartActionTypes.CartUpdated: {
      const { cart } = action.payload;

      return { ...state, cart };
    }

    case CartActionTypes.AddRegularBag: {
      const payload = action.payload;

      if (!state.cart.items) {
        state.cart.items = [];
      }
      const cartItems = state.cart.items;

      const bagItem = cartItems.find(
        (x) =>
          x.productId === payload.productId &&
          x.associatedPassenger.passengerId === payload.passengerId &&
          x.associatedPassenger.segmentId === payload.segmentId
      );

      if (bagItem) {
        bagItem.quantity++;
      } else {
        cartItems.push({
          productId: payload.productId,
          quantity: 1,
          catalogId: CatalogIdTypes.Bags,
          associatedPassenger: {
            passengerId: payload.passengerId,
            segmentId: payload.segmentId,
          },
        });
      }

      const { cart } = { cart: state.cart };

      return { ...state, cart };
    }

    case CartActionTypes.RemoveRegularBag: {
      const payload = action.payload;

      const cartItems = state.cart.items;

      const bagItem = cartItems.find(
        (x) =>
          x.productId === payload.productId &&
          x.associatedPassenger.passengerId === payload.passengerId &&
          x.associatedPassenger.segmentId === payload.segmentId
      );

      if (bagItem && bagItem.quantity > 0) {
        bagItem.quantity--;
      }

      const { cart } = { cart: state.cart };

      return { ...state, cart };
    }

    case CartActionTypes.PaxSelectedChangedForSpecialItems: {
      const { cart } = { cart: state.cart };
      cart.paxSelectedForSpecialItems = action.payload.passenger;

      return {
        ...state,
        cart,
      };
    }

    case CartActionTypes.SessionEnd: {
      const { cart } = { cart: state.cart };

      cart.sessionEnd = true;

      return { ...state, cart };
    }

    default: {
      return state;
    }
  }
}
