import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-table',
  templateUrl: './total-table.component.html',
  styleUrls: ['./total-table.component.scss', '../payment.component.scss'],
})
export class TotalTableComponent implements OnInit {
  public total;

  @Input() public currency: string;

  @Input() set cart(value) {
    this.total = value.grandTotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  constructor() {}

  ngOnInit() {}
}
