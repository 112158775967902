export interface Catalog {
  id: string;
  version: string;
  entries: PassengerCatalog[];
}

export enum CatalogIdTypes {
  Bags = 'MobileCheckInBags',
  Seats = 'MobileCheckInSeats',
}

interface PriceQuote {
  quantity: number | null;
  inventoryAvailable?: number | null;
  totalPrice: number | null;
}

export interface Product {
  productId: string | null;
  reasonForIssuanceCode?: string | null;
  reasonForIssuanceSubCode?: string | null;
  commercialName?: string | null;
  group?: string | null;
  type: string | null;
  freeItemCount?: number | null;
  priceQuotes: PriceQuote[] | null;
  availability?: string | null;
}

export class PassengerCatalog {
  flightId?: string | null;
  segmentId?: string | null;
  products: Product[] | null = [];

  static deserializeFromJson(json): PassengerCatalog {
    return Object.assign(new PassengerCatalog(), json);
  }
}
