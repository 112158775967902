import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';
import { AccessibilityService } from 'src/app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-no-self-tagging',
  templateUrl: './no-self-tagging.component.html',
  styleUrls: ['./no-self-tagging.component.scss', '../hazmat.component.scss'],
})
export class NoSelfTaggingComponent implements OnInit, AfterViewInit {
  constructor(public router: Router, private accessibilityService: AccessibilityService, private el: ElementRef) {}

  ngOnInit() {}

  continue() {
    this.router.navigate([AppRoutes.PRINT_DOCUMENTS]);
  }

  ngAfterViewInit(): void {
    this.accessibilityService.setChildElementsToRead(this.getElements());
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }
}
