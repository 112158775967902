import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { SelectLanguage } from '../../../../state/language/language.action';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { Logging } from 'src/app/services/logging/logging.service';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';
import { AccessibilityService } from 'src/app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-restricted',
  templateUrl: './restricted.component.html',
  styleUrls: ['./restricted.component.scss', '../hazmat.component.scss'],
})
export class RestrictedComponent implements AfterViewInit {
  constructor(
    private store: Store<AppState>,
    private logging: Logging,
    public router: Router,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {}

  selectLang(lang: string) {
    this.logging.infoUiHomePageLanguageChanged(lang, 0);
    this.store.dispatch(new SelectLanguage(lang));
  }

  continue() {
    this.router.navigate([AppRoutes.HAZMAT_NO_SELF_TAGGING]);
  }

  ngAfterViewInit(): void {
    const elements = this.el.nativeElement.querySelectorAll('[tabindex]');
    this.accessibilityService.setChildElementsToRead(elements);
  }
}
