import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { StateModule } from './state/state.module';
import { ApiModule } from './services/api/api.module';
import { LoggingModule } from './services/logging/logging.module';
import { LoadingService } from './services/ui/loading.service';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/routes/menu/menu.component';
import { ItineraryComponent } from './components/routes/itinerary/itinerary.component';
import { ItineraryFlightDetailsComponent } from './components/routes/itinerary/itinerary-flight-details/itinerary-flight-details.component';
// tslint:disable-next-line:max-line-length
import { ItineraryPassengerDetailsComponent } from './components/routes/itinerary/itinerary-passenger-details/itinerary-passenger-details.component';
import { ItineraryServicesComponent } from './components/routes/itinerary/itinerary-services/itinerary-services.component';

import { LocateReservationComponent } from './components/routes/locate-reservation/locate-reservation.component';
import { HazmatComponent } from './components/routes/hazmat/hazmat.component';

import { BackspaceComponent } from './shared/keyboard/backspace/backspace.component';
import { SpacebarComponent } from './shared/keyboard/spacebar/spacebar.component';
import { KeyboardComponent } from './shared/keyboard/keyboard.component';
import { ChangeLanguageComponent } from './components/routes/change-language/change-language.component';
import { ChangeLanguageButtonComponent } from './shared/change-language-button/change-language-button.component';
import { HeaderComponent } from './shared/header/header.component';
import { TimerDateComponent } from './shared/header/timer-date/timer-date.component';
import { StartOverComponent } from './shared/start-over/start-over.component';
import { RestrictedComponent } from './components/routes/hazmat/restricted/restricted.component';
import { ProhibitedComponent } from './components/routes/hazmat/prohibited/prohibited.component';
import { SelfTaggingComponent } from './components/routes/hazmat/self-tagging/self-tagging.component';
import { NoSelfTaggingComponent } from './components/routes/hazmat/no-self-tagging/no-self-tagging.component';
import { ErrorScreenComponent } from './components/routes/error-screen/error-screen.component';
import { ByConfirmationCodeComponent } from './components/routes/locate-reservation/by-confirmation-code/by-confirmation-code.component';
import { ByHawaiianMilesComponent } from './components/routes/locate-reservation/by-hawaiian-miles/by-hawaiian-miles.component';
import { ByETicketComponent } from './components/routes/locate-reservation/by-e-ticket/by-e-ticket.component';
import { ByFlightNumberComponent } from './components/routes/locate-reservation/by-flight-number/by-flight-number.component';
import { ClearComponent } from './shared/keyboard/clear/clear.component';
import { ByNameComponent } from './components/routes/locate-reservation/by-name/by-name.component';
import { ByBirthdayComponent } from './components/routes/locate-reservation/by-birthday/by-birthday.component';
// tslint:disable-next-line:max-line-length
import { LocateReservationTitleComponent } from './components/routes/locate-reservation/locate-reservation-title/locate-reservation-title.component';
// tslint:disable-next-line:max-line-length
import { LocateReservationTabsComponent } from './components/routes/locate-reservation/locate-reservation-tabs/locate-reservation-tabs.component';
import { GoBackButtonComponent } from './shared/go-back-button/go-back-button.component';
import { ConfirmationComponent } from './components/routes/confirmation/confirmation.component';
import { BagsPrintedConfirmationComponent } from './components/routes/confirmation/bags-printed/bags-printed.component';
import { NonPrintedBagsConfirmationComponent } from './components/routes/confirmation/non-printed-bags/non-printed-bags.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { PrintDocumentsComponent } from './components/routes/print-documents/print-documents.component';
import { ScrollableListComponent } from './shared/scrollable-list/scrollable-list.component';
import { HawaiianMilesEntryComponent } from './components/routes/pax-edit/hawaiian-miles-entry/hawaiian-miles-entry.component';
import { StepperComponent } from './shared/header/stepper/stepper.component';
import { TimeoutComponent } from './shared/timeout/timeout.component';
import { PaxEditMainComponent } from './components/routes/pax-edit/main/pax-edit-main.component';
import { SelectPaxComponent } from './components/routes/pax-edit/select-pax/select-pax.component';
import { KtnEntryComponent } from './components/routes/pax-edit/ktn-entry/ktn-entry.component';
import { RedressEntryComponent } from './components/routes/pax-edit/redress-entry/redress-entry.component';
import { CheckedInOptionsComponent } from './components/routes/checked-in-options/checked-in-options.component';
import { GridComponent } from './shared/grid/grid.component';
// tslint:disable-next-line:max-line-length
import { ConfirmationFlightStatusComponent } from './components/routes/confirmation/confirmation-flight-status/confirmation-flight-status.component';
// tslint:disable-next-line:max-line-length
import { HazmatAgricultureWarningComponent } from './components/routes/hazmat/hazmat-agriculture-warning/hazmat-agriculture-warning.component';
// tslint:disable-next-line:max-line-length
import { ConfirmationAgricultureWarningComponent } from './components/routes/confirmation/confirmation-agriculture-warning/confirmation-agriculture-warning.component';
import { SeatPreferenceComponent } from './components/routes/seat-preference/seat-preference.component';
import { MoreSegmentsCheckinComponent } from './components/routes/more-segments-checkin/more-segments-checkin.component';
import { FooterNavComponent } from './shared/footer-nav/footer-nav.component';
import { ExitButtonComponent } from './shared/exit-button/exit-button.component';
import { ErrorInterceptor } from './state/error/error-interceptor';
import { ByAirportCodeComponent } from './components/routes/locate-reservation/by-airport-code/by-airport-code.component';
import { ByDestinationComponent } from './components/routes/locate-reservation/by-destination/by-destination.component';
import { StandbyConfirmationComponent } from './components/routes/standby-confirmation/standby-confirmation.component';
import { FlightGridComponent } from './components/routes/seat-preference/flight-grid/flight-grid.component';
import { OutOfServiceComponent } from './shared/out-of-service/out-of-service.component';
import { ByBarcodeComponent } from './components/routes/locate-reservation/by-barcode/by-barcode.component';
import { SplashScreenComponent } from './components/routes/splash-screen/splash-screen.component';
import { DeviceService } from './services/ha-cuss/device.service';
import { AboutBagsComponent } from './components/routes/about-bags/about-bags.component';
import { BagsComponent } from './components/routes/bags/bags.component';
import { BagsHeaderComponent } from './components/routes/bags/bags-header/bags-header.component';
import { BagsServicesComponent } from './components/routes/bags/bags-services/bags-services.component';
import { BagsDetailsComponent } from './components/routes/bags/bags-details/bags-details.component';
import { BagsNoticeComponent } from './components/routes/bags/bags-notice/bags-notice.component';
import { RemoveCardComponent } from './shared/remove-card/remove-card.component';
import { PaymentComponent } from './components/routes/payment/payment.component';
import { PaxCartTableComponent } from './components/routes/payment/pax-cart-table/pax-cart-table.component';
import { TotalTableComponent } from './components/routes/payment/total-table/total-table.component';
import { ConfigService } from './services/api/config/config.service';
import { API } from './services/api/api.service';
import { environment } from '../environments/environment';
import { LibAngularPaymentModule } from 'lib-ha-angular-payment';
import { SessionEndModalComponent } from './shared/session-end-modal/session-end-modal.component';
import { PaymentInProgressAlertComponent } from './shared/payment-in-progress-alert/payment-in-progress-alert.component';
import { CheckInInProgressComponent } from './shared/checkin-in-progress/checkin-in-progress.component';
import { SpecialItemsComponent } from './components/routes/special-items/special-items.component';
import { SpecialItemsServicesComponent } from './components/routes/special-items/special-items-services/special-items-services.component';
import { SpecialItemsDetailsComponent } from './components/routes/special-items/special-items-details/special-items-details.component';
import { SpecialItemsHeaderComponent } from './components/routes/special-items/special-items-header/special-items-header.component';
import { RemovePassportComponent } from './shared/remove-passport/remove-passport.component';
import { WINDOW_PROVIDERS } from './utils/window';
import { ApplinkService } from './services/hardware/applink.service';
import { FocusAccessibilityDirective } from './directives/focus-accessibility.directive';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ItineraryComponent,
    ItineraryFlightDetailsComponent,
    ItineraryPassengerDetailsComponent,
    ItineraryServicesComponent,
    BackspaceComponent,
    HazmatComponent,
    KeyboardComponent,
    LocateReservationComponent,
    SpacebarComponent,
    ChangeLanguageComponent,
    ChangeLanguageButtonComponent,
    HeaderComponent,
    TimerDateComponent,
    StartOverComponent,
    RestrictedComponent,
    ProhibitedComponent,
    SelfTaggingComponent,
    NoSelfTaggingComponent,
    ErrorScreenComponent,
    StandbyConfirmationComponent,
    ByConfirmationCodeComponent,
    ByHawaiianMilesComponent,
    ByETicketComponent,
    ByFlightNumberComponent,
    ClearComponent,
    ByNameComponent,
    ByBirthdayComponent,
    LocateReservationTitleComponent,
    LocateReservationTabsComponent,
    GoBackButtonComponent,
    LoadingComponent,
    PrintDocumentsComponent,
    ConfirmationComponent,
    BagsPrintedConfirmationComponent,
    NonPrintedBagsConfirmationComponent,
    LoadingComponent,
    ScrollableListComponent,
    HawaiianMilesEntryComponent,
    StepperComponent,
    TimeoutComponent,
    PaxEditMainComponent,
    SelectPaxComponent,
    KtnEntryComponent,
    RedressEntryComponent,
    CheckedInOptionsComponent,
    GridComponent,
    ConfirmationFlightStatusComponent,
    HazmatAgricultureWarningComponent,
    ConfirmationAgricultureWarningComponent,
    OutOfServiceComponent,
    SeatPreferenceComponent,
    FooterNavComponent,
    MoreSegmentsCheckinComponent,
    ExitButtonComponent,
    ByAirportCodeComponent,
    ByDestinationComponent,
    FlightGridComponent,
    ByBarcodeComponent,
    SplashScreenComponent,
    AboutBagsComponent,
    BagsComponent,
    BagsHeaderComponent,
    BagsServicesComponent,
    BagsDetailsComponent,
    BagsNoticeComponent,
    RemoveCardComponent,
    PaymentComponent,
    PaxCartTableComponent,
    TotalTableComponent,
    SessionEndModalComponent,
    PaymentInProgressAlertComponent,
    CheckInInProgressComponent,
    SpecialItemsComponent,
    SpecialItemsServicesComponent,
    SpecialItemsDetailsComponent,
    SpecialItemsHeaderComponent,
    RemovePassportComponent,
    FocusAccessibilityDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    StateModule,
    ApiModule,
    LoggingModule,
    HttpClientModule,
    LibAngularPaymentModule.forRoot(environment),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    ApplinkService,
    LoadingService,
    DeviceService,
    API,
    ConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function ConfigLoader(configService: ConfigService) {
  return () => configService.getConfig();
}
