import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { KeyboardState } from '../../../state/keyboard/keyboard.reducer';
import { Keyboard } from '../../../state/keyboard/keyboard.model';
import { GoBack, SetContent } from '../../../state/keyboard/keyboard.action';

@Component({
  selector: 'backspace',
  templateUrl: './backspace.component.html',
  styleUrls: ['./backspace.component.scss'],
})
export class BackspaceComponent implements OnDestroy, OnInit, AfterViewInit {
  keyboard$: Observable<Keyboard>;

  public utilsValue = false;
  public entry = '';
  public goBackValue;
  public manageActiveValue;
  public keyboardSubscribe: Subscription;

  @Output() messageEvent = new EventEmitter<Array<ElementRef>>();

  constructor(private store: Store<KeyboardState>, private el: ElementRef) {}

  ngOnInit(): void {
    this.keyboard$ = this.store.pipe(select('keyboard'));
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      this.entry = value.content;
    });
  }

  @Input() set utils(value) {
    this.utilsValue = value;
  }

  @Input() set goBack(value) {
    this.goBackValue = value;
  }

  @Input() set manageActive(value) {
    this.manageActiveValue = value;
  }

  backspace() {
    if (this.entry !== undefined && this.entry.length < 1) {
      if (this.goBackValue) {
        this.store.dispatch(new GoBack());
      }
      return false;
    }
    this.store.dispatch(new SetContent(this.entry.substring(0, this.entry.length - 1)));
  }

  ngAfterViewInit(): void {
    this.messageEvent.emit(this.el.nativeElement.querySelectorAll('[tabindex]'));
  }

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
