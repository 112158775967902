import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/nx';
import { FlightStatusEffects, metaReducers, PaxEditEffects, reducers, TripEffects } from '.';
import { SegmentEffects } from './trip/segment/segment.effects';
import { PnrLocatorFlowEffects } from './pnr-locator-flow/pnr-locator-flow.effects';
import { ATBPrinterEffects } from './ha-cuss-device/atb-printer/atb-printer.effects';
import { BarcodeEffects } from './ha-cuss-device/barcode/barcode.effects';
import { DocumentEffects } from './document/document.effects';
import { PassportEffects } from './ha-cuss-device/passport/passport.effects';
import { CardReaderEffects } from './ha-cuss-device/card-reader/card-reader.effects';
import { LoadingStateEffects } from './loading-flow/loading-flow.effects';
import { CartEffects } from './cart/cart.effects';
import { BagTagPrinterEffects } from './ha-cuss-device/bagtag-printer/bagtag-printer.effects';
import { ClearStateEffects } from './clearstate/clearstate.effects';
import { CussAccessibilityEffects } from './ha-cuss-device/cuss-accessibility/cuss-accessibility.effects';

@NgModule({
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
    EffectsModule.forRoot([
      TripEffects,
      PaxEditEffects,
      FlightStatusEffects,
      SegmentEffects,
      LoadingStateEffects,
      PnrLocatorFlowEffects,
      BarcodeEffects,
      PassportEffects,
      ATBPrinterEffects,
      CardReaderEffects,
      BagTagPrinterEffects,
      CussAccessibilityEffects,
      DocumentEffects,
      CartEffects,
      ClearStateEffects,
    ]),
  ],
  declarations: [],
})
export class StateModule {}
