import { Injectable } from '@angular/core';

import { API } from '../api.service';
import { Trip } from '../../../state/trip/trip.model';
import { AuthTokenState, Passenger } from '../../../state';
import { ConfigService } from '../config/config.service';
import { PnrLocatorFlowState } from 'src/app/state/pnr-locator-flow/pnr-locator-flow.reducer';

@Injectable({ providedIn: 'root' })
export class TripService {
  public airportCode: string;
  private bagLineItemInfo;

  constructor(public api: API, private configService: ConfigService) {}

  setBagLineItemInfo(data) {
    this.bagLineItemInfo = data;
  }

  getBagLineItemInfo() {
    return this.bagLineItemInfo;
  }

  getTripDataBased(authToken: AuthTokenState, pnrLocator: PnrLocatorFlowState) {
    this.configService.config
      .subscribe((x) => {
        if (x && x.airportCode) {
          this.airportCode = x.airportCode;
        }
      })
      .unsubscribe();

    const token = 'Bearer ' + authToken.access_token;

    this.api.clearQueryParams();
    this.api.addDefaultHeaders(token);
    const hashParams = pnrLocator;
    let addedDateQueryParams = false;
    let addedAirportCode = false;

    for (const key in hashParams) {
      if (hashParams[key] === null || hashParams[key].length === 0 || key === 'lookupMethod') {
        continue;
      }

      this.api.appendQueryParams(this.api.apiQParams.trips[key].key, hashParams[key]);

      if (!addedDateQueryParams && this.api.shouldIncludeDateQueryParams(key)) {
        this.api.addDateQueryParams();
        addedDateQueryParams = true;
      }

      if (!addedAirportCode && this.api.shouldIncludeAirportCode(key)) {
        this.api.appendAirportCodeToQueryParams(this.airportCode);
        addedAirportCode = true;
      }
    }
    this.api.updateCorrelationId();
    return this.api.doGetCallTrips();
  }

  addMilesForPax(tripId: string, paxId: string, milesNo: string) {
    const url = `${this.api.getUrlToTrip()}/${tripId}/passengers`;

    const data = [
      {
        value: `${milesNo}`,
        path: `/passengers/${paxId}/hawaiianMilesNumber`,
        op: 'add',
      },
    ];

    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    this.api.updateCorrelationId(tripId);
    return this.api.doPatchCall(url, data).then((value) => (!value.type || value.count ? { paxId, milesNo } : value));
  }

  updatePassenger(
    tripId: string,
    passenger: Passenger,
    paxInfo: {
      knownTravelerNumber?: string;
      redressNumber?: string;
      hawaiianMilesNumber?: string;
    }
  ) {
    const updates = [];

    if (paxInfo.knownTravelerNumber && passenger.knownTravelerNumber !== paxInfo.knownTravelerNumber) {
      updates.push({
        value: paxInfo.knownTravelerNumber,
        path: `/passengers/${passenger.id}/knownTravelerNumber`,
        op: passenger.knownTravelerNumber ? 'replace' : 'add',
      });
    }

    if (paxInfo.redressNumber && passenger.redressNumber !== paxInfo.redressNumber) {
      updates.push({
        value: paxInfo.redressNumber,
        path: `/passengers/${passenger.id}/redressNumber`,
        op: passenger.redressNumber ? 'replace' : 'add',
      });
    }

    if (paxInfo.hawaiianMilesNumber && passenger.hawaiianMilesNumber !== paxInfo.hawaiianMilesNumber) {
      updates.push({
        value: paxInfo.hawaiianMilesNumber,
        path: `/passengers/${passenger.id}/hawaiianMilesNumber`,
        op: passenger.hawaiianMilesNumber ? 'replace' : 'add',
      });
    }

    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const url = `${this.api.getUrlToTrip()}/${tripId}/passengers`;
    this.api.updateCorrelationId(tripId);
    return this.api.doPatchCall(url, updates);
  }

  checkIn(trip: Trip) {
    if (trip.activeSegment.isOnMidPointJourney()) {
      return new Promise((checkInResult) => ({}));
    }

    const url = `${this.api.getUrlToTrip()}/${trip.id}`;

    const segmentId = trip.activeSegment.id;
    const paxIds = trip.activeSegment.getPaxIdsEligibleToCheckin();

    const data = paxIds
      .map((paxId) => `segments/${segmentId}/details/${paxId}/isCheckedIn`)
      .map((path) => {
        return {
          value: 'true',
          path,
          op: 'replace',
        };
      });

    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    this.api.updateCorrelationId(trip.id);
    return this.api.doPatchCall(url, data);
  }
}
