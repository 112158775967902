import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState, CartState, selectCurrentTrip, Trip } from 'src/app/state';
import { SelectLanguage } from 'src/app/state/language/language.action';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/api/config/config.service';
import { first, takeUntil } from 'rxjs/operators';
import { Cart } from 'src/app/state/cart/cart.model';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-session-end-modal',
  templateUrl: './session-end-modal.component.html',
  styleUrls: ['./session-end-modal.component.scss'],
})
export class SessionEndModalComponent implements OnInit, OnDestroy {
  private cart$: Observable<CartState>;
  public showModal = false;
  public config: any;
  private isMultiPax: boolean;
  public cartSubscription: Subscription;
  public configSubscription: Subscription;
  private clearStateSubscription: Subscription;
  private unsubscribe$ = new Subject<void>();
  public timer;
  public cart: Cart;
  public currentTrip: Trip;

  constructor(private router: Router, private store: Store<AppState>, private configService: ConfigService) {}

  ngOnInit() {
    this.cart$ = this.store.pipe(select('carts'));
    this.configSubscription = this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((config) => {
      if (config && config.configuration) {
        this.config = config;
        this.toggleModal();
      }
    });

    this.cartSubscription = this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      this.cart = state.cart;
      this.toggleModal();
    });
  }

  toggleModal() {
    if (!this.cart.sessionEnd || !this.config) {
      return false;
    }
    this.store
      .select(selectCurrentTrip)
      .pipe(first())
      .subscribe((trip) => {
        this.currentTrip = trip;
      });
    this.isMultiPax = this.currentTrip.passengers && this.currentTrip.passengers.length > 1;
    this.showModal = true;
    this.setTimer();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setTimer() {
    const timeout = this.getTimeout();
    this.timer = setTimeout(() => {
      this.doSessionEnd();
    }, timeout);
  }

  getTimeout() {
    let time = this.config.timeout.singlePaxSec;
    if (this.isMultiPax) {
      time = this.config.timeout.multiPaxSec;
    }
    return time * 1000;
  }

  doSessionEnd() {
    this.clearStateSubscription = of(this.store.dispatch({ type: 'clearState' }))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(new SelectLanguage('en'));
        this.router.navigate([AppRoutes.MENU]);
        this.showModal = false;
      });
  }
}
