import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum PRINTING_TYPES {
  NONE = 'NOT_PRINTING',
  BOARDING_PASS = 'BOARDING_PASS',
  BAG_TAG = 'BAG_TAG',
  RECEIPT = 'RECEIPT',
}

export const kioskId = new BehaviorSubject<string>('');
export const activeSession = new EventEmitter<boolean>();
export const isCardInReaderTooLong = new EventEmitter<boolean>();
export const paymentAttempts = new EventEmitter<number>();
export const currentlyPrinting = new BehaviorSubject<PRINTING_TYPES>(PRINTING_TYPES.NONE);
export const locatedPnr = new BehaviorSubject<boolean>(false);
export const isPassportInReaderTooLong = new EventEmitter<boolean>();
export const bagTagsPrinted = new BehaviorSubject<boolean>(false);
export const bagTagDataLoaded = new BehaviorSubject<boolean>(false);
export const socketAlive = new EventEmitter<boolean>();
export const almostOutOfTimeEvent = new EventEmitter<boolean>();
export const notificationTextEvent = new EventEmitter<string>();
export const notifyActiveEvent = new EventEmitter<boolean>();
export const loadKioskId = new EventEmitter<any>();
export const isAccessibilityMode = new BehaviorSubject<boolean>(false);
export const boardingPassDataLoaded = new BehaviorSubject<boolean>(false);
