import { Component } from '@angular/core';

@Component({
  selector: 'app-special-items',
  templateUrl: './special-items.component.html',
  styleUrls: ['./special-items.component.scss'],
})
export class SpecialItemsComponent {
  constructor() {}
}
