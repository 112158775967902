import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from '../api.service';
import { environment } from '../../../../environments/environment';
import { MachineConfig } from './config';
import { kioskId } from '../../emitters/session-event-emitters';

const defaultConfiguration = require('../../../../assets/config.json')[0];

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public config = new BehaviorSubject<MachineConfig | any>([]);
  private delay = 2000;
  private increment = 1000;
  private apiService: any;
  private delayMultiplier = 2;
  private numberOfRetries = 3;

  constructor(private injector: Injector) {}

  async getConfig() {
    if (!environment.cussEnabled && !environment.kioskId) {
      this.config.next(defaultConfiguration);
    } else {
      this.exponentialBackoff(this.numberOfRetries, this.delay);
    }
  }

  async getLauncher() {
    this.setApiService(kioskId.getValue());
    return this.apiService.doVersionCall();
  }

  exponentialBackoff(retries, delay) {
    this.validateKioskId(kioskId.getValue(), retries, delay);
  }

  validateKioskId(id, retries, delay) {
    id = environment.kioskId ? environment.kioskId : id;

    if (!id) {
      this.reloadAndRetry(retries, delay);
    } else {
      this.setApiService(id);
      this.apiService.doConfigCall().then((configuration) => {
        if (!configuration) {
          this.reloadAndRetry(retries, delay);
        } else {
          configuration.status ? this.reloadAndRetry(retries, delay) : this.config.next(configuration[0]);
        }
        return;
      });
    }
  }

  private setApiService(id) {
    this.apiService = this.injector.get(API);
    this.apiService.appendQueryParams(this.apiService.apiQParams.config.machineId.key, id);
  }

  reloadAndRetry(retries, delay) {
    if (this.numberOfRetries > 0) {
      setTimeout(() => {
        this.exponentialBackoff(--this.numberOfRetries, (delay + this.increment) * this.delayMultiplier);
      }, delay);
    } else {
      this.config.next(defaultConfiguration);
    }
  }

  public get configData(): Observable<any> {
    return this.config.asObservable();
  }
}
