import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { AppState, SegmentDetailAndPax, selectCart, Trip, selectCurrentTrip } from 'src/app/state';
import { AddRegularBag, PaxSelectedForSpecialItems, RemoveRegularBag } from 'src/app/state/cart/cart.actions';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from 'src/app/services/api/config/config.service';
import { isNumber } from 'util';
import { Cart, CartItem } from 'src/app/state/cart/cart.model';
import { CatalogIdTypes } from 'src/app/state/trip/passenger.catalog.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bags-details',
  templateUrl: './bags-details.component.html',
  styleUrls: ['./bags-details.component.scss'],
})
export class BagsDetailsComponent implements OnInit, OnDestroy {
  @Output() totalOfBagsChangedEvent = new EventEmitter<string>();
  private trip: Trip;
  private cart: Cart;
  public cart$: Observable<Cart>;
  public trip$: Observable<Trip>;
  public config$: Observable<any>;
  unsubscribe$ = new Subject<void>();
  private remainingFreeBags = false;
  private totalBags = 0;
  public segmentDetailsAndPax: SegmentDetailAndPax[] = [];
  public maxRegularBagsSupported: number;
  public bagGrandTotal;
  public bagsTotal;
  public totalNumberOfBags = 0;
  public totalNumberOfSeats = 0;
  public totalAdditionalItems = 0;
  enableSpecialItemsConfig: boolean;

  constructor(private store: Store<AppState>, private configService: ConfigService) {}

  ngOnInit() {
    this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      if (x.configuration) {
        this.enableSpecialItemsConfig = x.configuration.enableSpecialItems;
        this.maxRegularBagsSupported = x.configuration.maxRegularBagsSupported;
      }
    });

    this.cart$ = this.store.pipe(select(selectCart));
    this.cart$.pipe(takeUntil(this.unsubscribe$)).subscribe((cart: Cart) => {
      this.cart = cart;

      if (this.cart.items) {
        this.updateValues();
      }
    });

    this.trip$ = this.store.pipe(select(selectCurrentTrip));
    this.trip$.pipe(takeUntil(this.unsubscribe$)).subscribe((trip: Trip) => {
      this.trip = trip;
      this.segmentDetailsAndPax = this.trip.getSegmentDetailsAndPaxSelected();
      this.totalBagsChanged();
    });
    this.totalBagsChanged();
  }

  updateValues() {
    let value = 0;
    this.cart.items.map((item: CartItem) => {
      if (item.catalogId === CatalogIdTypes.Bags) {
        value += parseInt(item.price, 10);
      }
    });

    this.bagsTotal = value.toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    this.totalNumberOfBags = this.cart.items
      .filter((cartItem: CartItem) => cartItem.catalogId === CatalogIdTypes.Bags)
      .reduce((a, b) => a + b.quantity, 0);
    this.totalNumberOfSeats = this.cart.items
      .filter((cartItem: CartItem) => cartItem.catalogId === CatalogIdTypes.Seats)
      .reduce((a: number, b: CartItem) => a + b.quantity, 0);
    this.totalAdditionalItems = this.cart.items.reduce((a, b) => a + b.quantity, 0);

    if (this.cart.grandTotal > 0) {
      this.bagGrandTotal = this.cart.grandTotal.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      value = 0;

      this.bagGrandTotal = value.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  increaseRegularBags(segmentDetailAndPax) {
    if (!this.isMaximumValueOfRegularBags(segmentDetailAndPax)) {
      segmentDetailAndPax.segmentDetail.addRegularBag();

      this.addRegularBagToCartState(segmentDetailAndPax);
      this.totalBagsChanged();
    }
  }

  decreaseRegularBags(segmentDetailAndPax) {
    if (!this.isMinimumValueOfRegularBags(segmentDetailAndPax)) {
      segmentDetailAndPax.segmentDetail.removeRegularBag();

      this.removeRegularBagToCartState(segmentDetailAndPax);
      this.totalBagsChanged();
    }
  }

  isMaximumValueOfRegularBags(segmentDetailAndPax: SegmentDetailAndPax) {
    const bagsCatalog = segmentDetailAndPax.passenger.getRegularBagsCatalog();

    if (!bagsCatalog) {
      return;
    }

    let priceQuotes = bagsCatalog.priceQuotes;

    if (isNumber(this.maxRegularBagsSupported) && this.maxRegularBagsSupported > 0) {
      priceQuotes = priceQuotes.filter((x) => x.quantity <= this.maxRegularBagsSupported);
    }

    const quantitiesArray = priceQuotes.map((x) => x.quantity);
    const maximumAllowed = Math.max(...quantitiesArray);

    return segmentDetailAndPax.segmentDetail.getNumOfRegularBags() >= maximumAllowed;
  }

  isMinimumValueOfRegularBags(segmentDetailAndPax: SegmentDetailAndPax) {
    const allBags = segmentDetailAndPax.segmentDetail.getNumOfRegularBags();
    const minimumPossible = segmentDetailAndPax.segmentDetail.getPaidBagsCount() || 0;

    return allBags <= minimumPossible;
  }

  getTotalBags(): number {
    this.totalBags = this.segmentDetailsAndPax
      .map((segmentDetailAndPax) => {
        return segmentDetailAndPax.segmentDetail.getNumBags();
      })
      .reduce((sum: number, current: number) => sum + current, 0);

    return this.totalBags;
  }

  totalBagsChanged() {
    this.totalOfBagsChangedEvent.emit(this.getTotalBags().toString());
  }

  addRegularBagToCartState(segmentDetailAndPax: SegmentDetailAndPax) {
    this.store.dispatch(
      new AddRegularBag({
        productId: segmentDetailAndPax.passenger.getRegularBagsCatalog().productId,
        passengerId: segmentDetailAndPax.passenger.id,
        segmentId: segmentDetailAndPax.segmentDetail.segmentId,
      })
    );
  }

  removeRegularBagToCartState(segmentDetailAndPax: SegmentDetailAndPax) {
    this.store.dispatch(
      new RemoveRegularBag({
        productId: segmentDetailAndPax.passenger.getRegularBagsCatalog().productId,
        passengerId: segmentDetailAndPax.passenger.id,
        segmentId: segmentDetailAndPax.segmentDetail.segmentId,
      })
    );
  }

  goToSpecialItems(paxId) {
    this.store.dispatch(new PaxSelectedForSpecialItems({ passengerId: paxId }));
  }
}
