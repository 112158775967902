import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { AppState, TripActionTypes, UpdateTripDataRequested } from '../../../../state';
import { Store } from '@ngrx/store';
import { BagTagDataRequested } from '../../../../state/document/document.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityService } from 'src/app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-prohibited',
  templateUrl: './prohibited.component.html',
  styleUrls: ['./prohibited.component.scss', '../hazmat.component.scss'],
})
export class ProhibitedComponent implements OnInit, AfterViewInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private el: ElementRef,
    private accessibilityService: AccessibilityService
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTripDataRequested());
    this.actions$.pipe(takeUntil(this.unsubscribe$), ofType(TripActionTypes.TripLoaded)).subscribe(() => {
      // This call can take a while, so we can pre-load the response
      this.store.dispatch(new BagTagDataRequested());
    });
  }

  ngAfterViewInit(): void {
    const elements = this.getElements();
    this.accessibilityService.setChildElementsToRead(elements);
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
