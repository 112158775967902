import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { Logging } from '../../../../services/logging/logging.service';
import { Authenticate, KeyboardState, Keyboard, ResetContent, PnrLocatorFlowUpdateState } from '../../../../state';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-by-e-ticket',
  templateUrl: './by-e-ticket.component.html',
  styleUrls: ['./by-e-ticket.component.scss', './../locate-reservation.component.scss'],
})
export class ByETicketComponent implements OnInit, OnDestroy {
  public keyboardSubscribe: Subscription;
  keyboard$: Observable<Keyboard>;
  menuRoute = AppRoutes.MENU;

  public code = '';
  public codeDone = false;

  constructor(private store: Store<KeyboardState>, public router: Router, private logging: Logging) {
    store.dispatch(new ResetContent());
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnInit() {
    this.logging.infoUiEticketLookupPageDisplayed(0);
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      if (value) {
        this.code = value.content;
        this.codeDone = value.content.length === 13;
      }
    });
  }

  validateNumber() {
    if (!this.codeDone) {
      return false;
    }
    this.logging.infoUiEticketLookupPageContinueSelected(0);
    this.code = this.code.toUpperCase();
    this.store.dispatch(new PnrLocatorFlowUpdateState({ eTicketNumber: this.code, lookupMethod: 'ticketNumber' }));
    this.store.dispatch(new Authenticate({ eTicketNumber: this.code }));
  }

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
