/**
 * This will perform a search in an object or array and its elements.
 */
export const deepSearch = (
  searchFn: (key: string | number, value: any) => boolean,
  target: any,
  key?: string | number
): any => {
  if (target === null || target === undefined) {
    return target;
  }

  if (typeof key !== 'undefined') {
    if (searchFn(key, target)) {
      return target;
    }
  }

  if (target.constructor === Array) {
    const array = target as Array<any>;

    for (let i = 0; i < array.length; i++) {
      const found = deepSearch(searchFn, array[i], i);

      if (found) {
        return found;
      }
    }

    return null;
  }

  if (typeof target === 'object' && target !== null) {
    const keys = Object.keys(target);

    for (const currentKey of keys) {
      const found = deepSearch(searchFn, target[currentKey], currentKey);

      if (found) {
        return found;
      }
    }

    return null;
  }

  return null;
};

/**
 * This will apply a function in an object or array and its elements and replace them with the return values.
 */
export const deepEncode = (target: any, key?: string | number): any => {
  if (target === null || target === undefined) {
    return target;
  }

  if (key === 'dateOfBirth' || key === 'lastName') {
    return btoa(target);
  }

  if (target.constructor === Array) {
    const array = target as Array<any>;

    return array.map((value, index) => deepEncode(value, index));
  }

  if (typeof target === 'object' && target !== null) {
    const keys = Object.keys(target);
    const copy = {};

    for (const currentKey of keys) {
      copy[currentKey] = deepEncode(target[currentKey], currentKey);
    }

    return copy;
  }

  return target;
};
