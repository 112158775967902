import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state';
import { startSession } from '../../../app.component';
import { HaCussService } from '../../../services/ha-cuss/ha-cuss.service';
import { PnrLocatorFlowClearState } from '../../../state/pnr-locator-flow/pnr-locator-flow.action';
import { SelectLanguage } from '../../../state/language/language.action';
import { DeviceService } from '../../../services/ha-cuss/device.service';
import { CardReaderResetCount } from '../../../state/ha-cuss-device/card-reader/card-reader.actions';
import { ConfigService } from 'src/app/services/api/config/config.service';
import {
  activeSession,
  isCardInReaderTooLong,
  isPassportInReaderTooLong,
  locatedPnr,
} from '../../../services/emitters/session-event-emitters';
import { Logging } from '../../../services/logging/logging.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  shouldHideSplashScreen = false;
  interval;
  public enableBagsConfig: boolean;
  unsubscribe$ = new Subject<void>();
  menuRoute = AppRoutes.MENU;

  constructor(
    private haCussService: HaCussService,
    private deviceService: DeviceService,
    private store: Store<AppState>,
    private logging: Logging,
    public configService: ConfigService
  ) {
    this.store.dispatch(new PnrLocatorFlowClearState());
  }

  ngOnInit() {
    this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      if (x.configuration) {
        this.enableBagsConfig = x.configuration.enableBags;
      }
    });

    isCardInReaderTooLong.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.shouldHideSplashScreen = value;
    });

    isPassportInReaderTooLong.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.shouldHideSplashScreen = value;
    });

    activeSession.pipe(takeUntil(this.unsubscribe$)).subscribe((isActive) => {
      this.shouldHideSplashScreen = isActive;
    });

    startSession(this.logging);
    this.configService.getConfig();
    locatedPnr.next(false);

    this.store.dispatch(new SelectLanguage('en'));
    this.haCussService.clearBpData();
    this.haCussService.enableLookupDevices();
    this.store.dispatch(new CardReaderResetCount());
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    if (this.haCussService.isCurrentlyActive()) {
      this.haCussService.setActive();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
