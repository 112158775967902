import { IPaxDetails } from './device-interfaces/device-interfaces';
import * as dateFns from 'date-fns';
import { Router } from '@angular/router';

export class Parser {
  public static paxDetails: IPaxDetails = {
    documentType: '',
    lastName: '',
    firstName: '',
    middleName: '',
    number: '',
    issueCountry: '',
    nationality: '',
    dateOfBirth: '',
    expiryDate: '',
    gender: '',
    pnr: '',
  };

  constructor(private router: Router) {}

  public static resetPaxDetails() {
    Parser.paxDetails = {
      documentType: '',
      lastName: '',
      firstName: '',
      middleName: '',
      number: '',
      issueCountry: '',
      nationality: '',
      dateOfBirth: '',
      expiryDate: '',
      gender: '',
      pnr: '',
    };
  }

  public static getPNRFromBarcode(t1) {
    if (t1.length === 6) {
      return t1.substring(0, 6);
    } else if (t1.length > 50) {
      return t1.substring(23, 29);
    } else {
      return '';
    }
  }

  public static isContainsAlphaOnly(barcodeData) {
    if (barcodeData !== null && barcodeData !== '') {
      return barcodeData.match('^[a-zA-Z]+$');
    } else {
      return 'No Barcode Data';
    }
  }
  public static isValidInput(value: string | number): boolean {
    return value != null && /[A-Z|a-z|0-9]/.test(value as string);
  }

  public static isValidNumber(value: string | number): boolean {
    return value != null && /[A-Z|a-z|0-9]/.test('value');
  }

  private static processPassportTrack1(t1) {
    this.paxDetails.documentType = t1.substring(0, 1);
    this.paxDetails.issueCountry = t1.substring(2, 5);

    // last name
    let workingStr = t1.substring(5);
    let idx = workingStr.indexOf('<<');
    this.paxDetails.lastName = workingStr.substring(0, idx);

    // first name
    workingStr = workingStr.substring(idx + 2);
    idx = workingStr.indexOf('<');
    this.paxDetails.firstName = workingStr.substring(0, idx);

    // middle name
    workingStr = workingStr.substring(idx + 1);
    idx = workingStr.indexOf('<');
    if (idx === 0) {
      this.paxDetails.middleName = '';
    } else {
      this.paxDetails.middleName = workingStr.substring(0, idx);
    }
  }

  private static processPassportTrack2(t2) {
    this.paxDetails.number = t2.substring(0, 9);
    // just incase the passport number is less than 9 digit
    this.paxDetails.number = this.paxDetails.number.replace('<', '');
    // check digit
    this.paxDetails.nationality = t2.substring(10, 13);
    this.paxDetails.dateOfBirth = t2.substring(13, 19);
    // check digit
    this.paxDetails.gender = t2.substring(20, 21);
    this.paxDetails.expiryDate = t2.substring(21, 27);
  }

  public static getNameFromCC(t1, t2) {
    this.getNameFromCCTrack1(t1);
    if (t2.length > 25) {
      this.getCCFromTrack2(t2);
    }
    return this.paxDetails;
  }

  private static getNameFromCCTrack1(t1) {
    if (t1.length < 22) {
      return '';
    }

    let idx = t1.indexOf('^');
    if (idx <= 0) {
      return '';
    }

    const name = t1.substring(idx + 1);
    idx = name.indexOf('/');

    if (idx <= 0) {
      const idx1 = name.indexOf('^');
      this.paxDetails.firstName = name.substring(0, idx1);
    } else {
      this.paxDetails.lastName = name.substring(0, idx);
      const idx1 = name.indexOf('^');
      this.paxDetails.firstName = name.substring(idx + 1, idx1).trim();
    }
  }

  private static getCCFromTrack2(t2) {
    if (t2.length < 22) {
      return '';
    }
    const idx = t2.indexOf('=');

    if (idx <= 0) {
      return '';
    }

    this.paxDetails.number = t2.substring(0, idx);
    const month = t2.substring(idx + 3, idx + 5);
    const year = t2.substring(idx + 1, idx + 3);
    this.paxDetails.expiryDate = month + '' + year;
  }

  public static getPaxDetailFromPassport(t1, t2) {
    this.processPassportTrack1(t1);
    this.processPassportTrack2(t2);
    return this.validatePassport(this.paxDetails);
  }

  static validatePassport(paxDetailsToValidate: IPaxDetails) {
    const currentDate = dateFns.format(new Date(), 'YYYY-MM-DD');
    const INVALID_PASSPORT = 'Invalid Passport';

    // check documentType "P" char, stands for passport
    if (paxDetailsToValidate.documentType !== 'P') {
      return INVALID_PASSPORT;
    }

    if (!Parser.isContainsAlphaOnly(paxDetailsToValidate.gender) || paxDetailsToValidate.gender.length !== 1) {
      return INVALID_PASSPORT;
    }

    // Check First name and Last Name are alpha
    if (
      !Parser.isContainsAlphaOnly(paxDetailsToValidate.firstName) ||
      !Parser.isContainsAlphaOnly(paxDetailsToValidate.lastName)
    ) {
      return INVALID_PASSPORT;
    }

    // If middle name exist then check middle name is alpha
    if (paxDetailsToValidate.middleName !== null && !Parser.isContainsAlphaOnly(paxDetailsToValidate.middleName)) {
      return INVALID_PASSPORT;
    }

    // Check length of first name, middle name, and last name is <= 39 after
    // validation that all are characters
    const fullName = paxDetailsToValidate.firstName + paxDetailsToValidate.middleName + paxDetailsToValidate.lastName;
    if (fullName.length > 39) {
      return INVALID_PASSPORT;
    }

    // issue country: 3 char exact, alpha only
    if (
      !Parser.isContainsAlphaOnly(paxDetailsToValidate.issueCountry) ||
      paxDetailsToValidate.issueCountry.length !== 3
    ) {
      return INVALID_PASSPORT;
    }

    // nationality: 3 char exact, alpha only
    if (
      !Parser.isContainsAlphaOnly(paxDetailsToValidate.nationality) ||
      paxDetailsToValidate.nationality.length !== 3
    ) {
      return INVALID_PASSPORT;
    }

    // date of birth: 6 chars exact, numeric only
    if (!Parser.isValidNumber(paxDetailsToValidate.dateOfBirth) || paxDetailsToValidate.dateOfBirth.length !== 6) {
      return INVALID_PASSPORT;
    }

    // Passport number: 9 char max,
    // char could be numeric
    if (!Parser.isValidInput(paxDetailsToValidate.number) || paxDetailsToValidate.number.length > 9) {
      return INVALID_PASSPORT;
    }

    const fullDOB = dateFns.format(
      dateFns.subMonths(
        new Date(
          +paxDetailsToValidate.dateOfBirth.substr(0, 2),
          +paxDetailsToValidate.dateOfBirth.substr(2, 2),
          +paxDetailsToValidate.dateOfBirth.substr(4, 2)
        ),
        1
      ),
      'YYYY-MM-DD'
    );

    paxDetailsToValidate.dateOfBirth = fullDOB;

    // Check if passport DOB is greater than today
    if (new Date(fullDOB) > new Date(currentDate)) {
      return INVALID_PASSPORT;
    }

    return paxDetailsToValidate;
  }
}
