import { Component, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Passenger, PaxEditInfoUpdated, KeyboardState, ResetContent, selectCurrentPassenger } from '../../../../state';
import { Location } from '@angular/common';
import { Logging } from '../../../../services/logging/logging.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ktn-entry',
  templateUrl: './ktn-entry.component.html',
  styleUrls: ['./ktn-entry.component.scss'],
})
export class KtnEntryComponent implements OnInit {
  passenger$: Observable<Passenger>;
  public showModal = false;
  public keyboardSubscription: Subscription;
  public ktn = null;
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<KeyboardState>, private location: Location, private logging: Logging) {}

  ngOnInit() {
    this.logging.infoUiEnterKTNPageDisplayed(0);
    this.passenger$ = this.store.pipe(select(selectCurrentPassenger));
    this.store.dispatch(new ResetContent());
    this.validateKeyboardEntry();
  }

  validateKeyboardEntry() {
    this.keyboardSubscription = this.store
      .pipe(select('keyboard'))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.ktn = value.content;
      });
  }

  onContinueClick() {
    this.logging.infoUiEnterKTNPageContinueSelected(0);
    this.store.dispatch(new PaxEditInfoUpdated({ ktn: this.ktn }));
    this.location.back();
  }
}
