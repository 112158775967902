import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { LoadingService } from 'src/app/services/ui/loading.service';
import { bagTagsPrinted } from '../../../../services/emitters/session-event-emitters';
import {
  AppState,
  DisplayFlightInfoForKioskOrigin,
  FlightStatus,
  selectCurrentTripFlightStatus,
} from '../../../../state';
import { HaCussService } from '../../../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../../../services/logging/logging.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bags-printed',
  templateUrl: './bags-printed.component.html',
  styleUrls: ['./bags-printed.component.scss'],
})
export class BagsPrintedConfirmationComponent implements OnInit, OnDestroy, AfterContentInit {
  unsubscribe$ = new Subject<void>();
  flightStatus$: Observable<FlightStatus>;
  doneLoading = false;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private haCussService: HaCussService,
    public loadingService: LoadingService,
    protected logging: Logging
  ) {
    this.flightStatus$ = this.store.pipe(select(selectCurrentTripFlightStatus));
  }

  ngOnInit() {
    this.logging.infoUiCheckinComplete();
    this.store.dispatch(new DisplayFlightInfoForKioskOrigin());
  }

  ngAfterContentInit() {
    this.loadingService.loadingStatus.pipe(takeUntil(this.unsubscribe$)).subscribe((isLoading) => {
      if (isLoading) {
        return;
      }

      this.doneLoading = true;
    });
  }

  ngOnDestroy() {
    bagTagsPrinted.next(false);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.haCussService.enableLookupDevices();
    this.haCussService.setAvailable();
  }
}
