import Maybe from 'src/app/fixtures-unit-test/maybe';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-scrollable-list',
  templateUrl: './scrollable-list.component.html',
  styleUrls: ['./scrollable-list.component.scss'],
})
export class ScrollableListComponent implements OnInit {
  @Input() uiConfig;
  @Output() scrollUp = new EventEmitter<Maybe<null>>();
  @Output() scrollDown = new EventEmitter<Maybe<null>>();
  readonly config = {
    items: 1,
    startIndex: 0,
    endIndex: 1,
    MAX_ITEMS: 1,
  };
  constructor() {}

  ngOnInit() {}
  onScrollUp() {
    this.scrollUp.emit();
  }
  onScrollDown() {
    this.scrollDown.emit();
  }
}
