import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { bagTagsPrinted } from 'src/app/services/emitters/session-event-emitters';
import { ClearState } from 'src/app/state/clearstate/clearstate.actions';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../../services/ui/loading.service';
import { AppState } from '../../../state';
import { HaCussService } from '../../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../../services/logging/logging.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy, AfterContentInit {
  public clearStateSubscription: Subscription;
  public clearLoadingStatusSubscription: Subscription;
  public timeout;
  public bagsPrinted: boolean;
  private unsubscribe$ = new Subject<void>();
  private gifBagPrintedDuration = 18.5 * 1000;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private haCussService: HaCussService,
    public loadingService: LoadingService,
    protected logging: Logging
  ) {
    bagTagsPrinted.subscribe((result) => {
      this.bagsPrinted = result;
    });
  }

  ngOnInit() {
    this.logging.infoUiConfirmationPageDisplayed();
    this.logging.infoUiCheckinComplete();
  }

  ngAfterContentInit() {
    this.loadingService.loadingStatus.pipe(takeUntil(this.unsubscribe$)).subscribe((isLoading) => {
      if (!isLoading) {
        this.waitAndNavigateToSplashScreen();
      }
    });
  }

  private waitAndNavigateToSplashScreen() {
    setTimeout(() => {
      of(this.store.dispatch(new ClearState()))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          if (this.router.url === AppRoutes.CONFIRMATION) {
            this.router.navigate([AppRoutes.SPLASH_SCREEN]);
          }
        });
    }, this.gifBagPrintedDuration);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.haCussService.enableLookupDevices();
    this.haCussService.setAvailable();
  }
}
