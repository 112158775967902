import { Inject, Injectable } from '@angular/core';
import { LauncherResponse } from './config';
import { ConfigService } from './config.service';
import { version } from '../../../../../package.json';
import { WINDOW } from '../../../utils/window';
import { ErrorActionTypes } from '../../../state';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private currentHost: string;
  private proposedHost: string;
  private currentVersion: string;
  private proposedVersion: string;

  constructor(private configService: ConfigService, @Inject(WINDOW) private window: Window) {}

  checkForNewApp() {
    this.configService.getLauncher().then((response: LauncherResponse) => {
      this.currentHost = this.window.location.host;
      this.currentVersion = version;
      if (response.type !== ErrorActionTypes.SetTimeoutError) {
        this.proposedHost = response['launch-url'].split('/')[2];
        this.proposedVersion = response.version;
        if (this.currentHost !== this.proposedHost && this.proposedHost) {
          this.loadNewRoute(response);
        } else if (this.currentVersion < this.proposedVersion && this.proposedVersion) {
          this.loadNewVersion();
        }
      }
    });
  }

  private loadNewVersion() {
    this.window.location.assign(this.window.location.origin);
  }

  private loadNewRoute(response: LauncherResponse) {
    this.window.location.assign(response['launch-url']);
  }
}
