import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { environment } from '../../../../environments/environment';
import { startSession } from '../../../app.component';
import { HaCussService } from '../../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../../services/logging/logging.service';
import { SelectLanguage } from '../../../state/language/language.action';
import { PnrLocatorFlowClearState } from '../../../state/pnr-locator-flow/pnr-locator-flow.action';
import { isBarcodeAvailable, isPassportAvailable } from '../../../services/ha-cuss/device.service';
import {
  activeSession,
  isAccessibilityMode,
  locatedPnr,
  socketAlive,
} from '../../../services/emitters/session-event-emitters';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/api/config/config.service';
import { AccessibilityService } from '../../../services/accessibility/accessibility.service';
import { AccessibilityMode } from '../../../decorators/accessibility.decorator';
import { AppRoutes } from 'src/app/app-routing.module';
import { TranslateService } from '@ngx-translate/core';

@AccessibilityMode()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {
  public timeout;
  public validSession: false;
  public barcodeEnabled;
  public passportEnabled;
  public showSplashScreen: true;
  public versionNumber: string;
  public configSubscription: Subscription;
  public timeoutMenuConfig;
  locateReservationByBarcodeRoute = AppRoutes.LOCATE_RESERVATION_BARCODE;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private logging: Logging,
    private haCussService: HaCussService,
    public configService: ConfigService,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {
    this.store.dispatch(new PnrLocatorFlowClearState());
    activeSession.subscribe((isActive) => {
      this.validSession = isActive;
    });
    socketAlive.subscribe((isAlive) => {
      this.showSplashScreen = isAlive;
    });
    this.versionNumber = environment.version;
    this.barcodeEnabled = true;
    this.passportEnabled = true;
    this.configSubscription = this.configService.config.subscribe((x) => {
      if (x && x.configuration) {
        this.timeoutMenuConfig = x.timeout.menuSec;
      }
    });
  }

  @HostListener('document:click', ['$event.target'])
  public onClick() {
    this.setTimer();
  }

  ngOnInit() {
    locatedPnr.next(false);
    startSession(this.logging);
    this.configService.getConfig();
    this.haCussService.enableLookupDevices();
    isBarcodeAvailable.subscribe((value) => {
      this.barcodeEnabled = value;
    });
    isPassportAvailable.subscribe((value) => {
      this.passportEnabled = value;
    });
    this.haCussService.watchDevices();
    this.logging.resetSession();
    this.logging.infoUiHomePageDisplayed();
    if (this.haCussService.isCurrentlyActive()) {
      this.haCussService.setActive();
    }

    this.setTimer();

    this.haCussService.enableATBPrinter();
    this.haCussService.clearBpData();
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
    if (this.haCussService.isCurrentlyActive()) {
      this.haCussService.setActive();
    }
    this.haCussService.unwatchDevices();
  }

  ngAfterViewInit() {}

  setTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (!this.validSession || !isAccessibilityMode.getValue()) {
        this.router.navigate([AppRoutes.SPLASH_SCREEN]);
      }
    }, this.timeoutMenuConfig * 1000);
  }

  selectLang(lang: string) {
    this.logging.infoUiHomePageLanguageChanged(lang, 0);
    this.store.dispatch(new SelectLanguage(lang));
  }

  selectLookup(method: string) {
    this.logging.infoUiHomePageLookupSelected(method, 0);
  }
}
