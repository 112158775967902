export function AccessibilityMode(): ClassDecorator {
  return function(constructor: any) {
    const ngAfterViewInit = 'ngAfterViewInit';
    const original = constructor.prototype[ngAfterViewInit];
    constructor.prototype[ngAfterViewInit] = function(...args) {
      const elements = this.el.nativeElement.querySelectorAll('[tabindex]');
      this.accessibilityService.setElementsToRead(elements);
      original && original.apply(this, args);
    };
  };
}
