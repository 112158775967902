import * as dateFns from 'date-fns';
import { ErrorActionTypes } from './error.action';
import { ErrorModel, ErrorType } from './error.model';
import Maybe from '../../fixtures-unit-test/maybe';
import { AppRoutes } from 'src/app/app-routing.module';

export interface ErrorState {
  error: Maybe<ErrorModel>;
}

export const initialErrorState: ErrorState = {
  error: Maybe.nothing(),
};

export function errorReducer(state = initialErrorState, action): ErrorState {
  let displayErrorCode = action.payload && action.payload.alertReasonCode ? true : false;
  switch (action.type) {
    case ErrorActionTypes.SetError:
      return {
        error: Maybe.fromValue(action.payload),
      };

    case ErrorActionTypes.ClearError:
      return {
        error: Maybe.nothing(),
      };

    case ErrorActionTypes.SetReferToAgentError:
      const referOutToAgent: ErrorModel = {
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        errorType: ErrorType.ReferOutToAgent,
        info: [
          {
            translationTag: 'errors.referToAgent',
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.ReferOutToAgent,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(referOutToAgent),
      };

    case ErrorActionTypes.SetReferToAgentDontPrintError:
      const referOutToAgentDontPrint: ErrorModel = {
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        errorType: ErrorType.ReferOutToAgentDontPrint,
        info: [
          {
            translationTag: 'errors.referToAgent',
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.ReferOutToAgentDontPrint,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(referOutToAgentDontPrint),
      };

    case ErrorActionTypes.SetTicketingIssue:
      const ticketingIssueData: ErrorModel = {
        errorType: ErrorType.TicketingIssue,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.ticketing1',
            params: {},
          },
          {
            translationTag: 'errors.ticketing2',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.TicketingIssue,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(ticketingIssueData),
      };

    case ErrorActionTypes.SetNotFoundError:
      const notFound: ErrorModel = {
        errorType: ErrorType.NotFound,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.notFound1',
            params: {},
          },
          {
            translationTag: 'errors.notFound2',
            params: {},
          },
          {
            translationTag: 'errors.notFound3',
            params: { entry: action.payload.entry },
          },
          {
            translationTag: 'errors.notFound4',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.NotFound,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(notFound),
      };

    case ErrorActionTypes.SetDelayedFlightNotification:
      const okRoute = action.payload.okRoute || 'goBack';
      const delayedFlight: ErrorModel = {
        errorType: ErrorType.DelayedFlightNotification,
        primaryButton: { route: okRoute.toString(), translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.delayedFlight1',
          },
          {
            translationTag: 'errors.delayedFlight2',
          },
          {
            translationTag: 'errors.delayedFlight3',
            params: {
              departureDate: dateFns
                .format(action.payload.departureDate, 'MMM-DD h:mmA')
                .toUpperCase()
                .slice(0, -1),
            },
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.DelayedFlightNotification,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(delayedFlight),
      };

    case ErrorActionTypes.SetWrongMilesError:
      const wrongMiles: ErrorModel = {
        errorType: ErrorType.WrongMilesNumber,
        primaryButton: { route: AppRoutes.PAX_EDIT_MAIN, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.wrongMiles1',
          },
          {
            translationTag: 'errors.wrongMiles2',
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.WrongMilesNumber,
          displayErrorCode: displayErrorCode,
        },
      };
      return {
        error: Maybe.fromValue(wrongMiles),
      };

    case ErrorActionTypes.SetCancelledFlightError:
      const cancelledFlight: ErrorModel = {
        errorType: ErrorType.FlightCancelled,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.cancelledFlight',
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.FlightCancelled,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(cancelledFlight),
      };

    case ErrorActionTypes.SetTooEarlyError:
      const tooEarly: ErrorModel = {
        errorType: ErrorType.CheckInTooEarly,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.tooEarlyToCheckin',
            params: {
              checkInDate: dateFns.format(action.payload.checkInDate, 'h:mm A, DD MMM YYYY'),
            },
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.CheckInTooEarly,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(tooEarly),
      };

    case ErrorActionTypes.SetTooLateError:
      const tooLate: ErrorModel = {
        errorType: ErrorType.CheckInTooLate,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.tooLateToCheckin1',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.CheckInTooLate,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(tooLate),
      };

    case ErrorActionTypes.SetTooLateWithPrintBoardingPassError:
      const tooLateWithPrintBoardingPassError: ErrorModel = {
        errorType: ErrorType.CheckInTooLateWithPrintBoardingPass,
        primaryButton: { route: AppRoutes.SPLASH_SCREEN, translationTag: 'errorScreen.buttons.exit' },
        additionalButton: {
          route: AppRoutes.HAZMAT_PROHIBITED,
          translationTag: 'errorScreen.buttons.printBoardingPass',
        },
        info: [
          {
            translationTag: 'errors.tooLateToCheckinWithPrintBoardingPass',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.CheckInTooLateWithPrintBoardingPass,
          displayErrorCode: false,
        },
      };

      return {
        error: Maybe.fromValue(tooLateWithPrintBoardingPassError),
      };

    case ErrorActionTypes.SetTimeoutGoToItineraryError:
      const timeOutGoBack: ErrorModel = {
        errorType: ErrorType.Timeout,
        primaryButton: { route: AppRoutes.ITINERARY, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.timeOut1',
            params: {},
          },
          {
            translationTag: 'errors.timeOut2',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.Timeout,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(timeOutGoBack),
      };

    case ErrorActionTypes.SetTimeoutError:
      const timeOut: ErrorModel = {
        errorType: ErrorType.Timeout,
        primaryButton: { route: AppRoutes.SPLASH_SCREEN, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.timeOut1',
            params: {},
          },
          {
            translationTag: 'errors.timeOut2',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.Timeout,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(timeOut),
      };

    case ErrorActionTypes.SetBoardingPassError:
      const boardingPassError: ErrorModel = {
        errorType: ErrorType.DoNotPrintBoardingPass,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.printingIssue',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.DoNotPrintBoardingPass,
          displayErrorCode: displayErrorCode,
        },
      };
      return {
        error: Maybe.fromValue(boardingPassError),
      };

    case ErrorActionTypes.SetPrintingIssue:
      const printingIssue: ErrorModel = {
        errorType: ErrorType.PrintingIssue,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.printingIssue',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.PrintingIssue,
          displayErrorCode: displayErrorCode,
        },
      };

      return {
        error: Maybe.fromValue(printingIssue),
      };

    case ErrorActionTypes.SetBagTagPrintingIssue:
      const bagTagPrintingIssue: ErrorModel = {
        errorType: ErrorType.BagTagPrintingIssue,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.bagTagPrintingIssue',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.BagTagPrintingIssue,
          displayErrorCode: displayErrorCode,
        },
      };
      return {
        error: Maybe.fromValue(bagTagPrintingIssue),
      };

    case ErrorActionTypes.SetPassportScanError:
      const passportScanError: ErrorModel = {
        errorType: ErrorType.PassportScan,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        image: {
          url: '/assets/gifs/gif-passport-scan-error.gif',
          alt: 'Passport scan alt',
        },
        info: [
          {
            translationTag: 'errors.passportScanError1',
            params: {},
          },
          {
            translationTag: 'errors.passportScanError2',
            params: {},
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.PassportScan,
          displayErrorCode: displayErrorCode,
        },
      };
      return {
        error: Maybe.fromValue(passportScanError),
      };

    case ErrorActionTypes.SetRetryCardError:
      const retryCardError: ErrorModel = {
        errorType: ErrorType.RetryCard,
        primaryButton: { route: 'cardSwipe', translationTag: 'errorScreen.common' },
        image: {
          url: '/assets/gifs/gif-v1Standard-InsertCard.gif',
          alt: 'Insert Card alt',
        },
        info: [
          {
            translationTag: 'errors.retryCard',
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.RetryCard,
          displayErrorCode: displayErrorCode,
        },
      };
      return {
        error: Maybe.fromValue(retryCardError),
      };

    case ErrorActionTypes.SetSubsequentCardError:
      const subsequentCardError: ErrorModel = {
        errorType: ErrorType.SubsequentCardError,
        primaryButton: { route: 'cardSwipe', translationTag: 'errorScreen.common' },
        image: {
          url: '/assets/gifs/gif-v1Standard-InsertCard.gif',
          alt: 'Insert Card alt',
        },
        info: [
          {
            translationTag: 'errors.subsequentCardError',
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.SubsequentCardError,
          displayErrorCode: displayErrorCode,
        },
      };
      return {
        error: Maybe.fromValue(subsequentCardError),
      };

    case ErrorActionTypes.SetPaymentCardError:
      const setPaymentCardError: ErrorModel = {
        errorType: ErrorType.PaymentCardError,
        primaryButton: { route: 'paymentError', translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.paymentCardError',
          },
        ],
        codes: {
          alertReasonCode: displayErrorCode ? action.payload.alertReasonCode : undefined,
          alertMessageCode: ErrorType.PaymentCardError,
          displayErrorCode: displayErrorCode,
        },
      };
      return {
        error: Maybe.fromValue(setPaymentCardError),
      };

    case ErrorActionTypes.SetPrintBagTagsError:
      const setPrintBagTagsError: ErrorModel = {
        errorType: ErrorType.PrintBagTagsError,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.unableToPrintYourDocuments',
          },
        ],
        codes: {
          alertReasonCode: 'BT01',
          alertMessageCode: 'UnableToPrintDocuments',
          displayErrorCode: true,
        },
      };
      return {
        error: Maybe.fromValue(setPrintBagTagsError),
      };

    case ErrorActionTypes.SetGenerateBagTagError:
      const setGenerateBagTagError: ErrorModel = {
        errorType: ErrorType.GenerateBagTagError,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.unableToPrintYourDocuments',
          },
        ],
        codes: {
          alertReasonCode: 'BT02',
          alertMessageCode: 'UnableToPrintDocuments',
          displayErrorCode: true,
        },
      };
      return {
        error: Maybe.fromValue(setGenerateBagTagError),
      };

    case ErrorActionTypes.SetGenerateDocumentError:
      const setGenerateDocumentError: ErrorModel = {
        errorType: ErrorType.GenerateDocumentError,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.unableToPrintYourDocuments',
          },
        ],
        codes: {
          alertReasonCode: 'PR01',
          alertMessageCode: 'UnableToPrintDocuments',
          displayErrorCode: true,
        },
      };
      return {
        error: Maybe.fromValue(setGenerateDocumentError),
      };

    case ErrorActionTypes.SetFailedDocumentPrintError:
      const setFailedDocumentPrintError: ErrorModel = {
        errorType: ErrorType.FailedDocumentPrintError,
        primaryButton: { route: AppRoutes.MENU, translationTag: 'errorScreen.common' },
        info: [
          {
            translationTag: 'errors.unableToPrintYourDocuments',
          },
        ],
        codes: {
          alertReasonCode: 'PR02',
          alertMessageCode: 'UnableToPrintDocuments',
          displayErrorCode: true,
        },
      };
      return {
        error: Maybe.fromValue(setFailedDocumentPrintError),
      };

    default:
      return state;
  }
}
