import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-change-language-button',
  templateUrl: './change-language-button.component.html',
  styleUrls: ['./change-language-button.component.scss'],
})
export class ChangeLanguageButtonComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  onButtonClick() {
    this.router.navigate([AppRoutes.CHANGE_LANGUAGE]);
  }
}
