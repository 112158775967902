import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ItineraryComponent } from './components/routes/itinerary/itinerary.component';
import { MenuComponent } from './components/routes/menu/menu.component';

import { ChangeLanguageComponent } from './components/routes/change-language/change-language.component';
import { ErrorScreenComponent } from './components/routes/error-screen/error-screen.component';
import { HazmatComponent } from './components/routes/hazmat/hazmat.component';
import { RestrictedComponent } from './components/routes/hazmat/restricted/restricted.component';
import { ProhibitedComponent } from './components/routes/hazmat/prohibited/prohibited.component';
import { SelfTaggingComponent } from './components/routes/hazmat/self-tagging/self-tagging.component';
import { NoSelfTaggingComponent } from './components/routes/hazmat/no-self-tagging/no-self-tagging.component';
import { ByConfirmationCodeComponent } from './components/routes/locate-reservation/by-confirmation-code/by-confirmation-code.component';
import { ByETicketComponent } from './components/routes/locate-reservation/by-e-ticket/by-e-ticket.component';
import { ByHawaiianMilesComponent } from './components/routes/locate-reservation/by-hawaiian-miles/by-hawaiian-miles.component';
import { ByFlightNumberComponent } from './components/routes/locate-reservation/by-flight-number/by-flight-number.component';
import { ByNameComponent } from './components/routes/locate-reservation/by-name/by-name.component';
import { ByBirthdayComponent } from './components/routes/locate-reservation/by-birthday/by-birthday.component';
import { LocateReservationComponent } from './components/routes/locate-reservation/locate-reservation.component';
import { PrintDocumentsComponent } from './components/routes/print-documents/print-documents.component';
import { ConfirmationComponent } from './components/routes/confirmation/confirmation.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { PaxEditMainComponent } from './components/routes/pax-edit/main/pax-edit-main.component';
import { SelectPaxComponent } from './components/routes/pax-edit/select-pax/select-pax.component';
import { RedressEntryComponent } from './components/routes/pax-edit/redress-entry/redress-entry.component';
import { KtnEntryComponent } from './components/routes/pax-edit/ktn-entry/ktn-entry.component';
import { HawaiianMilesEntryComponent } from './components/routes/pax-edit/hawaiian-miles-entry/hawaiian-miles-entry.component';
import { CheckedInOptionsComponent } from './components/routes/checked-in-options/checked-in-options.component';
import { OutOfServiceComponent } from './shared/out-of-service/out-of-service.component';
import { SeatPreferenceComponent } from './components/routes/seat-preference/seat-preference.component';
import { StandbyConfirmationComponent } from './components/routes/standby-confirmation/standby-confirmation.component';
import { MoreSegmentsCheckinComponent } from './components/routes/more-segments-checkin/more-segments-checkin.component';
import { ByAirportCodeComponent } from './components/routes/locate-reservation/by-airport-code/by-airport-code.component';
import { ByDestinationComponent } from './components/routes/locate-reservation/by-destination/by-destination.component';
import { ByBarcodeComponent } from './components/routes/locate-reservation/by-barcode/by-barcode.component';
import { SplashScreenComponent } from './components/routes/splash-screen/splash-screen.component';
import { BagsComponent } from './components/routes/bags/bags.component';
import { AboutBagsComponent } from './components/routes/about-bags/about-bags.component';
import { PaymentComponent } from './components/routes/payment/payment.component';
import { SpecialItemsComponent } from './components/routes/special-items/special-items.component';
import { BagsPrintedConfirmationComponent } from './components/routes/confirmation/bags-printed/bags-printed.component';
import { NonPrintedBagsConfirmationComponent } from './components/routes/confirmation/non-printed-bags/non-printed-bags.component';

const routes: Routes = [
  { path: '', redirectTo: '/splash-screen', pathMatch: 'full' },
  { path: 'menu', component: MenuComponent },
  {
    path: 'itinerary',
    component: ItineraryComponent,
  },
  { path: 'error-screen', component: ErrorScreenComponent },
  { path: 'print-documents', component: PrintDocumentsComponent },
  {
    path: 'locate-reservation',
    component: LocateReservationComponent,
    children: [
      { path: 'confirmation-code', component: ByConfirmationCodeComponent },
      { path: 'e-ticket', component: ByETicketComponent },
      { path: 'hawaiian-miles', component: ByHawaiianMilesComponent },
      { path: 'flight-number', component: ByFlightNumberComponent },
      { path: 'last-name', component: ByNameComponent },
      { path: 'birthday', component: ByBirthdayComponent },
      { path: 'airport-code', component: ByAirportCodeComponent },
      { path: 'destination', component: ByDestinationComponent },
      { path: 'barcode', component: ByBarcodeComponent },
    ],
  },
  { path: 'bags', component: BagsComponent },
  { path: 'splash-screen', component: SplashScreenComponent },
  { path: 'seat-preference', component: SeatPreferenceComponent },
  { path: 'change-language', component: ChangeLanguageComponent },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    children: [
      { path: 'bags-printed', component: BagsPrintedConfirmationComponent },
      {
        path: 'non-printed-bags',
        component: NonPrintedBagsConfirmationComponent,
      },
    ],
  },
  { path: 'standby-confirmation', component: StandbyConfirmationComponent },
  { path: 'loading', component: LoadingComponent },
  { path: 'options', component: CheckedInOptionsComponent },
  { path: 'out-of-service', component: OutOfServiceComponent },
  { path: 'more-segments-checkin', component: MoreSegmentsCheckinComponent },
  {
    path: 'hazmat',
    component: HazmatComponent,
    children: [
      { path: 'prohibited', component: ProhibitedComponent },
      { path: 'restricted', component: RestrictedComponent },
      { path: 'self-tagging', component: SelfTaggingComponent },
      { path: 'no-self-tagging', component: NoSelfTaggingComponent },
    ],
  },
  {
    path: 'pax-edit',
    children: [
      { path: 'select-pax', component: SelectPaxComponent },
      { path: 'main', component: PaxEditMainComponent },
      { path: 'ktn', component: KtnEntryComponent },
      { path: 'redress', component: RedressEntryComponent },
      { path: 'hawaiian-miles', component: HawaiianMilesEntryComponent },
    ],
  },
  { path: 'about-bags', component: AboutBagsComponent },
  { path: 'special-items', component: SpecialItemsComponent },
  { path: 'payment', component: PaymentComponent },
];

export const enum AppRoutes {
  SPLASH_SCREEN = '/splash-screen',
  MENU = '/menu',
  ITINERARY = '/itinerary',
  ERROR_SCREEN = '/error-screen',
  PRINT_DOCUMENTS = '/print-documents',
  LOCATE_RESERVATION = '/locate-reservation',
  LOCATE_RESERVATION_CONFIRMATION_CODE = '/locate-reservation/confirmation-code',
  LOCATE_RESERVATION_E_TICKET = '/locate-reservation/e-ticket',
  LOCATE_RESERVATION_HAWAIIAN_MILES = '/locate-reservation/hawaiian-miles',
  LOCATE_RESERVATION_FLIGHT_NUMBER = '/locate-reservation/flight-number',
  LOCATE_RESERVATION_LAST_NAME = '/locate-reservation/last-name',
  LOCATE_RESERVATION_BIRTHDAY = '/locate-reservation/birthday',
  LOCATE_RESERVATION_AIRPORT_CODE = '/locate-reservation/airport-code',
  LOCATE_RESERVATION_DESTINATION = '/locate-reservation/destination',
  LOCATE_RESERVATION_BARCODE = '/locate-reservation/barcode',
  BAGS = '/bags',
  SEAT_PREFERENCE = '/seat-preference',
  CHANGE_LANGUAGE = '/change-language',
  CONFIRMATION = '/confirmation',
  CONFIRMATION_BAGS_PRINTED = '/confirmation/bags-printed',
  CONFIRMATION_NO_PRINTED_BAGS = '/confirmation/non-printed-bags',
  STANDBY_CONFIRMATION = '/standby-confirmation',
  LOADING = '/loading',
  OPTIONS = '/options',
  OUT_OF_SERVICE = '/out-of-service',
  MORE_SEGMENTS_CHECKIN = '/more-segments-checkin',
  HAZMAT = '/hazmat',
  HAZMAT_PROHIBITED = '/hazmat/prohibited',
  HAZMAT_RESTRICTED = '/hazmat/restricted',
  HAZMAT_SELF_TAGGING = '/hazmat/self-tagging',
  HAZMAT_NO_SELF_TAGGING = '/hazmat/no-self-tagging',
  PAX_EDIT = '/pax-edit',
  PAX_EDIT_SELECT_PAX = '/pax-edit/select-pax',
  PAX_EDIT_MAIN = '/pax-edit/main',
  PAX_EDIT_KTN = '/pax-edit/ktn',
  PAX_EDIT_REDRESS = '/pax-edit/redress',
  PAX_EDIT_HAWAIIAN_MILES = '/pax-edit/hawaiian-miles',
  ABOUT_BAGS = '/about-bags',
  SPECIAL_ITEMS = '/special-items',
  PAYMENT = '/payment',
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
