import { Component, OnDestroy, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';
import { Router } from '@angular/router';
import { HaCussService } from '../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../services/logging/logging.service';
import { environment } from '../../../environments/environment';
import { DeviceService } from '../../services/ha-cuss/device.service';
import { ConfigService } from 'src/app/services/api/config/config.service';
import { Subscription } from 'rxjs';
import { socketAlive } from '../../services/emitters/session-event-emitters';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-out-of-service',
  templateUrl: './out-of-service.component.html',
  styleUrls: ['./out-of-service.component.scss'],
})
export class OutOfServiceComponent implements OnInit, OnDestroy {
  public outOfService = true;
  public days: any = [];
  public timestamps = [];
  public interval;
  public config;
  public configSubscription: Subscription;

  constructor(
    private haCussService: HaCussService,
    private deviceService: DeviceService,
    private logging: Logging,
    public configService: ConfigService,
    public router: Router
  ) {}

  getCurrentHourAndMinute(): { hour; minute } {
    const hour = parseInt(dateFns.format(new Date(), 'H'), 10);
    const minute = parseInt(dateFns.format(new Date(), 'm'), 10);

    return { hour, minute };
  }

  parseServiceHours(serviceHours: string): { hasServiceHours?; openAt?; closeAt? } {
    const hasServiceHoursAsFalse = { hasServiceHours: false };

    if (!serviceHours || serviceHours.trim().length === 0 || serviceHours === 'null') {
      return hasServiceHoursAsFalse;
    }

    const cronTimeIntevalsRegex = /\'(.*?)\'/g;
    const serviceTimeIntervals = serviceHours.match(cronTimeIntevalsRegex);

    if (!serviceTimeIntervals) {
      return hasServiceHoursAsFalse;
    }

    let count = 0;

    serviceTimeIntervals.forEach((interval) => {
      this.timestamps.push(interval);
      count++;

      if (count >= 2) {
        this.days.push(this.timestamps.join(' '));
        this.timestamps = [];
        count = 0;
      }
    });

    if (this.days.length === 0) {
      return hasServiceHoursAsFalse;
    }

    const currentDay = dateFns.format(new Date(), 'ddd').toUpperCase();

    const currentTimeStamp = this.days.find((day) => day.indexOf(currentDay) > 0);

    const secondsMinutesHoursRegex = /\d{1,2}/gm;
    const timeInterval = currentTimeStamp.match(secondsMinutesHoursRegex);

    const openHourIndex = 2;
    const openMinuteIndex = 1;
    const closeHourIndex = 5;
    const closeMinuteIndex = 4;

    const openAt = {
      hour: parseInt(timeInterval[openHourIndex], 10),
      minute: parseInt(timeInterval[openMinuteIndex], 10),
    };

    const closeAt = {
      hour: parseInt(timeInterval[closeHourIndex], 10),
      minute: parseInt(timeInterval[closeMinuteIndex], 10),
    };

    return { hasServiceHours: true, openAt, closeAt };
  }

  ngOnInit() {
    this.configSubscription = this.configService.config.subscribe((config) => {
      this.config = config;

      if (this.config && this.config.configuration) {
        this.initOutOfService();
      }
    });

    this.logging.infoUiKioskUnavailablePageDisplayed();
  }

  isCurrentTimeOutOfServiceHours(openAt, closeAt): boolean {
    const { hour, minute } = this.getCurrentHourAndMinute();

    const isCurrentTimeBeforeServiceHours = hour < openAt.hour || (hour === openAt.hour && minute <= openAt.minute - 1);

    const isCurrentTimeAfterServiceHours = hour > closeAt.hour || (hour === closeAt.hour && minute >= closeAt.minute);

    const isOutOfServiceHours = isCurrentTimeBeforeServiceHours || isCurrentTimeAfterServiceHours;

    return isOutOfServiceHours;
  }

  checkOutOfService(openAt, closeAt) {
    const isOutOfService = this.isCurrentTimeOutOfServiceHours(openAt, closeAt);

    if (isOutOfService || !this.haCussService.peripheralOnline) {
      this.outOfService = true;
      this.deviceService.disableLookupDevices();
      return;
    }

    this.outOfService = false;
  }

  initOutOfService() {
    if (environment.cussEnabled === false) {
      this.outOfService = false;
      return;
    }

    if (!this.config.inService) {
      this.outOfService = true;
      return;
    }

    const sessionServiceHours = window.sessionStorage.getItem('serviceHours');

    const serviceHours = sessionServiceHours || this.config.serviceHours;

    const { hasServiceHours, openAt, closeAt } = this.parseServiceHours(serviceHours);

    if (!hasServiceHours) {
      this.outOfService = true;
      return;
    }

    this.interval = setInterval(() => {
      this.checkOutOfService(openAt, closeAt);
    }, 2000);

    socketAlive.subscribe((event) => {
      this.outOfService = !event;
    });
  }

  ngOnDestroy() {
    this.configService.getConfig();
    clearInterval(this.interval);

    this.router.navigate([AppRoutes.SPLASH_SCREEN]);

    this.haCussService.setAvailable();
    this.haCussService.enableLookupDevices();

    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }
}
