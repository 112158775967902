import { Injectable, NgZone } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CardReaderActionTypes, CardReaderInserted, CardReaderReadInternal } from './card-reader.actions';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  Authenticate,
  Checkout,
  selectCurrentTrip,
  SetCardRetryError,
  SetPaymentCardError,
  SetReferToAgentError,
  SetSubsequentCardError,
  SetTimeoutError,
  Trip,
} from '../../index';
import { Router } from '@angular/router';
import { Parser } from '../../../services/ha-cuss/device-parser/parser';
import { PnrLocatorFlowClearState, PnrLocatorFlowUpdateState } from '../../pnr-locator-flow/pnr-locator-flow.action';
import { DeleteCart } from '../../cart/cart.actions';
import { selectCart } from '../../cart/cart.selector';
import {
  activeSession,
  isCardInReaderTooLong,
  paymentAttempts,
} from '../../../services/emitters/session-event-emitters';
import { HaCussService } from '../../../services/ha-cuss/ha-cuss.service';
import { CartService } from '../../../services/api/cart/cart.service';
import { Cart } from '../../cart/cart.model';
import { DeviceService } from '../../../services/ha-cuss/device.service';
import { LoadingService } from '../../../services/ui/loading.service';
import { API } from '../../../services/api/api.service';
import { LibAngularPaymentService } from 'lib-ha-angular-payment';
import { Logging } from '../../../services/logging/logging.service';
import { selectCardSwipeCount } from './card-reader.selector';
import { DocumentService } from '../../../services/api/document/document.service';
import { ApplinkService } from '../../../services/hardware/applink.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Injectable({ providedIn: 'root' })
export class CardReaderEffects {
  private cardInterval;
  private timeInSeconds = 0;
  private maxTimeExceeded = false;
  cart: Cart;
  private trip: Trip;
  private swipeCount: number;
  private attempts = 0;
  private authToken;
  private isPartialCartsError = false;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private zone: NgZone,
    private router: Router,
    private haCussService: HaCussService,
    private deviceService: DeviceService,
    private cartService: CartService,
    private loadingService: LoadingService,
    private paymentApi: LibAngularPaymentService,
    private api: API,
    private logging: Logging,
    private documentService: DocumentService,
    private applinkService: ApplinkService
  ) {
    this.store.pipe(select(selectCart)).subscribe((cart) => {
      this.cart = cart;
    });
    this.store.pipe(select(selectCurrentTrip)).subscribe((trip: Trip) => {
      this.trip = trip;
    });
    this.store.pipe(select(selectCardSwipeCount)).subscribe((count) => {
      this.swipeCount = count;
    });
    this.store.pipe(select((authStore) => authStore.authentication)).subscribe((response) => {
      this.authToken = response.access_token;
    });
    paymentAttempts.subscribe((attemptNumber) => {
      this.attempts = attemptNumber;
    });
  }

  /**
   * This effect listens for a card to be swiped and starts an internal timer
   */
  @Effect({ dispatch: false })
  cardReaderInserted$ = this.actions$.pipe(
    ofType(CardReaderActionTypes.CardReaderInserted),
    map((action: CardReaderInserted) => action.type),
    map((value) => {
      this.deviceService.disablePassport();
      this.deviceService.disableBarcode();
      this.cardInterval = setInterval(() => {
        this.alertUserCardLeftInReader();
        this.timeInSeconds++;
      }, 1000);
    })
  );

  /**
   * This effect listens for the card to be removed and perform actions based on criteria
   */
  @Effect({ dispatch: false })
  cardReaderIncorrectCard$ = this.actions$.pipe(
    ofType(CardReaderActionTypes.CardReaderDamaged),
    map((value) => {
      this.logging.infoHardwareBadCardSwipe(this.swipeCount);
      if (this.maxTimeExceeded) {
        this.store.dispatch(new SetTimeoutError());
      } else {
        this.cardSwipeErrorHandler();
      }
      this.resetInterval();
    })
  );

  /**
   * This effect listens for the cardReadInternal event and will pass the information to the appropriate path
   */
  @Effect({ dispatch: false })
  cardReadInternal$ = this.actions$.pipe(
    ofType(CardReaderActionTypes.CardReaderReadInternal),
    map((action: CardReaderReadInternal) => action.payload.cardReaderReadInternal),
    map(async (cardValue) => {
      this.resetInterval();
      if (this.maxTimeExceeded) {
        this.store.dispatch(new SetTimeoutError());
        return;
      }
      /**
       * Deserialize the information from the CC Dip. This will either come masked or unmasked depending on the mode of the credit card
       * reader
       */
      CardReaderEffects.deserializeCardData(cardValue);
      /**
       * We check to see if we have a current cartId.
       * If we do not, then we need to locate the PNR via CC Dip.
       * If we do, then we know that we have already located the PNR and are likely in an
       * active session. At that point we know we need to tokenize the credit card.
       */
      if (this.cart.id === undefined) {
        /**
         * Locate a PAX via CC Dip.
         */
        this.locatePNRByCC();
      } else {
        await this.payForCart();
      }
    })
  );

  @Effect({ dispatch: false })
  cardReaderPayment$ = this.actions$.pipe(
    ofType(CardReaderActionTypes.CardReaderEnablePayment),
    map(() => {
      if (this.router.url === AppRoutes.PAYMENT) {
        this.deviceService.getCreditCardDevice().enable(1);
        this.haCussService.enableCardReaderPayment();
      }
    })
  );

  @Effect({ dispatch: false })
  cardReaderDisablePayment$ = this.actions$.pipe(
    ofType(CardReaderActionTypes.CardReaderDisablePayment),
    map(() => {
      this.deviceService.disableLookupDevices();
    })
  );

  static setPaymentBody() {
    return {
      lastName: Parser.paxDetails.lastName,
      firstName: Parser.paxDetails.firstName,
      cardNumber: Parser.paxDetails.number,
      expiration: Parser.paxDetails.expiryDate,
    };
  }

  static deserializeCardData(value) {
    Parser.getNameFromCC(JSON.parse(value).track1, JSON.parse(value).track2);
  }

  private isCallFailing(response): boolean {
    if ('status' in response) {
      switch (response.status) {
        case 200: {
          return false;
        }
        case 207: {
          // We know we have either encountered an error or received a partial carts response
          this.isPartialCartsError = true;
          return true;
        }
        case 400:
        case 401:
        case 404:
        case 500:
        case 502:
        case 504: {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  private async payForCart() {
    this.logging.infoHardwareBagSwipePayment();
    paymentAttempts.emit(this.attempts + 1);
    try {
      const recursivePaymentCall = async () => {
        try {
          if (this.cart.state === 'PROCESSING') {
            return;
          }
          const checkoutBody: Checkout = await this.paymentApi.getPayment(
            CardReaderEffects.setPaymentBody(),
            this.cart.confirmationCode,
            this.cart.grandTotal,
            this.cart.id,
            this.authToken
          );
          // Add pointOfSale Object
          checkoutBody.pointOfSale = this.documentService.getPointOfSale();
          // Set Non-PCI Payment Information
          this.documentService.setCartPaymentInformation(checkoutBody.payments[0]);
          this.logging.infoApiGetPaymentHeader();

          Parser.resetPaxDetails();

          const result = await this.cartService.checkoutCart(
            checkoutBody,
            this.cart.id,
            this.trip.id,
            this.applinkService.getKioskId()
          );
          if (this.isCallFailing(result)) {
            throw Error;
          } else {
            this.logging.infoHardwareCreditCardDippedPaymentSuccessful();
            this.logging.infoApiPaymentTokenizationSuccessful();
            this.logging.infoApiCheckoutSuccessful();
            this.logging.infoUiPaymentSuccessful();
            /**
             * Since we have successfully paid for the cart, we must now delete it
             */
            this.store.dispatch(new DeleteCart());
            /**
             * Move customer to the hazmat screen
             */
            this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
          }
        } catch (e) {
          this.logging.infoHardwareCreditCardDippedPaymentFailed(e, this.swipeCount);
          this.logging.infoApiPaymentTokenizationFailed(this.swipeCount);
          this.logging.infoUiPaymentFailed();
          this.store.dispatch(new SetPaymentCardError());
          if (this.attempts === 3 || this.isPartialCartsError) {
            this.store.dispatch(new DeleteCart());
            throw Error('Max Attempts Reached');
          }
        }
      };
      await recursivePaymentCall();
    } catch (e) {
      this.logging.infoApiCheckoutFailed(e);
      this.isPartialCartsError
        ? this.store.dispatch(
            new SetReferToAgentError({
              alertReasonCode: 'Bag Fulfilment' + ' Issue',
            })
          )
        : this.store.dispatch(new SetReferToAgentError({ alertReasonCode: 'Issue With Payment' }));
    }
  }

  private cardSwipeErrorHandler() {
    if (this.cart.id !== undefined) {
      paymentAttempts.emit(this.attempts + 1);
      this.logging.infoHardwareCreditCardDippedPaymentFailed('Invalid Card Swiped', this.swipeCount);
    }
    switch (this.swipeCount) {
      case 1: {
        this.store.dispatch(new SetCardRetryError());
        break;
      }
      case 2: {
        this.store.dispatch(new SetSubsequentCardError());
        break;
      }
      case 3: {
        if (this.cart.id !== undefined) {
          this.store.dispatch(new DeleteCart());
          this.logging.infoApiCheckoutFailed('Max Attempts Reached');
          this.store.dispatch(new SetReferToAgentError({ alertReasonCode: 'Issue With Payment' }));
        } else {
          this.store.dispatch(new SetTimeoutError());
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  private alertUserCardLeftInReader() {
    /**
     * If the card is still in the reader at 5 seconds, we display a page to the user asking them to remove their card
     */
    if (this.timeInSeconds >= 5) {
      isCardInReaderTooLong.emit(true);
    }
    /**
     * If the card is still in the reader after 10 seconds, we will clear the state of the application and if we are on the payments
     * page we will delete the users cart
     *
     * We want the real world timeout to be 10 seconds, there is a 2 second delay between the time the users removes their card and when
     * this event fires
     */
    if (this.timeInSeconds === 12) {
      /**
       * If we have a cartId, we need to delete the cart
       */
      this.maxTimeExceeded = true;
    }
  }

  private resetInterval() {
    clearInterval(this.cardInterval);
    this.timeInSeconds = 0;
    isCardInReaderTooLong.emit(false);
  }

  locatePNRByCC() {
    activeSession.emit(true);
    const lookupPayload = {
      lastName: Parser.paxDetails.lastName,
      firstName: Parser.paxDetails.firstName,
      lookupMethod: 'creditCard',
    };
    this.store.dispatch(new PnrLocatorFlowClearState());
    this.store.dispatch(new PnrLocatorFlowUpdateState(lookupPayload));
    this.store.dispatch(new Authenticate(lookupPayload));
  }
}
