import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bags',
  templateUrl: './bags.component.html',
  styleUrls: ['./bags.component.scss'],
})
export class BagsComponent implements OnInit {
  totalOfBags: string;

  constructor() {}

  ngOnInit() {}

  receiveTotalOfBags($event) {
    this.totalOfBags = $event;
  }
}
