import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SegmentDetail } from '../../state';
import { StoreRecorder } from './store-recorder.service';
import { environment } from '../../../environments/environment';
import { CatalogIdTypes, Product } from '../../state/trip/passenger.catalog.model';
import { loadKioskId } from '../emitters/session-event-emitters';

@Injectable({ providedIn: 'root' })
export class DataSource {
  tripLoaded$: Observable<any>;
  cartLoaded$: Observable<any>;
  tripLoadedListeners: Array<() => void>;
  cartLoadedListeners: Array<() => void>;
  private savedKioskId;

  constructor(private storeRecorder: StoreRecorder, private router: Router) {
    this.tripLoaded$ = this.storeRecorder.segment$.pipe(filter((segment) => segment !== null));

    this.tripLoaded$.subscribe(() => {
      this.flushListeners();
    });

    this.cartLoaded$ = this.storeRecorder.cart$.pipe(filter((cart) => cart !== null));

    this.cartLoaded$.subscribe(() => {
      this.flushListeners();
    });

    loadKioskId.subscribe((value) => {
      this.savedKioskId = value;
    });

    this.tripLoadedListeners = [];
    this.cartLoadedListeners = [];
  }

  onLoadedTrip(listener: () => void) {
    this.tripLoadedListeners.push(listener);
  }

  onLoadedCart(listener: () => void) {
    this.cartLoadedListeners.push(listener);
  }

  flushListeners() {
    this.tripLoadedListeners.forEach((listener) => listener());
    this.cartLoadedListeners.forEach((listener) => listener());

    this.tripLoadedListeners = [];
    this.cartLoadedListeners = [];
  }

  url() {
    return this.router.url;
  }

  language(): string {
    const appState = this.storeRecorder.getAppState();

    return appState.language.languageId.withDefault('en');
  }

  numberOfSelectedPax(): number {
    try {
      const activeSegmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const selectedPax = activeSegmentDetails.filter((segmentDetail: SegmentDetail) => segmentDetail.selected).length;

      return selectedPax;
    } catch (e) {
      return 0;
    }
  }

  appVersion(): string {
    return environment.version;
  }

  environment(): string {
    return environment.logging.environmentName;
  }

  kioskId(): string {
    return this.savedKioskId;
  }

  sessionId(): string {
    return window.sessionStorage.getItem('SESSIONID');
  }

  localTime(): string {
    const utc = Date.now();
    const offset = new Date().getTimezoneOffset();
    const msOffset = offset * 60 * 1000;

    return utc + msOffset + '';
  }

  systemTime(): string {
    return Date.now() + '';
  }

  hasAlreadyCheckedIn(): boolean {
    try {
      const activeSegmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const checkedIn = activeSegmentDetails.filter((segment: SegmentDetail) => segment.isCheckedIn).length;

      return checkedIn > 0;
    } catch (e) {
      return false;
    }
  }

  getMarketType(): string {
    let marketType;

    try {
      marketType = this.storeRecorder.getActiveSegment().marketType;
    } catch (e) {
      marketType = null;
    }

    return marketType;
  }

  getSeatPreference(): string {
    try {
      const segmentDetails = this.storeRecorder.getActiveSegmentDetails();

      const seatPreferences = segmentDetails.map((segmentDetai) => segmentDetai.seatPreference);

      return seatPreferences.join(',');
    } catch (e) {
      return null;
    }
  }

  isNonRev(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPassTravel;
    } catch (e) {
      return false;
    }
  }

  numberOfPax(): number {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const passengers = trip.passengers;

      return passengers.length;
    } catch (e) {
      return 0;
    }
  }

  totalBags(): number {
    try {
      const details = this.storeRecorder.getActiveSegmentDetails();

      return details.map((detail) => detail.bags.length).reduce((total, bags) => total + bags, 0);
    } catch (e) {
      return 0;
    }
  }

  totalSpecialItems(): number {
    return 0;
  }

  pnr(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.confirmationCode;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  channel(): string {
    return 'kiosk';
  }

  origin(): string {
    try {
      const segment = this.storeRecorder.getActiveSegment();

      return segment.origin;
    } catch (e) {
      return null;
    }
  }

  destination(): string {
    try {
      const segment = this.storeRecorder.getActiveSegment();

      return segment.destination;
    } catch (e) {
      return null;
    }
  }

  totalFlightsInSegment(): number {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.flights.length;
    } catch (e) {
      return null;
    }
  }

  isPassTraveler(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPassTravel;
    } catch (e) {
      return null;
    }
  }

  isPositiveSpace(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPositiveSpace;
    } catch (e) {
      return null;
    }
  }

  ktnUpdated(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const ktns = trip.passengers.map((pax) => pax.knownTravelerNumber).join(',');

      return ktns;
    } catch (e) {
      return null;
    }
  }

  redressUpdated(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const redresss = trip.passengers.map((pax) => pax.redressNumber).join(',');

      return redresss;
    } catch (e) {
      return null;
    }
  }

  hmUpdated(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      const hmNumbers = trip.passengers.map((pax) => pax.hawaiianMilesNumber).join(',');

      return hmNumbers;
    } catch (e) {
      return null;
    }
  }

  browserUrl(): string {
    return window.location.href;
  }

  userAgent(): string {
    return window.navigator.userAgent;
  }

  alreadyCheckedInPaxNames(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();
      const checkedInPax = trip
        .getSegmentDetailsAndPax()
        .filter((element) => element.segmentDetail.isCheckedIn === true)
        .map((element) => element.passenger);

      return checkedInPax
        .map((pax) => pax.passengerName.firstName.slice(0, 3) + pax.passengerName.lastName[0])
        .join(',');
    } catch (e) {
      return null;
    }
  }

  paxNames(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();
      const paxList = trip.getSegmentDetailsAndPax().map((element) => element.passenger);

      return paxList.map((pax) => pax.passengerName.firstName.slice(0, 3) + pax.passengerName.lastName[0]).join(',');
    } catch (e) {
      return null;
    }
  }

  boardingStartTime(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.activeSegment.nextFlight.scheduledBoarding;
    } catch (e) {
      return null;
    }
  }

  confirmationCode(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.confirmationCode;
    } catch (e) {
      return null;
    }
  }

  HAMemberStatus(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.passengers.map((passenger) => passenger.customerLevel).join(',');
    } catch (e) {
      return null;
    }
  }

  hawaiianMilesNumber(): string {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.passengers.map((passenger) => passenger.hawaiianMilesNumber).join(',');
    } catch (e) {
      return null;
    }
  }

  positiveSpace(): boolean {
    try {
      const trip = this.storeRecorder.getCurrentTrip();

      return trip.isPositiveSpace || trip.isNonRevSpaceAvailable();
    } catch (e) {
      return null;
    }
  }

  totalRegularBagsAllowance(): number {
    try {
      let totalBags = 0;
      const trip = this.storeRecorder.getCurrentTrip();
      const bagsCatalog = trip.passengers.map((passenger) => passenger.bagsCatalog);
      const products: Product[] = bagsCatalog.map((catalog) => catalog.products[0]);

      products.forEach((product) => {
        totalBags += product.priceQuotes.filter((quote) => quote.totalPrice == 0).length;
      });

      return totalBags;
    } catch (e) {
      return null;
    }
  }

  totalSeatsAllowance(): number {
    try {
      let totalSeats = 0;
      const trip = this.storeRecorder.getCurrentTrip();
      const seatsCatalog = trip.passengers.map((passenger) => passenger.seatsCatalog);
      const products: Product[] = [].concat(...seatsCatalog.map((catalog) => catalog.products));

      products.forEach((product) => {
        totalSeats += product.priceQuotes.filter((quote) => quote.totalPrice == 0).length;
      });

      return totalSeats;
    } catch (e) {
      return null;
    }
  }

  totalAllowances(): number {
    try {
      return this.totalRegularBagsAllowance() + this.totalSeatsAllowance();
    } catch (e) {
      return null;
    }
  }

  totalRegularBags(): number {
    try {
      let totalBags = 0;
      const cart = this.storeRecorder.getCurrentCart();
      const products = cart.items.filter((item) => item.productId === '0GO');
      products.forEach((product) => {
        totalBags += product.quantity;
      });

      return totalBags;
    } catch (e) {
      return null;
    }
  }

  runningBaggageTotal(): number {
    try {
      const cart = this.storeRecorder.getCurrentCart();

      return cart.totalPrices.find((item) => item.catalogId === CatalogIdTypes.Bags).price;
    } catch (e) {
      return null;
    }
  }

  totalPaymentsCollected(): number {
    try {
      const cart = this.storeRecorder.getCurrentCart();

      return cart.grandTotal;
    } catch (e) {
      return null;
    }
  }

  totalRegularBagsAllowanceUsed(): number {
    try {
      return this.totalRegularBagsAllowance() - this.totalRegularBags();
    } catch (e) {
      return null;
    }
  }
}
