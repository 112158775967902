import { Component, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SetContent } from '../../../../state/keyboard/keyboard.action';
import { take } from 'rxjs/operators';
import { PnrLocatorFlow } from '../../../../state/pnr-locator-flow/pnr-locator-flow.model';
import { KeyboardState } from '../../../../state/keyboard/keyboard.reducer';
import { Keyboard } from '../../../../state/keyboard/keyboard.model';
import { Router } from '@angular/router';
import { PnrLocatorFlowUpdateState } from '../../../../state/pnr-locator-flow/pnr-locator-flow.action';
import { PnrLocatorFlowState } from '../../../../state/pnr-locator-flow/pnr-locator-flow.reducer';
import { Logging } from '../../../../services/logging/logging.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-by-name',
  templateUrl: './by-name.component.html',
  styleUrls: ['./by-name.component.scss', './../locate-reservation.component.scss'],
})
export class ByNameComponent implements OnInit, OnDestroy {
  keyboard$: Observable<Keyboard>;
  pnrLocatorFlow$: Observable<PnrLocatorFlow>;

  public pnrLocatorFlowSubscriber: Subscription;
  public keyboardSubscriber: Subscription;
  public code = '';
  public codeDone = false;

  constructor(
    private store: Store<KeyboardState>,
    private router: Router,
    private logging: Logging,
    public pnrLocatorFlowStore: Store<PnrLocatorFlowState>
  ) {
    this.pnrLocatorFlow$ = pnrLocatorFlowStore.pipe(select('pnrLocatorFlow'));
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnInit() {
    this.logging.infoUiFlightNumberLastNameLookupPageDisplayed(0);

    this.pnrLocatorFlowSubscriber = this.pnrLocatorFlow$.pipe(take(1)).subscribe((value) => {
      let lastName = '';
      if (value) {
        lastName = value.lastName;
      }
      this.store.dispatch(new SetContent(lastName));
      this.enableKeyboard();
    });
  }

  ngOnDestroy() {
    if (this.pnrLocatorFlowSubscriber) {
      this.pnrLocatorFlowSubscriber.unsubscribe();
    }
    if (this.keyboardSubscriber) {
      this.keyboardSubscriber.unsubscribe();
    }
  }

  enableKeyboard() {
    this.keyboardSubscriber = this.keyboard$.subscribe((value) => {
      if (value) {
        this.code = value.content.toUpperCase();
        this.codeDone = value.content.length >= 1;
      }
    });
  }

  validateLastName() {
    this.logging.infoUiFlightNumberLastNameLookupPageContinueSelected(0);

    if (!this.codeDone) {
      return false;
    }
    this.store.dispatch(new PnrLocatorFlowUpdateState({ lastName: this.code }));
    this.router.navigate([AppRoutes.LOCATE_RESERVATION_BIRTHDAY]);
  }
}
