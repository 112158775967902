import { PassengerCatalog, Product } from './passenger.catalog.model';

export class Passenger {
  id: string;
  passengerName: PassengerName;
  externalId: string | null;
  dateOfBirth: string | null;
  gender: string | null;
  redressNumber: string | null;
  knownTravelerNumber: string | null;
  seatPreference: string | null;
  isMinor: boolean | null;
  hasInfant: boolean | null;
  loyalty?: PassengerLoyalty;
  seatsCatalog?: PassengerCatalog;
  bagsCatalog?: PassengerCatalog;

  static deserializeFromJson(json): Passenger {
    const passenger = new Passenger();
    passenger.loyalty = {
      isCardHolder: null,
      level: '',
      membershipId: '',
      programName: '',
    };

    return Object.assign(passenger, json);
  }

  get hawaiianMilesNumber() {
    return this.loyalty.membershipId;
  }

  set hawaiianMilesNumber(value: string) {
    this.loyalty.membershipId = value;
  }

  get customerLevel() {
    return this.loyalty.level;
  }

  set customerLevel(value: string) {
    this.loyalty.level = value;
  }

  isHawaiianMilesEliteMember() {
    const hawaiianMilesEliteMembershipLevels = ['Gold', 'Platinum', 'Premier'];
    return hawaiianMilesEliteMembershipLevels.indexOf(this.loyalty.level) >= 0;
  }

  getRegularBagsCatalog(): Product {
    if (this.bagsCatalog && this.bagsCatalog.products) {
      return this.bagsCatalog.products.find((product) => product.type === 'regularBag');
    }

    return null;
  }

  getPriceForRegularBags(numberOfBags: number, prePaidBags: number): number {
    const allPrices = this.getRegularBagsCatalog()
      .priceQuotes.filter((priceQuote) => priceQuote.quantity <= numberOfBags && priceQuote.quantity > prePaidBags)
      .map((priceQuote) => priceQuote.totalPrice);

    return allPrices.reduce((x, y) => x + y, 0);
  }

  getTotalFreeRegularBags(): number {
    let freeQuantities = this.getRegularBagsCatalog()
      .priceQuotes.filter((priceQuote) => priceQuote.totalPrice === 0)
      .map((priceQuote) => priceQuote.quantity);

    if (freeQuantities.length === 0) {
      freeQuantities = [0];
    }

    return Math.max(...freeQuantities);
  }
}

export interface PassengerName {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
}

export interface PassengerLoyalty {
  isCardHolder: boolean | null;
  membershipId: string | null;
  level: string | null;
  programName: string | null;
}
