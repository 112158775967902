import { Passenger } from '../trip/passenger.model';

export class Cart {
  id: string;
  expiration: Date;
  state: string;
  grandTotal: number;
  confirmationCode: string;
  passengers: string[];
  totalPrices: CartPrices[];
  associatedPassengers: { passengerId: string }[];
  items: CartItem[];
  sessionEnd = false;
  relatedCarts: RelatedCart[];
  paxSelectedForSpecialItems: Passenger;

  static deserializeFromJson(json): Cart {
    const cart = Object.assign(new Cart(), json);
    return cart;
  }

  checkConcurrentCart(): boolean {
    if (
      this.relatedCarts &&
      this.relatedCarts.length > 0 &&
      // tslint:disable-next-line: no-use-before-declare
      this.relatedCarts[0].state !== RelatedCartState.PROCESSING
    ) {
      return true;
    }
    return false;
  }

  checkConcurrentRelatedCart(): boolean {
    if (
      this.relatedCarts &&
      this.relatedCarts.length > 0 &&
      // tslint:disable-next-line: no-use-before-declare
      this.relatedCarts[0].state === RelatedCartState.PROCESSING
    ) {
      return true;
    }
    return false;
  }
}

export interface CartPrices {
  catalogId: string;
  price: number;
}

export interface CartItem {
  productId: string;
  catalogId: string;
  quantity?: number;
  price?: string;
  expirationTime?: Date;
  assignment?: string;
  associatedPassenger: AssociatedPassenger;
}

export interface AssociatedPassenger {
  passengerId: string;
  segmentId?: string;
  flightId?: string;
}

export interface Checkout {
  checkoutTransactionId: string;
  confirmationCode: string;
  pointOfSale: PointOfSale;
  payments: Payment[];
}

export interface Payment {
  paymentType: string;
  paymentToken: string;
  expirationDate: string;
  cardType: string;
  firstName: string;
  lastName: string;
  amount: string;
}

export interface AssociatedPassenger {
  passengerId: string;
}
export interface PointOfSale {
  location: KioskLocation;
}

export enum RelatedCartState {
  PROCESSING = 'PROCESSING',
}

export interface RelatedCart {
  id: string;
  state: RelatedCartState;
  segmentId: string;
  associatedPassengers: AssociatedPassenger[];
}

export interface KioskLocation {
  cityCode: string;
  stationCode: string;
}
