import { TraceLevels } from '../../../../assets/js/embross-device-manager';
import {
  BagTagFailed,
  BagTagPectabFailed,
  BagTagPectabLoaded,
  BagTagPECTabLoadingComplete,
  BagTagPrinted,
  BagTagPrinterStatusChange,
  BagTagPrinterStatusIsOK,
  BagTagPrintingComplete,
} from '../../../state/ha-cuss-device/bagtag-printer/bagtag-printer.actions';
import { numberToPrint } from '../device.service';
import { SetGenerateBagTagError, SetPrintBagTagsError } from 'src/app/state';
import { currentlyPrinting, PRINTING_TYPES, socketAlive } from '../../emitters/session-event-emitters';
import { take } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app-routing.module';

export function bagTagPrinterCallback(event) {
  this.applinkService
    .getDeviceManager()
    .sendLogMsg(TraceLevels.LOG_TRACE, 'bagTagPrinterCallback: event ' + event.key + ' and value ' + event.value);
  switch (event.key) {
    case 'pectabLoadingComplete':
      this.bagTagPrinterStore.dispatch(new BagTagPECTabLoadingComplete(event.key, event.value));
      break;
    case 'bagtagPrintingComplete':
      this.bagTagPrinterStore.dispatch(new BagTagPrintingComplete(event.key, event.value));
      break;
    case 'bagtagFailed':
      this.bagTagPrinterStore.dispatch(new BagTagFailed(event.key, event.value));
      this.logging.errorHardwareBagTagPrinterTicketFailed();
      numberToPrint.emit(this.printRemaining - 1);
      break;
    case 'pectabFailed':
      currentlyPrinting.pipe(take(1)).subscribe((value) => {
        if (value === PRINTING_TYPES.BAG_TAG) {
          this.bagTagPrinterStore.dispatch(new SetGenerateBagTagError());
        }
      });
      this.bagTagPrinterStore.dispatch(new BagTagPectabFailed(event.key, event.value));
      this.logging.errorHardwareBagTagPrinterPectabFailed(event.value);
      break;
    case 'pectabLoaded':
      this.bagTagPrinterStore.dispatch(new BagTagPectabLoaded(event.key, event.value));
      break;
    case 'bagtagPrinted':
      this.bagTagPrinterStore.dispatch(new BagTagPrinted(event.key, event.value));
      this.logging.infoHardwareBagTagPrinterTicketPrinted();
      numberToPrint.emit(this.printRemaining - 1);
      break;
    case 'statusIsOK':
      this.bagTagPrinterStore.dispatch(new BagTagPrinterStatusIsOK(event.key, event.value));
      break;
    case 'statusChange':
      this.bagTagPrinterStore.dispatch(new BagTagPrinterStatusChange(event.key, event.value));
      if (event.value[1] === false) {
        socketAlive.emit(true);
        this.enableBagTagPrinter();
      } else if (event.value[1] === true) {
        this.logging.errorHardwareBagTagPrinterError(event.value[0], event.value[1]);
        currentlyPrinting.pipe(take(1)).subscribe((value) => {
          if (value === PRINTING_TYPES.BAG_TAG) {
            this.bagTagPrinterStore.dispatch(new SetPrintBagTagsError());
          }
        });
        if (this.router.url === AppRoutes.SPLASH_SCREEN) {
          socketAlive.emit(false);
        }
      }
      break;
    default:
      this.applinkService
        .getDeviceManager()
        .sendLogMsg(
          TraceLevels.LOG_TRACE,
          'default:bagTagPrinterCallback event ' + event.key + ' and value ' + JSON.stringify(event.value)
        );
  }
}
