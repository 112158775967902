import { SegmentDetail } from './segment-detail.model';
import { AirportDate } from '../../common/airport-date.model';
import { Flight, FlightStatuses } from '../flight/flight.model';
import * as helpers from '../../../utils/helpers';
import { ErrorType } from '../../error/error.model';

export class Segment {
  id: string;
  arrival: AirportDate | null;
  areAllPassengersCheckedIn: boolean | false;
  canAllPassengersCheckIn: boolean | false;
  departure: AirportDate | null;
  destination: string | null;
  details: SegmentDetail[] | null;
  origin: string | null;
  requiresWebCheckIn: boolean | false;
  specialAnnouncementCode: string | null;
  version: string | null;
  marketType: string | null;
  flights?: Flight[] | null;
  nextFlight?: Flight | null;

  static deserializeFromJson(json, tripFlights: Flight[], kioskOrigin: string): Segment {
    const segment = Object.assign(new Segment(), json) as Segment;

    const details = json.details || [];
    segment.details = details.map((detail) => SegmentDetail.deserializeFromJson(detail));

    segment.setFlights(tripFlights, kioskOrigin);
    return segment;
  }

  getPaxIdsEligibleToCheckin(): string[] {
    const details = this.details || [];

    return details
      .filter((detail) => (detail.canCheckIn && detail.selected) || detail.isCheckedIn)
      .map((detail) => detail.passengerId);
  }

  hasCheckedInPax() {
    return this.details.reduce((hasCheckedInPax, segmentDetail) => hasCheckedInPax || segmentDetail.isCheckedIn, false);
  }

  getPaxIdsSelectedForCheckin(): string[] {
    return this.details.filter((detail) => detail.selected).map((detail) => detail.passengerId);
  }

  getNumOfPaxsSelectedForCheckin(): number {
    return this.getPaxIdsSelectedForCheckin().length;
  }

  getNumBags(): number {
    return this.details.reduce((numBags, detail) => numBags + detail.getNumBags(), 0);
  }

  setFlights(tripFlights: Flight[], kioskOrigin: string) {
    const flightDetails = [];
    this.details.map((detail) => detail.flightDetails.map((flightDetail) => flightDetails.push(flightDetail)));

    const segmentFlightIds = flightDetails.map((flightDetail) => flightDetail.flightId);

    this.flights = tripFlights.filter((flight) => segmentFlightIds.includes(flight.id));
    if (!this.nextFlight) {
      this.nextFlight = this.flights.filter((flight) => flight.originMatchesKioskAirport(kioskOrigin))[0];
    }
  }

  isInternationalMarketType(): boolean {
    return this.marketType === 'International';
  }

  isOnMidPointJourney(): boolean {
    // This validates if it is a midpoint journey check-in, which means that the nextFlight's origin doesn't match the segment origin
    return this.origin !== this.nextFlight.origin;
  }

  hasFirstFlightCancelledForCheckIn(): boolean {
    const hasCancelledAlertMessageCode = this.details[0].alertMessageCode === ErrorType.FlightCancelled;
    const isFlightClosed = this.flights[0].checkInStatus === FlightStatuses.Closed;

    return isFlightClosed && hasCancelledAlertMessageCode;
  }

  getOrigin(): string {
    const origin = this.nextFlight ? this.nextFlight.origin : this.origin;
    return helpers.getCityLongNameByAirportCode(origin);
  }

  getDestination(): string {
    return helpers.getCityLongNameByAirportCode(this.destination);
  }

  getDestinationStops(): string {
    const stops = [...this.flights];
    const origin = stops.shift();

    const route = stops.map((stop) => {
      return helpers.getCityLongNameByAirportCode(stop.origin);
    });

    const isOneStop = route.length === 1;
    if (isOneStop) {
      return route.join();
    }

    const isTwoStops = route.length === 2;
    if (isTwoStops) {
      return route.join(' and ');
    }

    const lastStop = route.pop();
    const routeString = route.join(', ');

    return routeString.concat(` and ${lastStop}`);
  }

  getNumberOfStops() {
    const numberOfStops = this.flights.length - 1;
    return numberOfStops;
  }

  originMatchesKioskAirport(kioskOrigin: string): boolean {
    return this.origin === kioskOrigin;
  }
}
