import { Injectable, OnDestroy } from '@angular/core';
import { DeviceService, SPEECH_STATUS, speechStatus } from '../ha-cuss/device.service';
import { takeUntil, filter, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessibilityService implements OnDestroy {
  private elementsToRead = [];
  private navigationIndex = 0;
  private initialPageLoad = true;
  private attributeName = 'data-accessibility-role';
  private tabIndexName = 'tabindex';
  private unsubscribe$ = new Subject<void>();
  private speechStatus: SPEECH_STATUS;
  private parentElementArray = [];
  private childElementArray = [];

  constructor(private deviceService: DeviceService) {
    speechStatus.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.speechStatus = value;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSpeechStatus(): SPEECH_STATUS {
    return this.speechStatus;
  }

  setParentElementsToRead(elements, clearChildrenElements = true) {
    this.parentElementArray = [];

    if (clearChildrenElements) {
      this.childElementArray = [];
    }

    this.navigationIndex = 0;
    this.parentElementArray = Array.from(elements);
    this.initialPageLoad = true;
    this.spreadAndSort();
  }

  setChildElementsToRead(elements) {
    this.childElementArray = [];
    this.navigationIndex = 0;
    this.childElementArray = Array.from(elements);
    this.initialPageLoad = true;
    this.spreadAndSort();
  }

  public setElementsToRead(values) {
    this.navigationIndex = 0;
    this.elementsToRead = Array.from(values);
    this.initialPageLoad = true;
    this.sortByTabIndex();
  }

  public navigateToNextElement() {
    this.validateNextElement();
    this.elementsToRead[this.navigationIndex].focus();
    this.playCurrentElement(this.getAttributeOfElement());
    this.initialPageLoad = false;
  }

  public navigateToPreviousElement() {
    this.validatePreviousElement();
    this.elementsToRead[this.navigationIndex].focus();
    this.playCurrentElement(this.getAttributeOfElement());
    this.initialPageLoad = false;
  }

  public selectCurrentElement() {
    if (this.elementsToRead[this.navigationIndex]) {
      this.elementsToRead[this.navigationIndex].click();
    }
  }

  public readAllNonInteractiveElements(elements): Observable<void> {
    const statusUnsubscribe$ = new Subject<void>();
    this.setElementsToRead(elements);
    return speechStatus.pipe(
      filter((status) => [SPEECH_STATUS.COMPLETED, SPEECH_STATUS.NONE].includes(status)),
      map(() => {
        this.navigateToNextElement();
        if (this.navigationIndex === this.elementsToRead.length - 1) {
          statusUnsubscribe$.next();
          statusUnsubscribe$.complete();
        }
      }),
      takeUntil(statusUnsubscribe$)
    );
  }

  private spreadAndSort(): void {
    this.elementsToRead = [...this.parentElementArray, ...this.childElementArray];
    this.sortByTabIndex();

    // Creating a set from an array removes duplicates
    const elementSet = new Set(this.elementsToRead);
    this.elementsToRead = [...elementSet];
  }

  private sortByTabIndex(): void {
    this.elementsToRead = this.elementsToRead
      .filter((tabIndex) => tabIndex.getAttribute(this.tabIndexName) >= 0)
      .sort((a, b) => {
        return parseInt(a.getAttribute('tabindex')) - parseInt(b.getAttribute('tabindex'));
      });
  }

  private getAttributeOfElement() {
    return this.elementsToRead[this.navigationIndex].getAttribute(this.attributeName);
  }

  private playCurrentElement(elementRole: string) {
    if (elementRole) {
      if (elementRole === 'input') {
        this.deviceService.playText(elementRole + this.elementsToRead[this.navigationIndex].placeholder);
      } else {
        this.deviceService.playText(elementRole + this.elementsToRead[this.navigationIndex].textContent);
      }
    } else {
      this.deviceService.playText(this.elementsToRead[this.navigationIndex].textContent);
    }
  }

  private validateNextElement() {
    if (this.navigationIndex === this.elementsToRead.length - 1 || this.initialPageLoad) {
      this.navigationIndex = 0;
    } else {
      this.navigationIndex = this.navigationIndex + 1;
    }
  }

  private validatePreviousElement() {
    if (this.navigationIndex === 0 || this.initialPageLoad) {
      this.navigationIndex = this.elementsToRead.length - 1;
    } else {
      this.navigationIndex = this.navigationIndex - 1;
    }
  }
}
