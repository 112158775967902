import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';

import { deepEncode, deepSearch } from './utils';

import { LogLevels } from './log-levels';
import { Logger } from './logger.service';
import { DataSource } from './data-source.service';

import { LogEvent } from './log-event.interface';
import { findFirstParameterThatMatches } from '../../utils/helpers';

@Injectable({ providedIn: 'root' })
export class Logging {
  constructor(private loggingBackend: Logger, private dataSource: DataSource) {
    this.sessionTime = new Date().getTime();
    this.hasCheckinStarted = false;
  }

  sessionTime: number;
  hasCheckinStarted: boolean;
  static encodePayload(obj) {
    const encoded = deepEncode(obj);

    return encoded;
  }

  resetSession() {
    this.sessionTime = new Date().getTime();
  }

  // For testing purposes
  startCheckin() {
    this.hasCheckinStarted = true;
  }

  infoApiPnrSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any>,
    lookupMethod: string,
    elapsedTime?: number
  ) {
    this.dataSource.onLoadedTrip(() => {
      const keys = {
        lookupMethod,
        timeLapsed: elapsedTime || 0,
        alreadyCheckedIn: this.dataSource.hasAlreadyCheckedIn(),
        marketType: this.dataSource.getMarketType(),
        seatPreference: this.dataSource.getSeatPreference(),
        nonRev: this.dataSource.isNonRev(),
        numberOfPax: this.dataSource.numberOfPax(),
        totalBags: this.dataSource.totalBags(),
        totalSpecialItems: this.dataSource.totalSpecialItems(),
        confirmationCode: this.dataSource.confirmationCode(),
        channel: this.dataSource.channel(),
        origin: this.dataSource.origin(),
        destination: this.dataSource.destination(),
        language: this.dataSource.language(),
        numberOfSelectedPax: this.dataSource.numberOfSelectedPax(),
        totalFlightsInSegment: this.dataSource.totalFlightsInSegment(),
        isPassTraveler: this.dataSource.isPassTraveler(),
        isPositiveSpace: this.dataSource.isPositiveSpace(),
        alreadyCheckedInPaxNames: this.dataSource.alreadyCheckedInPaxNames(),
        boardingStartTime: this.dataSource.boardingStartTime(),
        HAMemberStatus: this.dataSource.HAMemberStatus(),
        hawaiianMilesNumber: this.dataSource.hawaiianMilesNumber(),
        paxName: this.dataSource.paxNames(),
        positiveSpace: this.dataSource.positiveSpace(),
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'PnrSearchSuccessful',
        level: LogLevels.INFO,
        keys,
      });
    });
  }

  logWithThisInfo(info: any) {
    const event = {
      applicationVersion: this.dataSource.appVersion(),
      environment: this.dataSource.environment(),
      kioskId: this.dataSource.kioskId(),
      sessionId: this.dataSource.sessionId(),
      clientTimestamp: this.dataSource.localTime(),
      systemTimestamp: this.dataSource.systemTime(),
    };

    if (info.keys && info.keys.timeLapsed === 0) {
      info.keys.timeLapsed = new Date().getTime() - this.sessionTime;
    }

    const withInfo = { ...event, ...info } as LogEvent;

    this.loggingBackend.log(withInfo);
  }

  infoApiPnrFailed(request: HttpRequest<any>, response: HttpResponse<any> | HttpErrorResponse, elapsedTime?: number) {
    const lookupMethod = findFirstParameterThatMatches(
      ['confirmationCode', 'flightNumber', 'ticketNumber', 'hawaiianMilesNumber'],
      request.urlWithParams
    );

    const keys = {
      lookupMethod,
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'PnrSearchFailed',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiCatalogsSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    this.dataSource.onLoadedTrip(() => {
      const keys = {
        timeLapsed: elapsedTime || 0,
        totalAllowances: this.dataSource.totalAllowances(),
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'GetCatalogSuccessful',
        level: LogLevels.INFO,
        keys,
      });
    });
  }

  infoApiCatalogsFailed(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const keys = {
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'GetCatalogFailed',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiBagSaveSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    this.dataSource.onLoadedCart(() => {
      const keys = {
        timeLapsed: elapsedTime || 0,
        totalCost: this.dataSource.runningBaggageTotal(),
        totalRegularBags: this.dataSource.totalRegularBags(),
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'BagsSaveSuccessful',
        level: LogLevels.INFO,
        keys,
      });
    });
  }

  infoApiBagSaveFailed(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const keys = {
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'BagsSaveFailed',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiGetCartSuccessful(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    this.dataSource.onLoadedCart(() => {
      const keys = {
        timeLapsed: elapsedTime || 0,
        totalCost: this.dataSource.runningBaggageTotal(),
        totalRegularBags: this.dataSource.totalRegularBags(),
      };

      this.logWithThisInfo({
        correlationId: request.headers.get('x-root-correlation-id'),
        message: 'GetCartSuccessful',
        level: LogLevels.INFO,
        keys,
      });
    });
  }

  infoApiGetCartFailed(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    const keys = {
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'GetCartFailed',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoUiCheckinStarted(flightData, elapsedTime?: number) {
    this.hasCheckinStarted = true;

    const keys = {
      ...flightData,
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      message: 'CheckinStarted',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoUiSessionTimedOut(elapsedTime?: number) {
    this.logWithThisInfo({
      message: 'SessionTimedOut',
      level: LogLevels.INFO,
      timeLapsed: elapsedTime || 0,
    });
  }

  infoUiSessionStarted(elapsedTime?: number) {
    const event = {
      applicationVersion: this.dataSource.appVersion(),
      environment: this.dataSource.environment(),
      kioskId: this.dataSource.kioskId(),
      sessionId: this.dataSource.sessionId(),
      clientTimestamp: this.dataSource.localTime(),
      systemTimestamp: this.dataSource.systemTime(),
      language: this.dataSource.language(),
    };
    const keys = {
      ...event,
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      message: 'SessionStarted',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoUiSessionEnded(elapsedTime?: number) {
    this.logWithThisInfo({
      message: 'SessionEnded',
      level: LogLevels.INFO,
      timeLapsed: elapsedTime || 0,
    });
  }

  checkinData() {
    return {
      alreadyCheckedIn: this.dataSource.hasAlreadyCheckedIn(),
      marketType: this.dataSource.getMarketType(),
      seatPreference: this.dataSource.getSeatPreference(),
      nonRev: this.dataSource.isNonRev(),
      numberOfPax: this.dataSource.numberOfPax(),
      numberOfPaxSelected: this.dataSource.numberOfSelectedPax(),
      totalBags: this.dataSource.totalBags(),
      totalSpecialItems: this.dataSource.totalSpecialItems(),
      pnr: this.dataSource.pnr(),
      channel: this.dataSource.channel(),
      origin: this.dataSource.origin(),
      destination: this.dataSource.destination(),
      language: this.dataSource.language(),
      numberOfSelectedPax: this.dataSource.numberOfSelectedPax(),
      totalFlightsInSegment: this.dataSource.totalFlightsInSegment(),
      isPassTraveler: this.dataSource.isPassTraveler(),
      isPositiveSpace: this.dataSource.isPositiveSpace(),
    };
  }

  infoUiCheckinComplete(elapsedTime?: number) {
    const keys = {
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      message: 'CheckinComplete',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiCheckinSuccessful(request: HttpRequest<any>, response: HttpResponse<any>, elapsedTime?: number) {
    const data = {
      alreadyCheckedInPaxNames: this.dataSource.alreadyCheckedInPaxNames(),
      numberOfSelectedPax: this.dataSource.numberOfSelectedPax(),
    };

    const keys = {
      ...data,
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'CheckinSuccessful',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiCheckinFailed(request: HttpRequest<any>, response: HttpErrorResponse, elapsedTime?: number) {
    const keys = {
      numberOfSelectedPax: this.dataSource.numberOfSelectedPax(),
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'CheckinFailed',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiPassengersUpdateSuccessful(request: HttpRequest<any>, response: HttpResponse<any>, elapsedTime?: number) {
    const data = this.paxUpdateData();

    const keys = {
      ...data,
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'PassengersUpdateSuccessful',
      level: LogLevels.INFO,
      keys,
    });
  }

  paxUpdateData() {
    const paxData = {
      KTNUpdatedData: this.dataSource.ktnUpdated(),
      redressUpdatedData: this.dataSource.redressUpdated(),
      HMUpdatedData: this.dataSource.hmUpdated(),
    };

    return paxData;
  }

  infoApiAlertReasonCode(request: HttpRequest<any>, response: HttpResponse<any>, elapsedTime?: number) {
    const searchAlert = (alertType) => (key, value) => {
      return key === `alert${alertType}Code` && value.length > 0;
    };

    const alertReasonCode = deepSearch(searchAlert('Reason'), response.body);
    const alertMessageCode = deepSearch(searchAlert('Message'), response.body);

    const keys = {
      timeLapsed: elapsedTime || 0,
      alertReasonCode,
      alertMessageCode,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'CheckInEligibilityWithAlertReasonCode',
      level: LogLevels.INFO,
      keys,
    });
  }

  infoApiPassengersUpdateFailed(request: HttpRequest<any>, response: HttpErrorResponse, elapsedTime?: number) {
    const data = this.paxUpdateData();

    const keys = {
      ...data,
      timeLapsed: elapsedTime || 0,
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'PassengersUpdateFailed',
      level: LogLevels.INFO,
      keys,
    });
  }

  debugApiRequestEnded(
    request: HttpRequest<any>,
    response: HttpResponse<any> | HttpErrorResponse,
    elapsedTime?: number
  ) {
    let payload = null;

    if ((response as HttpResponse<any>).body) {
      const body = (response as HttpResponse<any>).body;
      payload = Logging.encodePayload(body);
    }

    const keys = { timeLapsed: elapsedTime || 0 };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'RequestEnded',
      level: LogLevels.DEBUG,
      payload,
      keys,
    });
  }

  debugApiRequestStarted(request: HttpRequest<any>) {
    const payload = Logging.encodePayload(request.body);

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'RequestStarted',
      level: LogLevels.DEBUG,
      payload,
    });
  }

  errorApiTrackException(request: HttpRequest<any>, response: HttpErrorResponse, elapsedTime?: number) {
    const keys = {
      timeLapsed: elapsedTime || 0,
      errorCode: response.status,
      errorCategory: 'API Exception',
      // We need to truncate the data since it may contain PII
      errorMessage: response.message.replace(/(([^\s]+\s\s*){3})(.*)/, '$1…'),
    };

    this.logWithThisInfo({
      correlationId: request.headers.get('x-root-correlation-id'),
      message: 'TrackAPIException',
      level: LogLevels.ERROR,
      keys,
    });
  }

  logWithUiInfo(custom: any) {
    if (!custom.keys) {
      custom.keys = {};
    }

    custom.keys.language = this.dataSource.language();
    custom.keys.channel = this.dataSource.channel();
    custom.keys.browserUrl = this.dataSource.browserUrl();
    custom.keys.userAgent = this.dataSource.userAgent();

    this.logWithThisInfo({
      level: LogLevels.INFO,
      ...custom,
    });
  }

  infoUiAppInitialized() {
    this.logWithUiInfo({
      message: 'ApplicationInitialized',
    });
  }

  infoUiCheckinCancelled() {
    if (this.hasCheckinStarted) {
      this.hasCheckinStarted = false;

      this.logWithUiInfo({
        message: 'CheckinCancelled',
      });
    }
  }

  infoUiHomePageDisplayed() {
    this.logWithUiInfo({
      message: 'HomePageDisplayed',
    });
  }

  infoUiHomePageLanguageChanged(languageSelected: string, timeLapsed?: number) {
    const keys = {
      languageSelected,
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HomePageLanguageChanged',
      keys,
    });
  }

  infoUiHomePageLookupSelected(lookupMethod: string, timeLapsed?: number) {
    const keys = {
      lookupMethod,
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HomePageLookupMethodSelected',
      keys,
    });
  }

  infoUiChangeLanguageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ChangeLanguagePageDisplayed',
      keys,
    });
  }

  infoUiChangeLanguagePageLanguageSelected(languageSelected: string, timeLapsed?: number) {
    const keys = {
      languageSelected,
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ChangeLanguagePageLanguageSelected',
      keys,
    });
  }

  infoUiChangeLangPageStartOver(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ChangeLanguagePageStarOverSelected',
      keys,
    });
  }

  infoUiStartOverSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      fromPage: this.dataSource.url(),
    };

    this.logWithUiInfo({
      message: 'StartOverSelected',
      keys,
    });
  }

  infoUiChangeLanguageSelected(selectedLanguage: string, timeLapsed?: number) {
    const keys = {
      timeLapsed,
      selectedLanguage,
      language: this.dataSource.language(),
    };

    this.logWithUiInfo({
      message: 'ChangeLanguageSelected',
      keys,
    });
  }

  infoUiChangeLangPageGoBack(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ChangeLanguagePageGoBackSelected',
      keys,
    });
  }

  infoUiAlreadyCheckedInPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      level: LogLevels.INFO,
      message: 'AlreadyCheckedInPageDisplayed',
      keys,
    });
  }

  infoUiAlreadyCheckedInPageCheckBagsSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'AlreadyCheckedInPageCheckBagsSelected',
      keys: { timeLapsed },
    });
  }

  infoUiCheckBagsSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      totalSelectedPax: this.dataSource.numberOfSelectedPax(),
    };

    this.logWithUiInfo({
      message: 'CheckBagsSelected',
      keys,
    });
  }

  infoUiExitSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithUiInfo({
      message: 'ExitSelected',
      keys,
    });
  }

  infoUiAlreadyCheckedinPagePrintOnlySelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      level: LogLevels.INFO,
      message: 'AlreadyCheckedInPagePrintOnlySelected',
      keys,
    });
  }

  infoUiAlreadyCheckedinPageModifySelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'AlreadyCheckedInPageModifyItinerarySelected',
      keys,
    });
  }

  infoUiNextSegmentCheckinDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'NextSegmentCheckInPageDisplayed',
      keys,
    });
  }

  logUiWithCheckinData(msg: string, customKeys?: any) {
    const checkinData = this.checkinData();

    const keys = {
      ...checkinData,
      ...customKeys,
    };

    this.logWithUiInfo({
      message: msg,
      keys,
    });
  }

  infoUiNextSegmentCheckInYesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'NextSegmentCheckInYesSelected',
      keys,
    });
  }

  infoUiNextSegmentCheckInNoSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'NextSegmentCheckInNoSelected',
      keys,
    });
  }

  infoUiTimeOutPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithUiInfo({
      message: 'TimeOutPageDisplayed',
      keys,
    });
  }

  infoUiTimeOutPageYesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithUiInfo({
      message: 'TimeOutPageYesSelected',
      keys,
    });
  }

  infoUiTimeOutPageNoSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithUiInfo({
      message: 'TimeOutPageNoSelected',
      keys,
    });
  }

  infoUiTimeOutPageTimeOut(timeLapsed?: number) {
    const keys = {
      timeLapsed,
      currentPage: this.dataSource.url(),
    };

    this.logWithUiInfo({
      message: 'TimeOutPageTimeOut',
      keys,
    });
  }

  infoUiStartOverPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'StartOverPageDisplayed',
      keys,
    });
  }

  infoUiStartOverPageYesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'StartOverPageYesSelected',
      keys,
    });
  }

  infoUiStartOverPageNoSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'StartOverPageNoSelected',
      keys,
    });
  }

  infoUiItineraryPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ItineraryPageDisplayed',
      keys,
    });
  }

  infoUiItineraryPagePassengerSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ItineraryPagePassengerSelected',
      keys,
    });
  }

  infoUiItineraryPagePassengerUnselected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ItineraryPagePassengerUnselected',
      keys,
    });
  }

  infoUiItineraryPageCheckInSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'ItineraryPageCheckInSelected',
      keys,
    });
  }

  infoUiItineraryPageAddHMKTNRedressSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      level: LogLevels.INFO,
      message: 'ItineraryPageAddHMKTNRedressSelected',
      keys,
    });
  }

  infoUiKTNRedressPaxSelectionPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPaxSelectionPageDisplayed',
      keys,
    });
  }

  infoUiHMKTNRedressPageDisplayed(
    hmPresent: boolean,
    ktnPresent: boolean,
    redressPresent: boolean,
    timeLapsed?: number
  ) {
    const keys = {
      timeLapsed,
      hmPresent,
      ktnPresent,
      redressPresent,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPageDisplayed',
      keys,
    });
  }

  infoUiHMKTNRedressPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPageContinueSelected',
      keys,
    });
  }

  infoUiKTNRedressPageInfoSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPageInfoSelected',
      keys,
    });
  }

  infoUiKTNRedressInfoPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressInfoPageDisplayed',
      keys,
    });
  }

  infoUiKTNRedressPageKTNSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPageKTNSelected',
      keys,
    });
  }

  infoUiKTNRedressPageRedressSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPageRedressSelected',
      keys,
    });
  }

  infoUiKTNRedressPageHawaiianMilesSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPageHMSelected',
      keys,
    });
  }

  infoUiKTNRedressPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HMKTNRedressPageContinueSelected',
      keys,
    });
  }

  infoUiEnterKTNPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'EnterKTNPageDisplayed',
      keys,
    });
  }

  infoUiEnterKTNPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'EnterKTNPageContinueSelected',
      keys,
    });
  }

  infoUiEnterRedressPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'EnterRedressPageDisplayed',
      keys,
    });
  }

  infoUiEnterRedressPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'EnterRedressPageContinueSelected',
      keys,
    });
  }

  infoUiEnterHawaiianMilesPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'EnterHawaiianMilesPageDisplayed',
      keys,
    });
  }

  infoUiEnterHawaiianMilesPageContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'EnterHawaiianMilesPageContinueSelected',
      keys,
    });
  }

  infoUiHazMatPage1Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage1Displayed',
      keys,
    });
  }

  infoUiHazMatPage1ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage1ContinueSelected',
      keys,
    });
  }

  infoUiHazMatPage2Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage2Displayed',
      keys,
    });
  }

  infoUiHazMatPage2ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage2ContinueSelected',
      keys,
    });
  }

  infoUiHazMatPage3Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage3Displayed',
      keys,
    });
  }

  infoUiHazMatPage3ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage3ContinueSelected',
      keys,
    });
  }

  infoUiHazMatPage4Displayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage4Displayed',
      keys,
    });
  }

  infoUiHazMatPage4ContinueSelected(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'HazMatPage4ContinueSelected',
      keys,
    });
  }

  infoUiPrintingDocsPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      message: 'PrintingDocsPageDisplayed',
      keys,
    });
  }

  infoUiConfirmationPageDisplayed(timeLapsed?: number) {
    const keys = {
      timeLapsed,
    };

    this.logWithUiInfo({
      level: LogLevels.INFO,
      message: 'ConfirmationPageDisplayed',
      keys,
    });
  }

  infoUiErrorPageDisplayed(alertReasonCode?: string, alertMessageCode?: string, timeLapsed?: number) {
    const keys = {
      timeLapsed,
      alertReasonCode,
      alertMessageCode,
    };

    this.logWithUiInfo({
      message: 'ErrorPageDisplayed',
      keys,
    });
  }

  infoUiKioskUnavailablePageDisplayed() {
    this.logWithUiInfo({
      message: 'KioskUnavailablePageDisplayed',
    });
  }

  infoUiFlightNumberLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLastNameLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberLastNameLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLastNameLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberLastNameLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberLastNameLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberLastNameLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberDayOfBirthLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberDayOfBirthLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberDayOfBirthLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberDayOfBirthLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiFlightNumberDayOfBirthLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'FlightNumberDayOfBirthLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiConfirmationCodeLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'ConfirmationCodeLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiConfirmationCodeLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'ConfirmationCodeLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiConfirmationCodeLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'ConfirmationCodeLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiHawaiianMilesLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'HawaiianMilesLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiHawaiianMilesLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'HawaiianMilesLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiHawaiianMilesLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'HawaiianMilesLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiEticketLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'EticketLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiEticketLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'EticketLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiEticketLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'EticketLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLookupPageContinueSelected(selectedDestination: string, timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationLookupPageContinueSelected',
      keys: { timeLapsed, selectedDestination },
    });
  }

  infoUiDestinationLookupPageSearchSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationLookupPageSearchSelected',
      keys: { timeLapsed },
    });
  }

  infoUiAirportCodeLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'AirportCodeLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiAirportCodeLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'AirportCodeLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiAirportCodeLookupPageContinueSelected(selectedDestination: string, timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'AirportCodeLookupPageContinueSelected',
      keys: { selectedDestination, timeLapsed },
    });
  }

  infoUiDestinationLastNameLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationLastNameLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLastNameLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationLastNameLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationLastNameLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationLastNameLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationDayOfBirthLookupPageDisplayed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationDayOfBirthLookupPageDisplayed',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationDayOfBirthLookupPageStartOverSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationDayOfBirthLookupPageStartOverSelected',
      keys: { timeLapsed },
    });
  }

  infoUiDestinationDayOfBirthLookupPageContinueSelected(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'DestinationDayOfBirthLookupPageContinueSelected',
      keys: { timeLapsed },
    });
  }

  infoUiPaymentSuccessful(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'PaymentSuccessful',
      keys: { timeLapsed },
    });
  }

  infoUiPaymentFailed(timeLapsed?: number) {
    this.logWithUiInfo({
      message: 'PaymentFailed',
      keys: { timeLapsed },
    });
  }

  infoSessionBoardingPassesToPrint(numOfBoardingPasses: number, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'NumberOfBoardingPassesToPrint',
      data: numOfBoardingPasses,
      level: LogLevels.INFO,
    });
  }

  infoPassportDataInvalid() {
    this.logWithThisInfo({ message: 'InvalidPassportScanned' });
  }

  infoHardwareATBPrinterEnabled(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterEnabled',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareBarcodeEnabled(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeEnabled',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareBarcodeScanned(barcodeScan: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeScanned',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwareBarcodeDamaged(barcodeData: [], timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeError',
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareBarcodeError(barcodeData: [], timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BarcodeError',
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  infoHardwarePassportScanned(passportScan: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'PassportScanned',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwarePassportDamaged(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'PassportDamaged',
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  infoHardwareCounterAssistPrinted(counterAssistData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'CounterAssistTicketPrinted',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareATBPrinterTicketPrinted(boardingPassData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterTicketPrinted',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwareATBPrinterTicketFailed(ticketData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterTicketFailed',
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareATBPrinterPectabFailed(pectabData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterPectabFailed',
      data: pectabData,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareATBPrinterError(printerErrorCode: number, isFatalError: boolean, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'ATBPrinterError',
      hardwareError: {
        code: printerErrorCode,
        FatalError: isFatalError,
      },
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  infoHardwareBagTagPrinterEnabled(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterEnabled',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  infoHardwareBagTagPrinterTicketPrinted(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterTicketPrinted',
      level: LogLevels.INFO,
      keys: { timeLapsed },
    });
  }

  errorHardwareBagTagPrinterTicketFailed(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterTicketFailed',
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareBagTagPrinterPectabFailed(pectabData: string, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterPectabFailed',
      data: pectabData,
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareBagTagPrinterError(printerErrorCode: number, isFatalError: boolean, timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'BagTagPrinterError',
      hardwareError: {
        code: printerErrorCode,
        FatalError: isFatalError,
      },
      level: LogLevels.ERROR,
      keys: { timeLapsed },
    });
  }

  errorHardwareCardReaderError(cardReaderErrorCode: number, isFatalError: boolean) {
    this.logWithThisInfo({
      message: 'CardReaderError',
      hardwareError: {
        code: cardReaderErrorCode,
        FatalError: isFatalError,
      },
      level: LogLevels.ERROR,
    });
  }

  infoHardwareCardReaderSwipe() {
    this.logWithThisInfo({
      message: 'CardReaderSwipe',
      level: LogLevels.INFO,
    });
  }

  infoHardwareBadCardSwipe(swipeCount: number) {
    this.logWithThisInfo({
      message: 'BadCardSwiped',
      level: LogLevels.INFO,
    });
  }

  infoHardwareBagSwipePayment() {
    this.logWithThisInfo({
      message: 'BagSwipePayment',
      level: LogLevels.INFO,
    });
  }

  infoHardwareCreditCardDippedPaymentSuccessful() {
    this.logWithThisInfo({
      message: 'CreditCardDippedPaymentSuccessful',
      level: LogLevels.INFO,
    });
  }

  infoHardwareCreditCardDippedPaymentFailed(errorMessage, attemptNumber: number) {
    this.logWithThisInfo({
      message: 'CreditCardDippedPaymentFailed',
      hardwareError: errorMessage,
      attempt: attemptNumber,
      level: LogLevels.INFO,
    });
  }

  infoApiPaymentTokenizationSuccessful() {
    this.logWithThisInfo({
      message: 'PaymentTokenizationSuccessful',
      level: LogLevels.INFO,
    });
  }

  infoApiPaymentTokenizationFailed(attemptNumber: number) {
    this.logWithThisInfo({
      message: 'PaymentTokenizationFailed',
      attempt: attemptNumber,
      level: LogLevels.INFO,
    });
  }

  infoApiCheckoutSuccessful(timeLapsed?: number) {
    this.dataSource.onLoadedTrip(() => {
      const keys = {
        timeLapsed,
        runningBaggageTotal: this.dataSource.runningBaggageTotal(),
        totalPaymentsCollected: this.dataSource.totalPaymentsCollected(),
        totalRegularBagsAllowanceUsed: this.dataSource.totalRegularBagsAllowanceUsed(),
        totalRegularBags: this.dataSource.totalRegularBags(),
      };

      this.logWithThisInfo({
        message: 'CheckoutSuccessful',
        level: LogLevels.INFO,
        keys,
      });
    });
  }

  infoApiCheckoutFailed(errorMessage) {
    this.logWithThisInfo({
      message: 'CheckoutFailed',
      error: errorMessage,
      level: LogLevels.INFO,
    });
  }

  infoApiGetPaymentHeader() {
    this.logWithThisInfo({
      message: 'GetPaymentHeader',
      level: LogLevels.INFO,
    });
  }

  infoUiNoBagsToCheckSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'NoBagsToCheckSelected',
      level: LogLevels.INFO,
      keys: {
        totalSelectedPax: this.dataSource.numberOfSelectedPax(),
        timeLapsed,
      },
    });
  }

  infoUiPaymentDueModifyBaggageSelected(timeLapsed?: number) {
    this.logWithThisInfo({
      message: 'PaymentDueModifyBaggageSelected',
      level: LogLevels.INFO,
      keys: {
        totalSelectedPax: this.dataSource.numberOfSelectedPax(),
        timeLapsed,
      },
    });
  }

  logException(exception: any) {
    // This will be done as part of followup logging issues ticket
  }
}
