import { Component, OnInit } from '@angular/core';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-about-bags',
  templateUrl: './about-bags.component.html',
  styleUrls: ['./about-bags.component.scss'],
})
export class AboutBagsComponent implements OnInit {
  bagUrl = AppRoutes.BAGS;
  constructor() {}

  ngOnInit() {}
}
