import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AppState,
  ChangeSeatsButtonClicked,
  CheckBagsButtonClicked,
  CheckInButtonClicked,
  EmptyCheckout,
  Passenger,
  Trip,
  TripEffects,
} from 'src/app/state';
import { Observable, Subscription, Subject } from 'rxjs';
import { SegmentEffects } from 'src/app/state/trip/segment/segment.effects';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/api/config/config.service';
import { Logging } from '../../../../services/logging/logging.service';
import { Cart } from '../../../../state/cart/cart.model';
import { AppRoutes } from 'src/app/app-routing.module';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-itinerary-services',
  templateUrl: './itinerary-services.component.html',
  styleUrls: ['./itinerary-services.component.scss'],
})
export class ItineraryServicesComponent implements OnInit, OnDestroy {
  public configSubscribe: Subscription;
  public printSelected = false;
  public hasPaxChecked = false;
  public seatPreferenceConfig: boolean;
  public standbyUpgradeConfig: boolean;
  public enableSeatsConfig: boolean;
  public enableBagsConfig: boolean;
  public isNonRevSpaceAvailable: boolean;
  public passengers: Passenger[];
  public showSeatPreference: boolean;
  public showChangeSeats: boolean;
  public isMidPoint: boolean;
  public hasBagsCheckedIn: boolean;
  public catalogs$: Observable<any>;
  public carts$: Observable<any>;
  public checkin$: Observable<any>;
  public unsubscribe$ = new Subject<void>();

  @Input() trip: Trip;
  @Input() cart: Cart;

  constructor(
    private store: Store<AppState>,
    public tripEffects: TripEffects,
    public segmentEffects: SegmentEffects,
    private router: Router,
    public configService: ConfigService,
    private logging: Logging
  ) {
    this.configSubscribe = this.configService.config.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      if (x.configuration) {
        this.seatPreferenceConfig = x.configuration.seatPreference;
        this.standbyUpgradeConfig = x.configuration.standbyUpgrade;
        this.enableSeatsConfig = x.configuration.enableSeats;
        this.enableBagsConfig = x.configuration.enableBags;
      }
    });
  }

  ngOnInit() {
    this.passengers = this.trip.passengers;
    this.hasPaxChecked = this.trip.activeSegment.hasCheckedInPax();
    this.isNonRevSpaceAvailable = this.trip.isNonRevSpaceAvailable();
    this.showSeatPreference = this.isNonRevSpaceAvailable && !this.hasPaxChecked && this.seatPreferenceConfig;
    this.showChangeSeats = !this.isNonRevSpaceAvailable && this.enableSeatsConfig;
    this.isMidPoint = this.trip.activeSegment.isOnMidPointJourney();
    this.hasBagsCheckedIn = this.trip.activeSegment.getNumBags() > 0;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkInButtonClicked() {
    this.logging.infoUiNoBagsToCheckSelected(0);
    if (!this.cart.id) {
      this.store.dispatch(new CheckInButtonClicked());
    }

    if (this.cart.id && this.cart.grandTotal > 0) {
      this.router.navigate([AppRoutes.PAYMENT]);
    }

    if (this.cart.id && this.cart.grandTotal === 0 && this.cart.items.length > 0) {
      this.store.dispatch(new EmptyCheckout());
      this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
    }
  }

  showStandbyButton() {
    const show = this.passengers.some((passenger) => {
      return passenger.isHawaiianMilesEliteMember();
    });

    return show && this.standbyUpgradeConfig;
  }

  changeSeatsClick() {
    this.store.dispatch(new ChangeSeatsButtonClicked());
  }

  checkBagsClick() {
    this.logging.infoUiCheckBagsSelected(0);
    this.store.dispatch(new CheckBagsButtonClicked());
  }
}
