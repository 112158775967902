import { Injectable } from '@angular/core';
import * as dateFns from 'date-fns';

import { API } from '../api.service';
import { HaCussService } from '../../ha-cuss/ha-cuss.service';
import { selectedError } from '../../../state/error/error.selector';
import { AppState, Segment, Trip } from '../../../state';
import { select, Store } from '@ngrx/store';
import { Receipt } from '../../../state/document/document.model';
import { Payment, PointOfSale } from '../../../state/cart/cart.model';
import { ConfigService } from '../config/config.service';
import { TripService } from '../trip/trip.service';
import { MachineConfig } from '../config/config';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  currentError;
  private cartPaymentInformation: Payment;
  private bagTagKeys: string[] = [];

  constructor(
    private api: API,
    private haCuss: HaCussService,
    private store: Store<AppState>,
    private configService: ConfigService,
    private tripService: TripService
  ) {
    this.store.pipe(select(selectedError)).subscribe((currentError) => {
      this.currentError = currentError;
    });
  }
  private baseUrl = `${this.api.baseUrl}/${this.api.basePath}/${this.api.apiPaths.document.root}`;
  private bagsUrl = `${this.api.baseUrl}/${this.api.basePath}/${this.api.apiPaths.bags}`;

  static getCustomerLastName(detail) {
    let lastName: string;
    if (!detail || typeof detail !== 'object' || Object.keys(detail).length === 0) {
      lastName = '';
    } else {
      lastName = 'passengerName' in detail ? detail.passengerName.lastName : '';
    }
    return lastName;
  }

  static getCustomerFirstName(detail) {
    let firstName: string;
    if (!detail || typeof detail !== 'object' || Object.keys(detail).length === 0) {
      firstName = '';
    } else {
      firstName = 'passengerName' in detail ? detail.passengerName.firstName : '';
    }
    return firstName;
  }

  setCartPaymentInformation(cardInfo) {
    this.cartPaymentInformation = cardInfo;
  }

  setBoardingPassData(trip) {
    this.resetQueryParams();

    const segment = trip.activeSegment;
    const paxInfo = [];

    trip.activeSegment.getPaxIdsSelectedForCheckin().map((paxId) => {
      paxInfo.push({ id: paxId });
    });

    const body = {
      isHAKiosk: true,
      documentType: this.api.apiPaths.document.boardingPass,
      documentData: {
        tripId: trip.id,
        segmentId: segment.id,
        passengerInformation: paxInfo,
      },
    };
    this.api.updateCorrelationId(trip.id);
    return this.api.doPostCall(this.baseUrl, body);
  }

  setCounterAssistTicketData(trip) {
    this.resetQueryParams();

    const segment = trip.activeSegment;
    const date = dateFns.format(new Date(), 'YYYY-MM-DD');
    if (this.currentError.value.codes.alertReasonCode.match(/^(Issue With Payment|Bag Fulfilment Issue)$/)) {
      segment.details[0].alertReasonCode = this.currentError.value.codes.alertReasonCode;
    }
    const body = {
      isHAKiosk: true,
      documentType: this.api.apiPaths.document.counterAssist,
      documentData: {
        errorCode: segment.details[0].alertReasonCode,
        errorMessage1: segment.details[0].alertMessageCode,
        errorMessage2: '',
        confirmationCode: trip.confirmationCode,
        flightNumber: segment.flights[0].flightNumber,
        additionalDetails: {
          kioskId: this.haCuss.getKioskId(),
          createdDate: date,
        },
        isOaEmpire: trip.flights[0].isOaEmpire().toString(),
        origin: trip.activeSegment.origin,
        checkinMethod: 'KIOSK',
        bookingBarcode: trip.confirmationCode,
      },
    };
    this.api.updateCorrelationId(trip.id);
    return this.api.doPostCall(this.baseUrl, body);
  }

  setReceiptTicketData(trip, cart) {
    this.resetQueryParams();
    const segment = trip.activeSegment;
    const date = dateFns.format(new Date(), 'YYYY-MM-DD');
    const body: Receipt = this.getReceiptBody(cart, trip, date, segment);
    this.api.updateCorrelationId(trip.id);
    return this.api.doPostCall(this.baseUrl, body);
  }

  setBagTagData(trip: Trip, segment: Segment): Promise<any> {
    this.resetQueryParams();
    let bagsToPrint = {};
    let body = {};
    const details = trip.activeSegment.details;
    bagsToPrint = details.map((segmentDetail) => {
      return {
        id: segmentDetail.passengerId,
        bags: segmentDetail.bags.map((bag) => {
          return {
            productId: bag.productId,
            quantity: bag.count,
            type: 'regularBag',
            weight: {
              unit: 'LBS',
              value: '50',
            },
            length: {
              unit: 'IN',
              value: '0',
            },
          };
        }),
      };
    });

    const tripId = trip.id;
    const segmentId = segment.id;
    this.configService.config
      .subscribe((response) => {
        if (response && response.configuration) {
          body = {
            isHAKiosk: true,
            documentType: this.api.apiPaths.document.bagTag,
            documentData: {
              tripId,
              segmentId,
              kioskId: this.haCuss.getKioskId(),
              kioskStock: response.bagTag.size,
              kioskStockOrientation: response.bagTag.orientation,
              kioskBrand: response.bagTag.brand,
              passengerInformation: bagsToPrint,
            },
          };
          this.api.updateCorrelationId(tripId);
        }
      })
      .unsubscribe();

    return this.api.doPostCall(this.baseUrl, body);
  }

  resetBagTagKeys() {
    this.bagTagKeys = [];
  }

  loadBagTagKey(key) {
    this.bagTagKeys.push(key);
  }

  getNextBagTagKey() {
    if (this.bagTagKeys.length === 0) {
      return null;
    } else {
      const nextKey = this.bagTagKeys[0];
      this.bagTagKeys.shift();
      return nextKey;
    }
  }

  reportBagTagPrinted(bagTagKey) {
    this.resetQueryParams();
    const body = [
      {
        path: `${bagTagKey}/printed`,
        op: 'replace',
        value: 'true',
      },
    ];
    this.api.updateCorrelationId(bagTagKey);
    return this.api.doPatchCall(this.bagsUrl, body);
  }

  private resetQueryParams() {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }
  }

  private getReceiptBody(cart, trip, date, segment) {
    return {
      isHAKiosk: true,
      documentType: this.api.apiPaths.document.receipt,
      documentData: {
        cardName: this.cartPaymentInformation.cardType || '',
        authorizationCode: this.cartPaymentInformation.cardType || '',
        totalPrice: cart.grandTotal.toString(10) || '',
        confirmationCode: trip.confirmationCode || '',
        hawaiianMilesNumber: trip.passengers.hawaiianMilesNumber || '',
        issueLocation: trip.kioskAirportCode || '',
        issueDate: date || '',
        formOfPayment: this.cartPaymentInformation.paymentType || '',
        cardNumber: this.getShortCardNum() || '',
        lineItem: this.getLineItem(cart, segment, date),
        bagText: '',
        isOAEmpire: trip.flights[0].isOaEmpire().toString() || '',
      },
    };
  }

  private getShortCardNum() {
    return this.cartPaymentInformation.paymentToken.substr(this.cartPaymentInformation.paymentToken.length - 4);
  }

  private getLineItem(cart, segment, date) {
    const lineItems = [];
    const itemsPurchased = cart.items.filter((item) => parseFloat(item.price) > 0);
    itemsPurchased.forEach((item) => {
      const id = item.associatedPassenger.passengerId;
      const associatedDetail = this.tripService.getBagLineItemInfo().find((bagItem) => bagItem.passengerId === id);
      const lineItem = {
        receiptNumber: associatedDetail.bags[0].receiptNumber || '',
        // This will be replaced with the call to the content API when it becomes available
        description: 'Regular Bag' || '',
        itemPrice: item.price || '',
        flightNumber: segment.flights[0].flightNumber || '',
        departureDate: date || '',
        lastName: DocumentService.getCustomerLastName(associatedDetail) || '',
        firstName: DocumentService.getCustomerFirstName(associatedDetail) || '',
      };
      lineItems.push(lineItem);
    });
    return lineItems;
  }

  getPointOfSale(): PointOfSale {
    const pointOfSale: PointOfSale = {
      location: {
        cityCode: '',
        stationCode: '',
      },
    };

    this.configService.config.subscribe((response: MachineConfig) => {
      pointOfSale.location.cityCode = response.airportCode;
    });

    return pointOfSale;
  }
}
