import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTrips from './trip.reducer';
import { selectFlightStatuses } from '../flight-status/flight-status.selectors';

export const selectTripState = createFeatureSelector<fromTrips.TripState>('trips');

export const selectCurrentTrip = createSelector(selectTripState, (tripState) => tripState.trip);

export const selectActiveSegment = createSelector(selectCurrentTrip, (trip) => (trip ? trip.activeSegment : null));

export const selectActiveSegmentDetails = createSelector(selectActiveSegment, (segment) =>
  segment ? segment.details : []
);

export const selectCurrentTripFlightStatus = createSelector(
  selectCurrentTrip,
  selectFlightStatuses,
  (trip, flightStatuses) => {
    if (!trip || !trip.activeSegment || !trip.activeSegment.nextFlight) {
      return;
    }

    const flightId = trip.activeSegment.nextFlight.id;
    return flightStatuses.get(flightId);
  }
);

export const selectSelectedPax = createSelector(selectCurrentTrip, (trip) => {
  return trip.activeSegment.details.filter((x) => x.selected);
});
