import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ViewChild,
  AfterContentInit,
} from '@angular/core';
import { AccessibilityService } from 'src/app/services/accessibility/accessibility.service';
import { StartOverComponent } from 'src/app/shared/start-over/start-over.component';

@Component({
  selector: 'page-hazmat',
  templateUrl: './hazmat.component.html',
  styleUrls: ['./hazmat.component.scss'],
})
export class HazmatComponent implements OnInit, AfterViewInit {
  public showAgricultureText = true;
  @ViewChild('acaa', { static: false }) title: ElementRef;
  @ViewChild(StartOverComponent, { static: false }) startOverElement: StartOverComponent;

  constructor(private accessibilityService: AccessibilityService, private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const elements = [this.title.nativeElement, this.startOverElement.buttonElement.nativeElement];
    const clearChildrenElements = false;
    this.accessibilityService.setParentElementsToRead(elements, clearChildrenElements);
  }
}
