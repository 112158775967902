import { Component, Input, OnChanges, SimpleChanges, Output, ElementRef, AfterContentChecked } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Cart } from 'src/app/state/cart/cart.model';
import { Store } from '@ngrx/store';
import { AppState, UpdateCartAndGoToNextStep, CartState } from 'src/app/state';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';
import { EventEmitter } from '@angular/core';
import { AccessibilityService } from 'src/app/services/accessibility/accessibility.service';

@Component({
  selector: 'app-bags-notice',
  templateUrl: './bags-notice.component.html',
  styleUrls: ['./bags-notice.component.scss'],
})
export class BagsNoticeComponent implements OnChanges, AfterContentChecked {
  @Input() cart: Cart;
  public cartSubscription: Subscription;
  unsubscribe$ = new Subject<void>();
  @Input() showModal: boolean;
  @Output() showModalChanged: EventEmitter<any> = new EventEmitter<any>();
  private specialItemsCount = 0;
  private specialItemsTotalPrice = '0.00';
  private regularBagsCount = '';
  private regularBagsTotalPrice = '';
  private totalPrice = '';

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private el: ElementRef,
    private accessibilityService: AccessibilityService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.regularBagsCount = this.getCountRegularBags();
    this.regularBagsTotalPrice = this.getRegularBagsTotalPrice();
    this.totalPrice = this.getTotalPrice();
  }

  ngAfterContentChecked(): void {
    this.checkElements();
  }

  public getElements() {
    const elements = this.el.nativeElement.querySelectorAll('[tabindex]');
    return elements;
  }

  private checkElements(): void {
    if (this.showModal) {
      const elements = this.getElements();
      if (elements.length > 0) {
        this.accessibilityService.setElementsToRead(elements);
        this.accessibilityService.navigateToNextElement();
      }
    }
  }

  back() {
    this.showModal = false;
    this.showModalChanged.emit(this.showModal);
  }

  continue() {
    if (this.cart && this.cart.items.length > 0) {
      this.store.dispatch(new UpdateCartAndGoToNextStep());
    } else {
      this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
    }
  }

  getCountRegularBags() {
    if (this.cart.items) {
      let count = 0;

      this.cart.items.forEach((item) => {
        count += item.quantity;
      });

      return count.toString();
    }

    return '';
  }

  getCountSpecialItems() {
    return this.specialItemsCount;
  }

  getRegularBagsTotalPrice() {
    if (this.cart.items) {
      let totalPrice = 0;

      this.cart.items.forEach((item) => {
        totalPrice += parseFloat(item.price);
      });

      return totalPrice.toString();
    }
  }

  getSpecialItemsTotalPrice() {
    return this.specialItemsTotalPrice;
  }

  getTotalPrice() {
    return this.getRegularBagsTotalPrice(); // we don't have yet special items
  }
}
