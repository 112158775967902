import { Component, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ResetContent, SetContent } from '../../../../state/keyboard/keyboard.action';
import { PnrLocatorFlow } from '../../../../state/pnr-locator-flow/pnr-locator-flow.model';
import { AppState, Authenticate } from '../../../../state';
import { Keyboard } from '../../../../state/keyboard/keyboard.model';
import { PnrLocatorFlowUpdateState } from '../../../../state/pnr-locator-flow/pnr-locator-flow.action';
import { Logging } from '../../../../services/logging/logging.service';

@Component({
  selector: 'app-by-birthday',
  templateUrl: './by-birthday.component.html',
  styleUrls: ['./by-birthday.component.scss', './../locate-reservation.component.scss'],
})
export class ByBirthdayComponent implements OnInit, OnDestroy {
  keyboard$: Observable<Keyboard>;
  pnrLocatorFlow$: Observable<PnrLocatorFlow>;

  public pnrLocatorFlowSubscribe: Subscription;
  public keyboardSubscriber: Subscription;
  public code = '';
  public codeDone = false;
  public monthCode = '';
  public dayCode = '';
  public yearCode = '';
  public focus = 'month';
  public error = false;
  public showError = false;
  public activeGoBack = false;
  private pnrLocatorFlow: PnrLocatorFlow;
  public currentYear = 0;
  public currentDay = 0;
  public currentMonth = 0;

  constructor(private store: Store<AppState>, private logging: Logging) {
    this.currentYear = new Date().getFullYear();
    this.currentDay = new Date().getDate();
    this.currentMonth = new Date().getMonth() + 1;
  }

  clear() {
    this.monthCode = this.yearCode = this.dayCode = '';
    this.focus = 'month';
  }

  ngOnInit() {
    this.pnrLocatorFlow$ = this.store.pipe(select('pnrLocatorFlow'));
    this.pnrLocatorFlow$.subscribe((value) => {
      this.pnrLocatorFlow = value;
    });

    this.store.dispatch(new ResetContent());
    this.logPageDisplayedEvent(this.pnrLocatorFlow.lookupMethod);
    this.keyboard$ = this.store.pipe(select('keyboard'));
    this.keyboardSubscriber = this.keyboard$.subscribe((value) => {
      if (value) {
        this.codeDone = false;
        if (value.goBack) {
          if (this.focus === 'year') {
            this.focus = 'day';
            this.store.dispatch(new SetContent(this.dayCode.substring(0, this.dayCode.length - 1)));
          } else {
            this.focus = 'month';
            this.store.dispatch(new SetContent(this.monthCode.substring(0, this.monthCode.length - 1)));
          }
        }

        this.validate(value);

        if (this.error) {
          this.store.dispatch(new SetContent(value.content.substring(0, value.content.length - 1)));
          this.error = false;
        } else {
          if (this.focus === 'month' && this.monthCode.length === 2) {
            if (!value.goBack) {
              this.store.dispatch(new ResetContent());
            }
            this.focus = 'day';
            this.activeGoBack = true;
          }
          if (this.focus === 'day' && this.dayCode.length === 2) {
            if (!value.goBack) {
              this.store.dispatch(new ResetContent());
            }
            this.focus = 'year';
          }
          if (this.focus === 'year' && this.yearCode.length === 4) {
            this.store.dispatch(
              new PnrLocatorFlowUpdateState({
                dateOfBirth: this.yearCode + '-' + this.monthCode + '-' + this.dayCode,
              })
            );
            this.codeDone = true;
          }
        }
      }
    });
  }

  validate(value) {
    if (value.append) {
      this.showError = false;
    }
    switch (this.focus) {
      case 'month':
        this.monthCode = value.content;
        if (value.append) {
          this.validateMonth();
        }
        break;
      case 'day':
        this.dayCode = value.content;
        if (value.append) {
          this.validateDay();
        }
        break;
      case 'year':
        this.yearCode = value.content;
        if (value.append) {
          this.validateYear();
        }
        break;
    }
  }

  ngOnDestroy() {
    if (this.keyboardSubscriber) {
      this.keyboardSubscriber.unsubscribe();
    }
    if (this.pnrLocatorFlowSubscribe) {
      this.pnrLocatorFlowSubscribe.unsubscribe();
    }
  }

  setError() {
    this.showError = this.error = true;
  }

  validateMonth() {
    switch (this.monthCode.length) {
      case 1:
        if (!/[0|1]/.test(this.monthCode)) {
          this.setError();
        }
        break;
      case 2:
        if (parseInt(this.monthCode, 10) > 12 || parseInt(this.monthCode, 10) < 1) {
          this.setError();
        }
        break;
    }
    return true;
  }

  validateDay() {
    switch (this.dayCode.length) {
      case 1:
        const regex = this.monthCode === '02' ? /[0-2]/ : /[0-3]/;
        if (!regex.test(this.dayCode)) {
          this.setError();
        }
        break;
      case 2:
        if (parseInt(this.dayCode, 10) > this.validateDays(this.monthCode) || parseInt(this.dayCode, 10) < 1) {
          this.setError();
        }
        break;
    }
    return true;
  }

  validateYear() {
    const min = (this.currentYear - 120).toString();
    const max = this.currentYear.toString();
    if (
      parseInt(this.yearCode, 10) > parseInt(max.slice(0, this.yearCode.length), 10) ||
      parseInt(this.yearCode, 10) < parseInt(min.slice(0, this.yearCode.length), 10)
    ) {
      this.setError();
    }
    if (max === this.yearCode) {
      if (parseInt(this.monthCode, 10) > this.currentMonth) {
        this.setError();
      }
      if (parseInt(this.monthCode, 10) === this.currentMonth && parseInt(this.dayCode, 10) >= this.currentDay) {
        this.setError();
      }
    }
    return true;
  }

  validateDays(month) {
    switch (month) {
      case '01':
      case '03':
      case '05':
      case '07':
      case '08':
      case '10':
      case '12':
        return 31;
      case '04':
      case '06':
      case '09':
      case '11':
        return 30;
      case '02':
        return 29;
    }
    return false;
  }

  validateByBirthday() {
    if (!this.codeDone) {
      return false;
    }

    switch (this.pnrLocatorFlow.lookupMethod) {
      case 'flightNumberLastName':
        this.logging.infoUiFlightNumberDayOfBirthLookupPageContinueSelected(0);
        this.store.dispatch(
          new Authenticate({
            flightNumber: this.pnrLocatorFlow.flightNumber,
            lastName: this.pnrLocatorFlow.lastName,
            dateOfBirth: this.pnrLocatorFlow.dateOfBirth,
          })
        );
        break;
      case 'destinationLastName':
        this.logging.infoUiDestinationDayOfBirthLookupPageContinueSelected(0);
        this.store.dispatch(
          new Authenticate({
            destination: this.pnrLocatorFlow.destination,
            lastName: this.pnrLocatorFlow.lastName,
            dateOfBirth: this.pnrLocatorFlow.dateOfBirth,
          })
        );
        break;
      case 'creditCard':
        this.store.dispatch(
          new Authenticate({
            firstName: this.pnrLocatorFlow.firstName,
            lastName: this.pnrLocatorFlow.lastName,
            dateOfBirth: this.pnrLocatorFlow.dateOfBirth,
          })
        );
        break;
    }
  }

  logPageDisplayedEvent(lookupMethod) {
    if (lookupMethod === 'destinationLastName') {
      this.logging.infoUiDestinationDayOfBirthLookupPageDisplayed(0);
    } else if (lookupMethod === 'flightNumberLastName') {
      this.logging.infoUiFlightNumberDayOfBirthLookupPageDisplayed(0);
    }
  }
}
