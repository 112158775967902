import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Segment } from '../../state/trip/segment/segment.model';
import { AppState } from '../../state';
import { Trip } from '../../state/trip/trip.model';
import { SegmentDetail } from '../../state/trip/segment/segment-detail.model';
import { selectActiveSegment, selectActiveSegmentDetails, selectCurrentTrip } from '../../state/trip/trip.selectors';
import { selectCart } from '../../state/cart/cart.selector';
import { Cart } from '../../state/cart/cart.model';

@Injectable({ providedIn: 'root' })
export class StoreRecorder {
  public segment$: Observable<Segment>;
  public cart$: Observable<Cart>;
  private state: AppState;
  private activeDetails: Array<SegmentDetail>;
  private segment: Segment;
  private trip: Trip;
  private cart: Cart;

  constructor(private store: Store<AppState>) {
    if (store !== null) {
      this.store.subscribe((state) => {
        this.state = state;
      });

      this.segment$ = this.store.pipe(select(selectActiveSegment), share());

      this.cart$ = this.store.pipe(select(selectCart), share());

      this.cart$.subscribe((cart) => {
        this.cart = cart;
      });

      this.store.pipe(select(selectCurrentTrip)).subscribe((trip) => {
        this.trip = trip;
      });

      this.store.pipe(select(selectActiveSegmentDetails)).subscribe((details) => {
        this.activeDetails = details as SegmentDetail[];
      });

      this.segment$.subscribe((segment) => {
        this.segment = segment;
      });
    }
  }

  getAppState(): AppState {
    return this.state;
  }

  getActiveSegmentDetails(): SegmentDetail[] {
    return this.activeDetails;
  }

  getActiveSegment(): Segment {
    return this.segment;
  }

  getCurrentTrip(): Trip {
    return this.trip;
  }

  getCurrentCart(): Cart {
    return this.cart;
  }
}
