import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  constructor(public router: Router) {}

  showStepper(route) {
    const possibleRoutes = [
      /^\/itinerary/,
      /^\/seat-preference/,
      /^\/hazmat/,
      /^\/locate-reservation/,
      /^\/print-documents/,
      /^\/confirmation/,
      /^\/pax-edit/,
      /^\/bags/,
      /^\/options/,
      /^\/payment/,
      /^\/special-items/,
    ];

    return possibleRoutes.reduce((a, b) => a || RegExp(b).test(route), false);
  }

  currentStep(route) {
    switch (true) {
      case /^\/locate-reservation/.test(route):
      case /^\/seat-preference/.test(route):
      case /^\/options/.test(route):
      case /^\/pax-edit/.test(route):
      case /^\/itinerary/.test(route): {
        return 'step-1';
      }
      case /^\/payment/.test(route):
      case /^\/special-items/.test(route):
      case /^\/bags/.test(route): {
        return 'step-2';
      }
      case /^\/confirmation/.test(route):
      case /^\/print-documents/.test(route):
      case /^\/hazmat/.test(route): {
        return 'step-3';
      }
      default: {
        return false;
      }
    }
  }

  ngOnInit() {}
}
