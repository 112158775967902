import { Action } from '@ngrx/store';

export enum DocumentActionTypes {
  DocumentPrintingDataRequested = '[Document] Document printing data requested',
  DocumentPrintingDataPrinted = '[Document] Document data printed',
  CounterAssistTicketDataRequested = '[Document] Counter Assist ticket data requested',
  PaymentReceiptDataRequested = '[Document] Payment Receipt data requested',
  BagTagDataRequested = '[Document] Bag Tag data requested',
  BagTagDataPrinted = '[Document] Bag Tags printed',
}

export class DocumentPrintingDataRequested implements Action {
  readonly type = DocumentActionTypes.DocumentPrintingDataRequested;
}

export class DocumentPrintingDataPrinted implements Action {
  readonly type = DocumentActionTypes.DocumentPrintingDataPrinted;
}

export class CounterAssistTicketDataRequested implements Action {
  readonly type = DocumentActionTypes.CounterAssistTicketDataRequested;
  constructor(public payload: { alertReasonCode: string }) {}
}

export class PaymentReceiptDataRequested implements Action {
  readonly type = DocumentActionTypes.PaymentReceiptDataRequested;
}

export class BagTagDataRequested implements Action {
  readonly type = DocumentActionTypes.BagTagDataRequested;
}

export class BagTagDataPrinted implements Action {
  readonly type = DocumentActionTypes.BagTagDataPrinted;
}

export type DocumentActions =
  | DocumentPrintingDataRequested
  | CounterAssistTicketDataRequested
  | PaymentReceiptDataRequested
  | BagTagDataRequested
  | BagTagDataPrinted;
