import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  Authenticate,
  CatalogLoadRequested,
  CatalogsChanged,
  TripActionTypes,
  TripChanged,
  TripFirstLoadRequested,
  TripFlightStatusRequested,
  TripLoaded,
} from './trip.actions';
import { AddAuthToken, AuthTokenActionTypes } from '../auth-token/auth-token.actions';
import { SetDelayedFlightNotification, SetReferToAgentDontPrintError } from '../error/error.action';
import { FlightStatuses } from './flight/flight.model';
import { FlightStatus } from '../flight-status/flight-status.model';
import { FlightStatusLoaded, FlightStatusRequested } from '../flight-status/flight-status.actions';
import { TripService } from '../../services/api/trip/trip.service';
import { FlightService } from '../../services/api/flight/flight.service';
import { AuthenticationService } from '../../services/api/authentication/authentication.service';
import { Trip } from './trip.model';
import { AppState, AuthTokenState } from '../index';
import { selectCurrentTrip } from './trip.selectors';
import { errorHelpers } from '../error/error-helpers';
import { DeviceService } from '../../services/ha-cuss/device.service';
import { CatalogService } from '../../services/api/catalog/catalog.service';
import { ConfigService } from '../../services/api/config/config.service';
import { CardReaderResetCount } from '../ha-cuss-device/card-reader/card-reader.actions';
import { Logging } from '../../services/logging/logging.service';
import { locatedPnr } from '../../services/emitters/session-event-emitters';
import { CatalogIdTypes } from './passenger.catalog.model';
import { AppRoutes } from 'src/app/app-routing.module';

@Injectable()
export class TripEffects {
  public enableInfantConfig;
  public enableInternationalConfig;
  public airportCodeConfig;
  public catalogs$: Observable<any>;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private flightService: FlightService,
    private authService: AuthenticationService,
    private tripService: TripService,
    private deviceService: DeviceService,
    public catalogService: CatalogService,
    public configService: ConfigService,
    protected logging: Logging
  ) {
    this.configService.config.subscribe((x) => {
      if (x && x.configuration) {
        this.enableInfantConfig = x.configuration.enableInfants;
        this.enableInternationalConfig = x.configuration.enableInternational;
        this.airportCodeConfig = x.airportCode;
      }
    });
  }

  @Effect({ dispatch: false })
  loadFirstTrip$ = this.actions$.pipe(
    ofType(TripActionTypes.TripFirstLoadRequested),
    withLatestFrom(this.store.select('authentication'), this.store.select('pnrLocatorFlow')),
    switchMap(([_, authToken, pnrLocatorFlow]) => {
      return from(this.tripService.getTripDataBased(authToken, pnrLocatorFlow)).pipe(
        map((tripResponse: any) => {
          if (!tripResponse || !tripResponse.results || tripResponse.results.length === 0) {
            return;
          }

          this.resetsHardwareAndDisableDevices();

          let trip = this.deserializeTrip(tripResponse);

          if (!trip) {
            return;
          }
          this.store.dispatch(new TripLoaded({ trip }));
          trip = this.validateTrip(trip);

          // We only want to dispatch these actions if we have a truthy trip
          if (trip) {
            TripEffects.setSelectedSegmentDetails(trip);
            this.store.dispatch(new CatalogLoadRequested());
            this.store.dispatch(new TripFlightStatusRequested());
          }

          return trip;
        })
      );
    })
  );

  @Effect({ dispatch: false })
  loadTrip$ = this.actions$.pipe(
    ofType(TripActionTypes.TripLoadRequested),
    withLatestFrom(
      this.store.select((store) => store.authentication),
      this.store.select((store) => store.pnrLocatorFlow)
    ),
    switchMap(([_, authToken, pnrLocatorFlow]) => {
      return from(this.tripService.getTripDataBased(authToken, pnrLocatorFlow)).pipe(
        map((tripResponse: any) => {
          if (!tripResponse || !tripResponse.results || tripResponse.results.length === 0) {
            return;
          }

          this.resetsHardwareAndDisableDevices();

          let trip = this.deserializeTrip(tripResponse);
          trip = this.validateTrip(trip);

          if (!trip) {
            return;
          }

          this.store.dispatch(new TripLoaded({ trip }));
          if (trip) {
            TripEffects.setSelectedSegmentDetails(trip);
            this.store.dispatch(new CatalogLoadRequested());
          }

          return trip;
        })
      );
    })
  );

  @Effect({ dispatch: false })
  selectNextSegment$ = this.actions$.pipe(
    ofType(TripActionTypes.NextSegmentSelected),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([_, trip]) => {
      const nextSegment = trip.getNextSegmentToCheckIn();
      const isFirstSegment = false;

      if (!nextSegment) {
        return false;
      }

      const newTrip = trip.clone();
      newTrip.activeSegment = newTrip.segments.find((segment) => segment.id === nextSegment.id);

      TripEffects.setSelectedSegmentDetails(newTrip);
      newTrip.activeSegment.nextFlight = newTrip.activeSegment.flights[0];

      this.store.dispatch(new TripChanged({ newTrip }));
      if (!this.dispatchTripErrorActions(newTrip, isFirstSegment)) {
        this.store.dispatch(new TripFlightStatusRequested());
      }
      return newTrip;
    })
  );

  @Effect({ dispatch: false })
  selectCurrentAirportSegment$ = this.actions$.pipe(
    ofType(TripActionTypes.DisplayFlightInfoForKioskOrigin),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([_, trip]) => {
      const newTrip = trip;
      const currentLocationTrip = trip.findActiveSegment();

      newTrip.activeSegment = newTrip.findActiveSegment();
      const flightId = currentLocationTrip.flights[0].id;

      this.store.dispatch(new TripChanged({ newTrip }));
      this.store.dispatch(new FlightStatusRequested({ flightId }));

      return currentLocationTrip;
    })
  );

  @Effect({ dispatch: false })
  authenticate$ = this.actions$.pipe(
    ofType(TripActionTypes.Authenticate),
    map((action: Authenticate) => action.payload),
    switchMap((code) => from(this.authService.authenticate(code))),
    map((response: AuthTokenState) => {
      if (response && response.access_token) {
        this.store.dispatch(new AddAuthToken(response));
      }

      return response;
    }),
    catchError(() => EMPTY)
  );

  @Effect({ dispatch: false })
  getTrip$ = this.actions$.pipe(
    ofType(AuthTokenActionTypes.AddAuthToken),
    map(() => {
      this.store.dispatch(new TripFirstLoadRequested());
    })
  );

  @Effect({ dispatch: false })
  validateFlight$ = this.actions$.pipe(
    ofType(TripActionTypes.TripFlightStatusRequested),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([_, trip]) => {
      this.getFlightStatusAndCheckDelayed(trip.activeSegment.nextFlight.id).subscribe((flightStatusResult) => {
        let route: string;
        if (trip.activeSegment.hasCheckedInPax()) {
          route = AppRoutes.OPTIONS;
        } else {
          route = AppRoutes.ITINERARY;
        }

        if (flightStatusResult) {
          const { flightStatus, flightSubStatus, boardingStatus } = flightStatusResult;
          const boardingStartTime = flightStatusResult.boardingStartTime.airportDateTimeString;
          const flightLoggingData = {
            flightStatus,
            flightSubStatus,
            boardingStatus,
            boardingStartTime,
          };
          this.logging.infoUiCheckinStarted(flightLoggingData);
          if (flightStatusResult.flightStatus === FlightStatuses.Delayed) {
            this.store.dispatch(
              new SetDelayedFlightNotification({
                departureDate: new Date(flightStatusResult.departure.airportDateTimeString),
                okRoute: route,
              })
            );
          } else {
            this.router.navigate([route]);
          }
        } else {
          this.store.dispatch(new SetReferToAgentDontPrintError({ alertReasonCode: '' }));
          throw new Error();
        }
      });
    })
  );

  @Effect({ dispatch: false })
  getCatalog$ = this.actions$.pipe(
    ofType(TripActionTypes.CatalogLoadRequested),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip))),
    switchMap(([_, trip]) => {
      return combineLatest([
        of(trip),
        from(this.catalogService.getCatalogs(trip.confirmationCode, trip.activeSegment.id, trip.id)),
      ]);
    }),
    map(([trip, catalogs]) => {
      const newTrip = trip.clone();
      let seatsCatalogs = [];
      let bagsCatalogs = [];
      if (catalogs.results && catalogs.results.length > 0) {
        seatsCatalogs = catalogs.results.filter((catalog) => catalog.id === CatalogIdTypes.Seats)[0].entries;
        bagsCatalogs = catalogs.results.filter((catalog) => catalog.id === CatalogIdTypes.Bags)[0].entries;
        this.store.dispatch(new CatalogsChanged({ seatsCatalogs, bagsCatalogs }));
      }
      return newTrip;
    })
  );

  @Effect({ dispatch: false })
  updateTrip$ = this.actions$.pipe(
    ofType(TripActionTypes.UpdateTripDataRequested),
    withLatestFrom(
      this.store.select((store) => store.authentication),
      this.store.select((store) => store.pnrLocatorFlow),
      this.store.select(selectCurrentTrip)
    ),
    switchMap(([_, authToken, pnrLocatorFlow, currentTrip]) => {
      return from(this.tripService.getTripDataBased(authToken, pnrLocatorFlow)).pipe(
        map((tripResponse: any) => {
          if (!tripResponse || !tripResponse.results || tripResponse.results.length === 0) {
            return;
          }
          const trip = this.deserializeTrip(tripResponse);

          if (!trip) {
            return;
          }

          this.tripService.setBagLineItemInfo(trip.activeSegment.details);

          if (currentTrip.activeSegment) {
            trip.activeSegment = currentTrip.activeSegment;
          }

          this.store.dispatch(new TripLoaded({ trip }));
          return trip;
        })
      );
    })
  );

  static setSelectedSegmentDetails(trip: Trip) {
    // Set passengers selected by default
    trip.activeSegment.details.forEach((segmentDetail) => {
      segmentDetail.selected = true;
    });
  }

  private deserializeTrip(tripResponse) {
    const tripJson = tripResponse.results[0];

    tripJson.flights = tripJson.flights && tripJson.flights.entries ? tripJson.flights.entries : [];
    tripJson.segments = tripJson.segments && tripJson.segments.entries ? tripJson.segments.entries : [];
    tripJson.passengers = tripJson.passengers && tripJson.passengers.entries ? tripJson.passengers.entries : [];

    return Trip.deserializeFromJson(tripJson, this.airportCodeConfig);
  }

  private resetsHardwareAndDisableDevices() {
    /*At this point we know that we have an active trip and can disable all the methods allowed to lookup with the hardware devices*/
    this.store.dispatch(new CardReaderResetCount());
    locatedPnr.next(true);
    this.deviceService.disableLookupDevices();
  }

  private validateTrip(trip) {
    const isFirstSegment = true;

    if (this.dispatchTripErrorActions(trip, isFirstSegment)) {
      return false;
    }

    const isOnMidPointJourneyNotCheckedIn =
      trip.activeSegment.isOnMidPointJourney() && !trip.activeSegment.areAllPassengersCheckedIn;
    /*
     * The API does not allow printing boarding passes if the segment is not checked in,
     * So if the passenger is on midpoint journey and is not checked in the application will stop him from going any further
     * */
    if (isOnMidPointJourneyNotCheckedIn) {
      this.store.dispatch(new SetReferToAgentDontPrintError({ alertReasonCode: '' }));
      return;
    }

    return trip;
  }

  dispatchTripErrorActions(trip: Trip, isFirstSegment: boolean): boolean {
    if (!trip.activeSegment) {
      this.store.dispatch(new SetReferToAgentDontPrintError({ alertReasonCode: '' }));
      return true;
    }

    if (
      !trip.activeSegment.isOnMidPointJourney() &&
      trip.activeSegment.origin !== this.airportCodeConfig &&
      isFirstSegment
    ) {
      this.store.dispatch(new SetReferToAgentDontPrintError({ alertReasonCode: '' }));
    }

    if (trip.activeSegment.details[0].alertMessageCode) {
      errorHelpers.setSegmentRelatedError(trip.activeSegment, this.store);
      return true;
    }

    /*
     * For now the API does not support infants and international trips so we're displaying
     * a generic error message for those cases
     */
    let checkError = false;
    if (trip.activeSegment.isInternationalMarketType() && !this.enableInternationalConfig) {
      checkError = true;
    }

    if (trip.hasInfant() && !this.enableInfantConfig) {
      checkError = true;
    }

    if (checkError) {
      this.store.dispatch(new SetReferToAgentDontPrintError({ alertReasonCode: '' }));
      return true;
    }

    return false;
  }

  public getFlightStatusAndCheckDelayed(flightId: string): Observable<FlightStatus | any> {
    const promise = this.flightService.getFlightStatusDataBasedId(flightId);

    return from(promise).pipe(
      map((response: any) => {
        let flightResult = null;

        if (response && response.results && response.results.length > 0) {
          flightResult = response.results[0];

          const flightStatus = FlightStatus.deserializeFromJson(flightResult);
          this.store.dispatch(new FlightStatusLoaded({ flightStatus }));
          return flightStatus;
        } else {
          return null;
        }
      })
    );
  }
}
