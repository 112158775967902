import { Action } from '@ngrx/store';
import { Cart } from '../cart/cart.model';
import { Passenger } from '..';

export enum CartActionTypes {
  CreateCart = '[Cart] Create Cart',
  CartCreated = '[Cart] Created Cart',
  CartDeleted = '[Cart] Deleted Cart',
  EndRelatedCartSession = '[Cart] KickOther Cart',
  DeleteCart = '[Cart] Delete Cart',
  CartUpdated = '[Cart] Cart Updated',
  UpdateCartRequested = '[Cart] Update Cart Requested',
  BagSendRequested = '[Cart] Bag Send Requested',
  AddRegularBag = '[Cart] Add Regular Bag',
  RemoveRegularBag = '[Cart] Remove Regular Bag',
  PaxSelectedForSpecialItems = '[Cart] Pax Selected For Special Items',
  PaxSelectedChangedForSpecialItems = '[Cart] Pax Selected Changed',
  SessionEnd = '[Cart] Session End',
  RelatedCartRetrieved = '[Cart] Related Cart retrieved',
  EmptyCheckout = '[Cart] Empty Checkout',
  UpdateCartAndGoToNextStep = '[Cart] Update Cart And Go To Next Step',
}

export class RelatedCartRetrieved implements Action {
  readonly type = CartActionTypes.RelatedCartRetrieved;
  constructor(public payload: { cart: Cart }) {}
}

export class CartCreated implements Action {
  readonly type = CartActionTypes.CartCreated;
  constructor(public payload: { cart: Cart }) {}
}

export class CreateCart implements Action {
  readonly type = CartActionTypes.CreateCart;
}
export class EndRelatedCartSession implements Action {
  readonly type = CartActionTypes.EndRelatedCartSession;
}
export class CartDeleted implements Action {
  readonly type = CartActionTypes.CartDeleted;
}

export class DeleteCart implements Action {
  readonly type = CartActionTypes.DeleteCart;
}

export class CartUpdated implements Action {
  readonly type = CartActionTypes.CartUpdated;
  constructor(public payload: { cart: Cart }) {}
}

export class UpdateCartRequested implements Action {
  readonly type = CartActionTypes.UpdateCartRequested;
}

export class EmptyCheckout implements Action {
  readonly type = CartActionTypes.EmptyCheckout;
}

export class UpdateCartAndGoToNextStep implements Action {
  readonly type = CartActionTypes.UpdateCartAndGoToNextStep;
}

export class BagSendRequested implements Action {
  readonly type = CartActionTypes.BagSendRequested;
}

export class AddRegularBag implements Action {
  readonly type = CartActionTypes.AddRegularBag;
  constructor(
    public payload: {
      productId: string;
      passengerId: string;
      segmentId: string;
    }
  ) {}
}

export class RemoveRegularBag implements Action {
  readonly type = CartActionTypes.RemoveRegularBag;
  constructor(
    public payload: {
      productId: string;
      passengerId: string;
      segmentId: string;
    }
  ) {}
}

export class PaxSelectedForSpecialItems implements Action {
  readonly type = CartActionTypes.PaxSelectedForSpecialItems;
  constructor(public payload: { passengerId: string }) {}
}

export class PaxSelectedChangedForSpecialItems implements Action {
  readonly type = CartActionTypes.PaxSelectedChangedForSpecialItems;
  constructor(public payload: { passenger: Passenger }) {}
}

export class SessionEnd implements Action {
  readonly type = CartActionTypes.SessionEnd;
}

export type CartActions =
  | CreateCart
  | CartCreated
  | CartDeleted
  | DeleteCart
  | BagSendRequested
  | AddRegularBag
  | RemoveRegularBag
  | CartUpdated
  | UpdateCartRequested
  | UpdateCartAndGoToNextStep
  | PaxSelectedForSpecialItems
  | PaxSelectedChangedForSpecialItems
  | SessionEnd
  | EmptyCheckout
  | RelatedCartRetrieved;
