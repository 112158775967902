import { Action } from '@ngrx/store';
import { ItineraryView } from './itinerary-view.model';
import { ScrollableList } from './scrollable-list.model';

export enum ItineraryViewActionTypes {
  LoadItineraryView = '[ItineraryView] Load ItineraryView',
  LoadScrollableList = '[ItineraryView] Load ScrollableList',
  ScrollUpPassengerList = '[ItineraryView] ScrollUp PassengerList',
  ScrollDownPassengerList = '[ItineraryView] ScrollDown PassengerList',
  CheckInButtonClicked = '[ItineraryView] Check-in Button Clicked',
  ChangeSeatsButtonClicked = '[ItineraryView] Change Seats Button Clicked',
  CheckBagsButtonClicked = '[ItineraryView] Check bags Button Clicked',
}

export class LoadItineraryView implements Action {
  readonly type = ItineraryViewActionTypes.LoadItineraryView;
  constructor(public payload: { itineraryView: ItineraryView }) {}
}

export class LoadScrollableList implements Action {
  readonly type = ItineraryViewActionTypes.LoadScrollableList;
  constructor(public payload: { scrollableList: ScrollableList }) {}
}

export class ScrollUpPassengerList implements Action {
  readonly type = ItineraryViewActionTypes.ScrollUpPassengerList;
  constructor(public payload: { scrollableList: ScrollableList }) {}
}
export class ScrollDownPassengerList implements Action {
  readonly type = ItineraryViewActionTypes.ScrollDownPassengerList;
  constructor(public payload: { scrollableList: ScrollableList }) {}
}

export class CheckInButtonClicked implements Action {
  readonly type = ItineraryViewActionTypes.CheckInButtonClicked;
}

export class ChangeSeatsButtonClicked implements Action {
  readonly type = ItineraryViewActionTypes.ChangeSeatsButtonClicked;
}

export class CheckBagsButtonClicked implements Action {
  readonly type = ItineraryViewActionTypes.CheckBagsButtonClicked;
}

export type ItineraryViewActions =
  | LoadItineraryView
  | LoadScrollableList
  | ScrollUpPassengerList
  | ScrollDownPassengerList
  | CheckInButtonClicked
  | ChangeSeatsButtonClicked
  | CheckBagsButtonClicked;
