import { Directive, ElementRef, HostListener } from '@angular/core';
import { isAccessibilityMode } from '../services/emitters/session-event-emitters';

@Directive({
  selector: '[appFocusAccessibility]',
})
export class FocusAccessibilityDirective {
  private borderStyle = '2px solid orange';
  private noBorderStyle = 'none';

  constructor(private el: ElementRef) {}

  @HostListener('focus') onFocus() {
    this.focus();
  }

  @HostListener('blur') onBlur() {
    this.blur();
  }

  focus(): void {
    if (isAccessibilityMode.getValue()) {
      this.el.nativeElement.style.border = this.borderStyle;
    }
  }

  blur(): void {
    this.el.nativeElement.style.border = this.noBorderStyle;
  }
}
