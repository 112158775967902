import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Logging } from '../../../../services/logging/logging.service';
import { PaxEditConfirmed, PaxEditInfoUpdated, Keyboard, ResetContent, KeyboardState } from '../../../../state';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-hawaiian-miles-entry',
  templateUrl: './hawaiian-miles-entry.component.html',
  styleUrls: ['./hawaiian-miles-entry.component.scss', '../../locate-reservation/locate-reservation.component.scss'],
})
export class HawaiianMilesEntryComponent implements OnInit, OnDestroy {
  keyboard$: Observable<Keyboard>;
  paxEditMainRoute = AppRoutes.PAX_EDIT_MAIN;

  public code = '';
  public codeDone = false;
  public keyboardSubscription: Subscription;

  constructor(private store: Store<KeyboardState>, private logging: Logging) {}

  ngOnInit() {
    this.logging.infoUiEnterHawaiianMilesPageDisplayed(0);
    this.store.dispatch(new ResetContent());
    this.keyboard$ = this.store.pipe(select('keyboard'));
    this.keyboardSubscription = this.keyboard$.subscribe((value) => {
      if (value) {
        this.code = value.content;
        this.codeDone = value.content.length === 9;
      }
    });
  }

  addHawaiianMiles() {
    if (this.codeDone) {
      this.logging.infoUiEnterHawaiianMilesPageContinueSelected();
      this.store.dispatch(new PaxEditInfoUpdated({ hawaiianMiles: this.code }));
      this.store.dispatch(new PaxEditConfirmed({ addHawaiianMiles: true }));
    }
  }

  ngOnDestroy() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
  }
}
