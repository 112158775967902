import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-special-items-services',
  templateUrl: './special-items-services.component.html',
  styleUrls: ['./special-items-services.component.scss'],
})
export class SpecialItemsServicesComponent {
  constructor(private router: Router) {}

  continue() {
    this.router.navigate([AppRoutes.BAGS]);
  }
}
