import { SegmentFlight } from './segment-flight.model';
import { Passenger } from '../passenger.model';
import { seatNumberParse } from 'src/app/utils/helpers';

export enum BagTypeId {
  Regular = '0GO',
  SpecialItem = 'special_item',
}

export interface SegmentDetailBags {
  productId?: string;
  count: number;
  isPendingCheckout: boolean;
  receiptNumber: string;
}

export class SegmentDetail implements SegmentDetail {
  id: string;
  alertReasonCode: string | null;
  alertMessageCode: string | null;
  bags: SegmentDetailBags[] | null;
  canCheckIn: boolean | false;
  passengerName: {
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
  };
  flightDetails: SegmentFlight[] | null;
  isCheckedIn: boolean | false;
  selected: boolean | true;
  passengerId: string | null;
  isUpgradeEligible?: boolean | false;
  isDowngradeEligible?: boolean | false;
  segmentId: string | null;
  seatPreference?: string | null;

  static deserializeFromJson(json): SegmentDetail {
    const segmentDetail = Object.assign(new SegmentDetail(), json);
    segmentDetail.passenger = Passenger.deserializeFromJson(segmentDetail.passenger);

    const flights = json.flightDetails || [];
    segmentDetail.flightDetails = flights.map((flight) => SegmentFlight.deserializeFromJson(flight));

    return segmentDetail;
  }

  getNumBags(): number {
    let numBags = 0;

    this.bags.forEach((bag) => {
      if (typeof bag === 'number') {
        numBags += bag;
      }

      if (bag && typeof bag.count === 'number') {
        numBags += bag.count;
      }
    });

    return numBags;
  }

  getPaidBagsCount(): number {
    if (!this.bags) {
      return 0;
    }

    return this.bags
      .filter((bag) => !bag.isPendingCheckout)
      .map((bag) => bag.count)
      .reduce((x, y) => x + y, 0);
  }

  getNumOfRegularBags(): number {
    let numOfRegularBags = 0;
    this.bags.forEach((bag) => {
      if (bag && bag.productId === BagTypeId.Regular) {
        numOfRegularBags += bag.count;
      }
    });

    return numOfRegularBags;
  }

  getNumOfSpecialItems(): number {
    let numOfSpecialItems = 0;
    this.bags.forEach((bag) => {
      if (bag && bag.productId === BagTypeId.SpecialItem) {
        numOfSpecialItems += bag.count;
      }
    });

    return numOfSpecialItems;
  }

  addRegularBag() {
    this.bags.push({
      count: 1,
      productId: BagTypeId.Regular,
      isPendingCheckout: true,
      receiptNumber: '',
    });
  }

  addSpecialItem() {
    this.bags.push({
      count: 1,
      productId: BagTypeId.SpecialItem,
      isPendingCheckout: true,
      receiptNumber: '',
    });
  }

  removeRegularBag() {
    this.removedBag(BagTypeId.Regular);
  }

  removeSpecialItem() {
    this.removedBag(BagTypeId.SpecialItem);
  }

  removedBag(type: BagTypeId) {
    const indexOfBagToRemove = this.bags.findIndex((bag) => bag.productId === type && bag.isPendingCheckout);
    if (indexOfBagToRemove > -1) {
      const removedBag = this.bags.splice(indexOfBagToRemove, 1);
    }
  }

  getSeatsAsString(): string {
    const seatNumbers = [];
    this.flightDetails.map((flightDetail) => seatNumbers.push(seatNumberParse(flightDetail.seatNumber)));
    return seatNumbers.filter(Boolean).join(', ');
  }
}
