import { Injectable } from '@angular/core';
import { AccessibilityService } from '../../../services/accessibility/accessibility.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CussAccessibilityActionTypes } from './cuss-accessibility.actions';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';

@Injectable({ providedIn: 'root' })
export class CussAccessibilityEffects {
  constructor(private accessibilityService: AccessibilityService, private actions$: Actions, private router: Router) {}

  @Effect({ dispatch: false })
  headsetInserted$ = this.actions$.pipe(
    ofType(CussAccessibilityActionTypes.HeadsetInserted),
    map(() => {
      if (this.router.url === AppRoutes.SPLASH_SCREEN) {
        this.router.navigate([AppRoutes.MENU]);
      }
    })
  );

  @Effect({ dispatch: false })
  navNext$ = this.actions$.pipe(
    ofType(CussAccessibilityActionTypes.NavNext),
    map(() => {
      this.accessibilityService.navigateToNextElement();
    })
  );

  @Effect({ dispatch: false })
  navPrevious$ = this.actions$.pipe(
    ofType(CussAccessibilityActionTypes.NavPrevious),
    map(() => {
      this.accessibilityService.navigateToPreviousElement();
    })
  );

  @Effect({ dispatch: false })
  navEnter$ = this.actions$.pipe(
    ofType(CussAccessibilityActionTypes.NavEnter),
    map(() => {
      this.accessibilityService.selectCurrentElement();
    })
  );
}
