import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  DocumentPrintingDataPrinted,
  DocumentPrintingDataRequested,
  ErrorActionTypes,
  selectActiveSegment,
  selectCurrentTrip,
  SetTimeoutError,
} from '../../state';
import { BagTagDataPrinted, DocumentActionTypes } from './document.actions';
import { DocumentService } from '../../services/api/document/document.service';
import { HaCussService, onBPAdded } from '../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../services/logging/logging.service';
import {
  bagTagDataLoaded,
  currentlyPrinting,
  boardingPassDataLoaded,
  PRINTING_TYPES,
} from '../../services/emitters/session-event-emitters';
import { selectCart } from '../cart/cart.selector';
import { numberToPrint } from '../../services/ha-cuss/device.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Injectable()
export class DocumentEffects {
  private errorUrl = AppRoutes.ERROR_SCREEN;
  private bagTagData = [];
  private boardingPassData = [];

  constructor(
    private actions$: Actions,
    public router: Router,
    private store: Store<AppState>,
    public documentService: DocumentService,
    private haCussService: HaCussService,
    private zone: NgZone,
    private logging: Logging
  ) {}

  @Effect({ dispatch: false })
  getBoardingPass$ = this.actions$.pipe(
    ofType(DocumentActionTypes.DocumentPrintingDataRequested),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip))),
    switchMap(([_, trip]) => {
      return from(this.documentService.setBoardingPassData(trip));
    }),
    map((result) => {
      onBPAdded.emit(result.length);
      this.logging.infoSessionBoardingPassesToPrint(result.length);
      this.boardingPassData = result;
      boardingPassDataLoaded.next(true);
    }),
    catchError(() => of(new SetTimeoutError()))
  );

  @Effect({ dispatch: false })
  printBoardingPass$ = this.actions$.pipe(
    ofType(DocumentActionTypes.DocumentPrintingDataPrinted),
    map(() => {
      if (boardingPassDataLoaded.getValue() && this.boardingPassData && this.boardingPassData.length > 0) {
        this.printDocument(this.boardingPassData, this.haCussService, this.store, this.zone, this.router);
        currentlyPrinting.next(PRINTING_TYPES.BOARDING_PASS);
      } else {
        setTimeout(() => this.store.dispatch(new DocumentPrintingDataPrinted()), 1000);
      }
    }),
    catchError(() => of(new SetTimeoutError()))
  );

  @Effect({ dispatch: false })
  getCounterAssistTicket$ = this.actions$.pipe(
    ofType(ErrorActionTypes.SetReferToAgentError, ErrorActionTypes.SetTicketingIssue),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip))),
    switchMap(([_, trip]) => {
      return from(this.documentService.setCounterAssistTicketData(trip));
    }),
    map((result) => {
      this.logging.infoHardwareCounterAssistPrinted(result.stream);
      this.printDocument([result], this.haCussService, this.store, this.zone, this.router, true);
    }),
    catchError(() => of(new SetTimeoutError()))
  );

  @Effect({ dispatch: false })
  getPaymentReceipt$ = this.actions$.pipe(
    ofType(DocumentActionTypes.PaymentReceiptDataRequested),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip)), this.store.pipe(select(selectCart))),
    switchMap(([_, trip, cart]) => {
      return from(this.documentService.setReceiptTicketData(trip, cart));
    }),
    map((result) => {
      this.printDocument([result], this.haCussService, this.store, this.zone, this.router);
      currentlyPrinting.next(PRINTING_TYPES.RECEIPT);
    }),
    catchError(() => of(new SetTimeoutError()))
  );

  @Effect({ dispatch: false })
  getBagTags$ = this.actions$.pipe(
    ofType(DocumentActionTypes.BagTagDataRequested),
    withLatestFrom(this.store.pipe(select(selectCurrentTrip)), this.store.pipe(select(selectActiveSegment))),
    switchMap(([_, trip, segment]) => {
      return from(this.documentService.setBagTagData(trip, segment));
    }),
    map((result) => {
      this.bagTagData = result;
      bagTagDataLoaded.next(true);
      this.store.dispatch(new DocumentPrintingDataRequested());
    }),
    catchError(() => of(new SetTimeoutError()))
  );

  @Effect({ dispatch: false })
  printBagTags$ = this.actions$.pipe(
    ofType(DocumentActionTypes.BagTagDataPrinted),
    map(() => {
      if (bagTagDataLoaded.getValue()) {
        if (this.bagTagData && this.bagTagData.length > 0) {
          this.documentService.resetBagTagKeys();
          this.printBagTag(this.bagTagData, this.haCussService, this.store, this.zone, this.router);
          currentlyPrinting.next(PRINTING_TYPES.BAG_TAG);
        } else {
          currentlyPrinting.next(PRINTING_TYPES.BAG_TAG);
          numberToPrint.emit(0);
        }
      } else {
        setTimeout(() => this.store.dispatch(new BagTagDataPrinted()), 1000);
      }
    })
  );

  printDocument(results, haCussService, store, zone, router, resetPectab = false) {
    if (
      (haCussService.getAtbPrinterStatus()[1] !== true || haCussService.peripheralOnline !== false) &&
      results.length > 0
    ) {
      results.map((document) => {
        of(haCussService.loadPECTabs(this.pectabArray(document), false, resetPectab)),
          of(haCussService.callATBPrinter(this.extractStreams(document), resetPectab));
        this.boardingPassData = [];
        boardingPassDataLoaded.next(false);
      });
    } else {
      store.dispatch(new SetTimeoutError());
      zone.run(() => router.navigateByUrl(this.errorUrl));
    }
  }

  printBagTag(results, haCussService, store, zone, router) {
    if ((haCussService.getBagTagPrinterStatus()[1] !== true || haCussService.peripheralOnline) && results.length > 0) {
      results.map((boardingPassData) => {
        of(haCussService.loadBagTagPECTabs(this.pectabArray(boardingPassData), false)),
          of(haCussService.callBagTagPrinter(this.extractStreams(boardingPassData)));
        this.bagTagData = [];
        bagTagDataLoaded.next(false);
      });
    } else {
      store.dispatch(new SetTimeoutError());
      zone.run(() => router.navigateByUrl(this.errorUrl));
    }
  }

  pectabArray(pectabObj) {
    const ticketPecTab = [];
    Object.keys(pectabObj).forEach((key) => {
      if (!key.match(/^(stream|streamArray|bagTagStreams)$/)) {
        ticketPecTab.push(pectabObj[key]);
      }
    });

    return ticketPecTab;
  }

  extractStreams(entireDocument: Array<any>) {
    const streamArrayKey = 'streamArray';
    const streamKey = 'stream';
    const printStream = [];
    const dataStreamsToPrint = entireDocument[streamArrayKey];

    if (dataStreamsToPrint !== undefined) {
      dataStreamsToPrint.forEach((item: object) => {
        Object.entries(item).forEach(([key, value]) => {
          this.documentService.loadBagTagKey(key);
          printStream.push(value);
        });
      });
      onBPAdded.emit(printStream.length);
    } else {
      printStream.push(entireDocument[streamKey]);
    }

    return printStream;
  }
}
