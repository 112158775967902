import { Component, Input, OnInit } from '@angular/core';
import { AppState, Trip } from 'src/app/state';
import { Store } from '@ngrx/store';
import { Cart } from 'src/app/state/cart/cart.model';
import { CatalogIdTypes } from 'src/app/state/trip/passenger.catalog.model';

@Component({
  selector: 'app-pax-cart-table',
  templateUrl: './pax-cart-table.component.html',
  styleUrls: ['./pax-cart-table.component.scss', '../payment.component.scss'],
})
export class PaxCartTableComponent implements OnInit {
  @Input() public currency: string;

  @Input() public trip: Trip;

  @Input() public cart: Cart;

  public active = false;
  public scrollDown = true;
  public scrollUp = false;
  public steps;
  public progress = 0;
  public activeList;
  public itemsPerStep = 1;
  public itemsList = [];

  constructor(public store: Store<AppState>) {}

  ngOnInit() {
    if (this.cart && this.cart.passengers) {
      this.cart.passengers.forEach((pax) => {
        const baggagePrice = this.cart.items
          .filter((item: any) => item.associatedPassenger.passengerId === pax && item.catalogId === CatalogIdTypes.Bags)
          .map((item: any) => item.price)
          .reduce((a, b) => Number(a) + Number(b), 0);
        const baggageQuantity = this.cart.items
          .filter((item: any) => item.associatedPassenger.passengerId === pax && item.catalogId === CatalogIdTypes.Bags)
          .map((item: any) => item.quantity)
          .reduce((a, b) => a + b, 0);
        const seat = this.cart.items
          .filter(
            (item: any) => item.associatedPassenger.passengerId === pax && item.catalogId === CatalogIdTypes.Seats
          )
          .map((item: any) => item.price)
          .reduce((a, b) => Number(a) + Number(b), 0);

        this.itemsList.push({
          associatedPassenger: pax,
          name: this.trip.getPassengerNamesById(pax),
          baggage: {
            value: baggagePrice.toLocaleString('en', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            quantity: baggageQuantity,
          },
          seat: seat.toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        });
      });

      this.assembleList();
    }
  }

  assembleList() {
    this.activeList = [];
    const init = this.progress * this.itemsPerStep;
    this.activeList = this.itemsList.slice(init, this.itemsPerStep + init);

    this.steps = Math.ceil(this.itemsList.length / this.itemsPerStep);

    if (this.steps > 1) {
      this.active = true;
    }
  }

  onScrollUp() {
    if (this.progress <= 0) {
      return false;
    }
    this.scrollDown = true;
    this.progress--;
    this.assembleList();
    if (this.progress <= 0) {
      this.scrollUp = false;
    }
  }

  onScrollDown() {
    if (this.progress >= this.steps - 1) {
      return false;
    }
    this.progress++;
    this.scrollUp = true;
    this.assembleList();
    if (this.progress === this.steps - 1) {
      this.scrollDown = false;
    }
  }
}
