import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, PaxSelectedForEdit, SegmentDetail, selectSelectedPax } from '../../../../state';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-select-pax',
  templateUrl: './select-pax.component.html',
  styleUrls: ['./select-pax.component.scss'],
})
export class SelectPaxComponent implements OnInit {
  public selectedPax$: Observable<string[] | SegmentDetail[]>;
  itineraryRoute = AppRoutes.ITINERARY;

  constructor(private store: Store<AppState>, private router: Router) {
    this.selectedPax$ = this.store.pipe(select(selectSelectedPax));
  }

  ngOnInit() {}

  selectPassenger(passengerId: string) {
    this.store.dispatch(new PaxSelectedForEdit({ passengerId }));
  }

  goToItinerary() {
    this.router.navigate([AppRoutes.ITINERARY]);
  }
}
