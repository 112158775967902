import { Component, Input, OnInit } from '@angular/core';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-locate-reservation-tabs',
  templateUrl: './locate-reservation-tabs.component.html',
  styleUrls: ['./locate-reservation-tabs.component.scss'],
})
export class LocateReservationTabsComponent implements OnInit {
  public componentActiveTab: string;
  locateReservationByConfirmationRoute = AppRoutes.LOCATE_RESERVATION_CONFIRMATION_CODE;
  locateReservationByETicketRoute = AppRoutes.LOCATE_RESERVATION_E_TICKET;
  locateReservationByHAMilesRoute = AppRoutes.LOCATE_RESERVATION_HAWAIIAN_MILES;

  constructor() {}

  ngOnInit() {}

  @Input() set activeTab(value: string) {
    this.componentActiveTab = value;
  }
}
