import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TripService } from '../../services/api/trip/trip.service';
import { CatalogLoadRequested, TripChanged } from '../trip/trip.actions';
import {
  PaxEditActionTypes,
  PaxEditConfirmed,
  PaxEditInfoUpdated,
  PaxSelectedChanged,
  PaxSelectedForEdit,
} from './pax-edit.actions';
import { AppState } from '../index';
import { SetTimeoutGoToItineraryError, SetWrongMilesError } from '../error/error.action';
import { selectActiveSegment, selectCurrentTrip } from '../trip/trip.selectors';
import { selectPassengerEditInfo } from './pax-edit.selectors.js';
import { SetReferToAgentDontPrintError } from '../../state/error/error.action';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateCartRequested } from '../cart/cart.actions';
import { selectCart } from '../cart/cart.selector';
import { from } from 'rxjs';
import { AppRoutes } from 'src/app/app-routing.module';

@Injectable()
export class PaxEditEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private tripService: TripService
  ) {}

  multiplePaxSelected: string[];

  @Effect({ dispatch: false })
  startPassengerEditing$ = this.actions$.pipe(
    ofType(PaxEditActionTypes.PaxEditRequested),
    withLatestFrom(this.store.select(selectActiveSegment)),
    map(([_, segment]) => {
      if (segment.getPaxIdsSelectedForCheckin().length === 1) {
        const passengerId = segment.getPaxIdsSelectedForCheckin()[0];
        this.store.dispatch(new PaxSelectedForEdit({ passengerId }));
      } else {
        this.router.navigate([AppRoutes.PAX_EDIT_SELECT_PAX]);
      }
    })
  );

  @Effect({ dispatch: false })
  selectPassengerForEdit$ = this.actions$.pipe(
    ofType(PaxEditActionTypes.PaxSelectedForEdit),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([action, trip]) => {
      const { passengerId } = (action as PaxSelectedForEdit).payload;

      const passenger = trip.passengers.filter((pax) => pax.id === passengerId)[0];
      this.store.dispatch(new PaxSelectedChanged({ passenger }));
      this.router.navigate([AppRoutes.PAX_EDIT_MAIN]);
    })
  );

  @Effect({ dispatch: false })
  paxEditConfirmChanges$ = this.actions$.pipe(
    ofType(PaxEditActionTypes.PaxEditConfirmed),
    withLatestFrom(
      this.store.select(selectCurrentTrip),
      this.store.select(selectCart),
      this.store.select(selectPassengerEditInfo)
    ),
    switchMap(([action, trip, cart, paxInfo]) => {
      const { addHawaiianMiles } = (action as PaxEditConfirmed).payload;

      const paxUpdate = {
        knownTravelerNumber: paxInfo.ktn,
        redressNumber: paxInfo.redress,
        hawaiianMilesNumber: paxInfo.hawaiianMiles,
      };

      return from(this.tripService.updatePassenger(trip.id, paxInfo.passenger, paxUpdate)).pipe(
        map((response) => {
          if (response instanceof HttpErrorResponse) {
            if (addHawaiianMiles && (response.status === 500 || response.status === 400 || response.status === 401)) {
              this.store.dispatch(new SetWrongMilesError());
              this.store.dispatch(new PaxEditInfoUpdated({ hawaiianMiles: null }));
            } else {
              this.store.dispatch(new SetTimeoutGoToItineraryError());
            }

            return;
          }

          if (!response || !response.results || response.results.length === 0) {
            this.store.dispatch(new SetReferToAgentDontPrintError({}));
            return;
          }

          const newTrip = trip.clone();
          const newPax = newTrip.passengers.filter((pax) => pax.id === paxInfo.passenger.id)[0];
          newPax.knownTravelerNumber = paxInfo.ktn;
          newPax.redressNumber = paxInfo.redress;
          newPax.hawaiianMilesNumber = paxInfo.hawaiianMiles;

          this.store.dispatch(new TripChanged({ newTrip }));

          if (addHawaiianMiles) {
            this.store.dispatch(new CatalogLoadRequested());

            if (cart && cart.id) {
              this.store.dispatch(new UpdateCartRequested());
            }
          }

          this.router.navigate([AppRoutes.ITINERARY]);

          return true;
        })
      );
    })
  );
}
