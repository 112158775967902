import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
})
export class FooterNavComponent implements OnInit, AfterViewInit {
  public inputIsValid: false;
  @Input() goBackToRoute;
  @Input() buttons;
  @Input() center;
  @Input() alignRight;
  @Output() continueClick = new EventEmitter<null>();
  @Output() centerBtnClick = new EventEmitter<null>();
  @Output() messageEvent = new EventEmitter<Array<ElementRef>>();

  @Input() public smallerFontSize;

  @Input() set codeDone(value) {
    this.inputIsValid = value;
  }

  constructor(public router: Router, public location: Location, private el: ElementRef) {}

  ngOnInit() {}

  goBackClicked() {
    if (this.goBackToRoute) {
      return this.router.navigate(this.goBackToRoute);
    }
    return this.location.back();
  }

  continueClicked() {
    this.continueClick.emit();
  }

  centerBtnClicked() {
    this.centerBtnClick.emit();
  }

  ngAfterViewInit(): void {
    this.messageEvent.emit(this.el.nativeElement.querySelectorAll('[tabindex]'));
  }
}
