import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { Logging } from '../../../../services/logging/logging.service';
import { Authenticate, KeyboardState, Keyboard, ResetContent, PnrLocatorFlowUpdateState } from '../../../../state';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-by-hawaiian-miles',
  templateUrl: './by-hawaiian-miles.component.html',
  styleUrls: ['./by-hawaiian-miles.component.scss', './../locate-reservation.component.scss'],
})
export class ByHawaiianMilesComponent implements OnInit, OnDestroy {
  public keyboardSubscribe: Subscription;
  keyboard$: Observable<Keyboard>;
  menuRoute = AppRoutes.MENU;

  public code = '';
  public codeDone = false;

  constructor(private store: Store<KeyboardState>, public router: Router, private logging: Logging) {
    store.dispatch(new ResetContent());
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnInit() {
    this.logging.infoUiHawaiianMilesLookupPageDisplayed(0);
    this.keyboardSubscribe = this.keyboard$.subscribe((value) => {
      if (value) {
        this.code = value.content;
        this.codeDone = value.content.length >= 9;
      }
    });
  }

  validateHawaiianMiles() {
    if (!this.codeDone) {
      return false;
    }
    this.logging.infoUiHawaiianMilesLookupPageContinueSelected(0);
    this.code = this.code.toUpperCase();
    this.store.dispatch(new Authenticate({ hawaiianMilesNumber: this.code }));
    this.store.dispatch(
      new PnrLocatorFlowUpdateState({ hawaiianMilesNumber: this.code, lookupMethod: 'hawaiianMilesNumber' })
    );
  }

  ngOnDestroy() {
    if (this.keyboardSubscribe) {
      this.keyboardSubscribe.unsubscribe();
    }
  }
}
