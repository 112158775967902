import { Action } from '@ngrx/store';
import { Trip } from './trip.model';

export enum TripActionTypes {
  Authenticate = '[Trip] Authenticate',
  TripLoadRequested = '[Trip] Load a Trip',
  TripFirstLoadRequested = '[Trip] Load the first Trip',
  TripLoaded = '[Trip] A trip Loaded',
  TripChanged = '[Trip] Trip object was changed',
  NextSegmentSelected = '[Trip] Next trip segment selected',
  TripFlightStatusRequested = '[Trip] Trip flight status requested',
  TripSetSelectedPax = '[Trip] Trip set selected pax',
  CatalogLoadRequested = '[Trip] Catalog requested',
  CatalogsChanged = '[Trip] Catalogs changed',
  DisplayFlightInfoForKioskOrigin = '[Trip] Origin airport segment',
  UpdateTripDataRequested = '[Trip] Update Trip Data Requested',
}

export class Authenticate implements Action {
  readonly type = TripActionTypes.Authenticate;
  constructor(public payload: {}) {}
}

export class TripFirstLoadRequested implements Action {
  readonly type = TripActionTypes.TripFirstLoadRequested;
}

export class TripLoadRequested implements Action {
  readonly type = TripActionTypes.TripLoadRequested;
}

export class TripSetSelectedPax implements Action {
  readonly type = TripActionTypes.TripSetSelectedPax;
}

export class TripLoaded implements Action {
  readonly type = TripActionTypes.TripLoaded;
  constructor(public payload: { trip: Trip }) {}
}

export class TripChanged implements Action {
  readonly type = TripActionTypes.TripChanged;
  constructor(public payload: { newTrip?: Trip }) {}
}

export class NextSegmentSelected implements Action {
  readonly type = TripActionTypes.NextSegmentSelected;
}

export class DisplayFlightInfoForKioskOrigin implements Action {
  readonly type = TripActionTypes.DisplayFlightInfoForKioskOrigin;
}

export class TripFlightStatusRequested implements Action {
  readonly type = TripActionTypes.TripFlightStatusRequested;
}

export class CatalogLoadRequested implements Action {
  readonly type = TripActionTypes.CatalogLoadRequested;
}

export class CatalogsChanged implements Action {
  readonly type = TripActionTypes.CatalogsChanged;
  constructor(public payload: { seatsCatalogs: any[]; bagsCatalogs: any[] }) {}
}

export class UpdateTripDataRequested implements Action {
  readonly type = TripActionTypes.UpdateTripDataRequested;
}

export type TripActions =
  | Authenticate
  | TripLoadRequested
  | TripFirstLoadRequested
  | TripLoaded
  | TripChanged
  | NextSegmentSelected
  | TripFlightStatusRequested
  | TripSetSelectedPax
  | CatalogLoadRequested
  | CatalogsChanged
  | DisplayFlightInfoForKioskOrigin
  | UpdateTripDataRequested;
