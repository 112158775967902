import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  FlightStatus,
  PaxSelectedForEdit,
  SegmentPaxSelectedForCheckin,
  selectCurrentTrip,
  selectCurrentTripFlightStatus,
  Trip,
  Cart,
  selectCart,
} from '../../../state';
import { activeSession } from '../../../services/emitters/session-event-emitters';
import { Logging } from '../../../services/logging/logging.service';

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
})
export class ItineraryComponent implements OnInit {
  trip$: Observable<Trip>;
  flightStatus$: Observable<FlightStatus>;
  cart$: Observable<Cart>;

  constructor(private store: Store<AppState>, private logging: Logging) {}

  ngOnInit() {
    this.logging.infoUiItineraryPageDisplayed(0);
    this.trip$ = this.store.pipe(select(selectCurrentTrip));
    this.flightStatus$ = this.store.pipe(select(selectCurrentTripFlightStatus));
    this.cart$ = this.store.pipe(select(selectCart));
    activeSession.emit(true);
  }

  // Events
  onPassengerCheckInSelectionEv($event) {
    const { segmentDetail } = $event;
    this.store.dispatch(new SegmentPaxSelectedForCheckin({ segmentDetail }));
  }

  onEditPassenger($event) {
    const { passengerId } = $event;
    this.store.dispatch(new PaxSelectedForEdit({ passengerId }));
  }
}
