import { Component } from '@angular/core';

@Component({
  selector: 'app-special-items-details',
  templateUrl: './special-items-details.component.html',
  styleUrls: ['./special-items-details.component.scss'],
})
export class SpecialItemsDetailsComponent {
  public items = [
    {
      id: 1,
      icon: '/assets/icons/icon-car-seat.png',
      name: 'Carseat',
      count: 0,
      max: 10,
    },
    {
      id: 2,
      icon: '/assets/icons/icon-assistive-device.png',
      name: 'Wheelchair',
      count: 0,
      max: 10,
    },
    {
      id: 3,
      icon: '/assets/icons/icon-prepackaged-fruit.png',
      name: 'Pre-Packaged Airport Fruit',
      count: 0,
      max: 10,
    },
    {
      id: 4,
      icon: '/assets/icons/icon-default-bag-item.png',
      name: 'Baggage w/o Icon',
      count: 0,
      max: 10,
    },
    {
      id: 5,
      icon: '/assets/icons/icon-default-bag-item.png',
      name: 'Baggage w/o Icon',
      count: 0,
      max: 10,
    },
  ];

  constructor() {}

  decreaseItemCount(item) {
    if (item.count < 1) {
      return item.count;
    }
    item.count--;
    return item;
  }

  increaseItemCount(item) {
    if (item.count === item.max) {
      return item.count;
    }
    item.count++;
    return item;
  }

  isMinimumValueOfSpecialItems(item) {
    return item.count < 1;
  }

  isMaximumValueOfSpecialItems(item) {
    return item.count === item.max;
  }
}
