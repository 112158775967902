import { Component, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SetContent } from '../../../../state/keyboard/keyboard.action';
import { PnrLocatorFlow } from '../../../../state/pnr-locator-flow/pnr-locator-flow.model';
import { KeyboardState } from '../../../../state/keyboard/keyboard.reducer';
import { Keyboard } from '../../../../state/keyboard/keyboard.model';
import { Router } from '@angular/router';
import { PnrLocatorFlowUpdateState } from '../../../../state/pnr-locator-flow/pnr-locator-flow.action';
import { PnrLocatorFlowState } from '../../../../state/pnr-locator-flow/pnr-locator-flow.reducer';
import { Logging } from '../../../../services/logging/logging.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-by-flight-number',
  templateUrl: './by-flight-number.component.html',
  styleUrls: ['./by-flight-number.component.scss', './../locate-reservation.component.scss'],
})
export class ByFlightNumberComponent implements OnInit, OnDestroy {
  keyboard$: Observable<Keyboard>;
  pnrLocatorFlow$: Observable<PnrLocatorFlow>;
  menuRoute = AppRoutes.MENU;

  public pnrLocatorFlowSubscriber;
  public keyboardSubscriber;
  public code = '';
  public codeDone = false;

  constructor(
    private store: Store<KeyboardState>,
    private router: Router,
    private logging: Logging,
    public pnrLocatorFlowStore: Store<PnrLocatorFlowState>
  ) {
    this.pnrLocatorFlow$ = pnrLocatorFlowStore.pipe(select('pnrLocatorFlow'));
    this.keyboard$ = store.pipe(select('keyboard'));
  }

  ngOnDestroy() {
    if (this.pnrLocatorFlowSubscriber) {
      this.pnrLocatorFlowSubscriber.unsubscribe();
    }
    if (this.keyboardSubscriber) {
      this.keyboardSubscriber.unsubscribe();
    }
  }

  ngOnInit() {
    this.logging.infoUiFlightNumberLookupPageDisplayed(0);

    this.pnrLocatorFlowSubscriber = this.pnrLocatorFlow$.pipe(take(1)).subscribe((value) => {
      const flightNumber = value.flightNumber ? value.flightNumber : '';
      this.store.dispatch(new SetContent(flightNumber));
      this.enableKeyboard();
    });
  }

  enableKeyboard() {
    this.keyboardSubscriber = this.keyboard$.subscribe((value) => {
      if (value.content === undefined) {
        return;
      }

      this.code = value.content;
      this.codeDone = false;
      if (value.content.length >= 1) {
        this.codeDone = true;
      }
    });
  }

  validateNumber() {
    this.logging.infoUiFlightNumberLookupPageContinueSelected(0);

    if (!this.codeDone) {
      return false;
    }

    this.store.dispatch(
      new PnrLocatorFlowUpdateState({
        flightNumber: this.code,
        lookupMethod: 'flightNumberLastName',
      })
    );
    this.router.navigate([AppRoutes.LOCATE_RESERVATION_LAST_NAME]);
  }
}
