import { Component, OnDestroy, OnInit, InjectionToken, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AppState, ErrorState, LanguageState, StoreConfigService } from './state';
import { Logging } from './services/logging/logging.service';

import * as devToolsFix from './utils/devtools-mapfix';
import { VersionService } from './services/api/config/version.service';
import { environment } from '../environments/environment';
import { HaCussService } from './services/ha-cuss/ha-cuss.service';

import { utils } from './utils/helpers';
import { AppRoutes } from './app-routing.module';

devToolsFix.fixDevToolsMapPrototype();

export const LOCATION_TOKEN = new InjectionToken<Location>('Window Location');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: LOCATION_TOKEN, useValue: window.location }],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'hawaiianairlines-web';
  landingPage = AppRoutes.MENU;
  errorScreen = AppRoutes.ERROR_SCREEN;
  language$: Observable<LanguageState>;
  error$: Observable<ErrorState>;
  errorSubscription: Subscription;
  languageSubscription: Subscription;
  selectedLanguage: string;
  private readonly updateInterval;
  private versionCheckTimeout = 300000;

  constructor(
    translate: TranslateService,
    private router: Router,
    private store: Store<AppState>,
    private logging: Logging,
    private storeConfig: StoreConfigService,
    @Inject(LOCATION_TOKEN) private location: Location,
    private versionService: VersionService,
    private haCussService: HaCussService
  ) {
    // Save store observable into a static BehaviourSubject that could be used in other files of the app without
    // injecting the store into a injectable class.
    this.storeConfig.setStoreSubject(this.store);
    translate.setDefaultLang('en');

    this.language$ = this.store.pipe(select('language'));

    this.languageSubscription = this.language$.subscribe((lang) => {
      translate.use(lang.languageId.withDefault('en'));
      this.selectedLanguage = lang.languageId.withDefault('en');
    });

    this.error$ = this.store.pipe(select('error')).pipe(skip(1));

    this.errorSubscription = this.error$.subscribe((error) => {
      const destination = error.error.map(() => this.errorScreen).withDefault(this.landingPage);

      this.router.navigate([destination]);
    });

    this.logging.infoUiAppInitialized();

    const urlParams = new URLSearchParams(this.location.search);

    if (urlParams.has('serviceHours')) {
      window.sessionStorage.setItem('serviceHours', urlParams.get('serviceHours'));
    }

    // If we are on the splash-screen for more than a minute we will check for a new version
    this.updateInterval = setInterval(() => this.checkForSession(), this.versionCheckTimeout);
    this.haCussService.setAvailable();
  }

  ngOnInit() {
    if (environment.production) {
      if (utils.navigator().serviceWorker) {
        utils.navigator().serviceWorker.register('/sw.js');
      }
    }
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
    this.storeConfig.clearStoreSubject();
    clearInterval(this.updateInterval);
  }

  async checkForSession() {
    if (['/', AppRoutes.SPLASH_SCREEN].includes(this.router.url) && environment.name !== 'local') {
      this.getAppInformation();
    }
  }

  private getAppInformation() {
    this.versionService.checkForNewApp();
  }
}

export function startSession(logging) {
  const kioskId = Math.random()
    .toString(36)
    .substr(2, 9);

  const dateString = new Date().toISOString();

  const newID = `${kioskId}-${dateString}`;

  if (window.sessionStorage.getItem('SESSIONID')) {
    logging.infoUiSessionEnded();
    window.sessionStorage.removeItem('SESSIONID');
  }

  window.sessionStorage.setItem('SESSIONID', newID);
  logging.infoUiSessionStarted();
}
