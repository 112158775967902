import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { BagTagPrinted, BagTagPrinterActionTypes } from './bagtag-printer.actions';
import { map } from 'rxjs/operators';
import { DocumentService } from '../../../services/api/document/document.service';
import { bagTagsPrinted } from '../../../services/emitters/session-event-emitters';

@Injectable({ providedIn: 'root' })
export class BagTagPrinterEffects {
  constructor(private actions$: Actions, private documentService: DocumentService) {}
  @Effect({ dispatch: false })
  bagTagPrinted$ = this.actions$.pipe(
    ofType(BagTagPrinterActionTypes.BagTagPrinted),
    map((action: BagTagPrinted) => action.type),
    map(async () => {
      bagTagsPrinted.next(true);
      const result = await this.documentService.reportBagTagPrinted(this.documentService.getNextBagTagKey());
      switch (result.status) {
        case 500: {
          // We need to handle this error need requirements.
          break;
        }
        case 400: {
          // We need to handle or retry this error, need requirements.
        }
      }
    })
  );
}
