import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectTripState, TripLoadRequested } from 'src/app/state';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { EndRelatedCartSession } from 'src/app/state/cart/cart.actions';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-checkin-in-progress',
  templateUrl: './checkin-in-progress.component.html',
  styleUrls: ['./checkin-in-progress.component.scss'],
})
export class CheckInInProgressComponent implements OnInit, OnDestroy {
  public showModal = false;
  public cartSubscription: Subscription;
  public passengers: { paxId: string; paxName: string }[];

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.cartSubscription = this.store
      .select((state) => state.carts.cart)
      .subscribe((cart) => {
        this.showModal = false;
        if (cart.checkConcurrentCart()) {
          const passengersIds = cart.relatedCarts[0].associatedPassengers.map((x) => x.passengerId);
          this.store
            .select(selectTripState)
            .pipe(first())
            .subscribe((result) => {
              this.passengers = result.trip.passengers
                .filter((pax) => passengersIds.find((paxId) => paxId === pax.id))
                .map((pax) => {
                  return {
                    paxId: pax.id,
                    paxName: `${pax.passengerName.firstName} ${pax.passengerName.lastName}`,
                  };
                });
              this.showModal = true;
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.cartSubscription.unsubscribe();
  }

  cancel() {
    this.showModal = false;
    this.store.dispatch(new TripLoadRequested());
    this.router.navigate([AppRoutes.ITINERARY]);
  }

  continue() {
    this.store.dispatch(new EndRelatedCartSession());
    this.showModal = false;
  }
}
