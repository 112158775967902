import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-back-button',
  templateUrl: './go-back-button.component.html',
  styleUrls: ['./go-back-button.component.scss'],
})
export class GoBackButtonComponent {
  public internalRoute: any;

  @Input() set route(value) {
    this.internalRoute = value;
  }

  constructor(public router: Router, public location: Location) {}

  goToRoute() {
    if (this.internalRoute) {
      this.router.navigate(this.internalRoute);
    } else {
      this.location.back();
    }
  }
}
