import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, PaxEditConfirmed, selectPassengerEditInfo } from '../../../../state';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Logging } from '../../../../services/logging/logging.service';
import { AccessibilityService } from '../../../../services/accessibility/accessibility.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-ktn-redress-inputs',
  templateUrl: './pax-edit-main.component.html',
  styleUrls: ['./pax-edit-main.component.scss'],
})
export class PaxEditMainComponent implements OnInit, AfterViewInit {
  paxEditInfo$: Observable<any>;
  itineraryRoute = AppRoutes.ITINERARY;

  public showModal = false;
  language: string;
  public hmPresent = false;
  public ktnPresent = false;
  public redressPresent = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private logging: Logging,
    private accessibilityService: AccessibilityService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.paxEditInfo$ = this.store.pipe(select(selectPassengerEditInfo));

    this.paxEditInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((info) => {
      this.hmPresent = info.ktn;
      this.ktnPresent = info.ktn;
      this.redressPresent = info.redress;

      this.logging.infoUiHMKTNRedressPageDisplayed(this.hmPresent, this.ktnPresent, this.redressPresent, 0);
    });
  }

  ngAfterViewInit(): void {
    const elements = this.getElements();
    this.accessibilityService.setChildElementsToRead(elements);
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }

  updatePassengerInfo() {
    this.logging.infoUiHMKTNRedressPageContinueSelected(0);
    this.store.dispatch(new PaxEditConfirmed({}));
  }

  redressInputClicked() {
    this.router.navigate(['pax-edit/redress']);
    this.logging.infoUiKTNRedressPageRedressSelected();
  }

  ktnInputClicked() {
    this.router.navigate(['/pax-edit/ktn']);
    this.logging.infoUiKTNRedressPageKTNSelected();
  }

  hmInputClicked() {
    this.router.navigate(['/pax-edit/hawaiian-miles']);
    this.logging.infoUiKTNRedressPageHawaiianMilesSelected();
  }
}
