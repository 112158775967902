import { Action } from '@ngrx/store';
import { SegmentDetail } from './segment-detail.model';

export enum SegmentActionTypes {
  SegmentPaxSelectedForCheckin = '[Segment] Passenger selected for checkin',
  CheckinPax = '[Segment] CheckIn Passengers',
  CheckinMade = '[Segment] CheckIn Made',
}

export class SegmentPaxSelectedForCheckin implements Action {
  readonly type = SegmentActionTypes.SegmentPaxSelectedForCheckin;
  constructor(public payload: { segmentDetail: SegmentDetail }) {}
}

export class CheckinPax implements Action {
  readonly type = SegmentActionTypes.CheckinPax;
}

export class CheckinMade implements Action {
  readonly type = SegmentActionTypes.CheckinMade;
}

export type SegmentActions = SegmentPaxSelectedForCheckin | CheckinPax | CheckinMade;
