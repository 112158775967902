import { Injectable } from '@angular/core';
import { API } from '../api.service';
import { Trip } from 'src/app/state';
import { Cart } from 'src/app/state/cart/cart.model';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private api: API) {}

  createCart(trip: Trip) {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const url = `${this.api.getUrlToCart()}`;
    this.api.updateCorrelationId(trip.id);

    this.api.appendQueryParams(this.api.apiQParams.trips.confirmationCode.key, trip.confirmationCode);
    this.api.appendQueryParams(this.api.apiQParams.trips.segmentId.key, trip.activeSegment.id);
    this.api.appendQueryParams(this.api.apiQParams.trips.passengerIds.key, trip.getSelectedPassengerIds().toString());

    return this.api.doPostCartCall(url, {});
  }

  deleteCart(trip: Trip, cart: Cart) {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const url = `${this.api.getUrlToCart()}/${cart.id}`;
    this.api.updateCorrelationId(trip.id);

    return this.api.doDeleteCall(url, {});
  }

  updateCart(cart: Cart, tripId: string) {
    return new Promise<any>((res, rej) => {
      if (!cart || !cart.id) {
        return rej('Cart cant be null');
      }

      if (this.api.getQueryParamsCount().length > 0) {
        this.api.clearQueryParams();
      }

      const url = `${this.api.getUrlToCart()}/${cart.id}/items`;
      this.api.updateCorrelationId(tripId, 'PUT');

      cart.items.forEach((item) => {
        delete item.price;
      });

      this.api
        .doPutCall(url, cart.items)
        .then(res)
        .catch(rej);
    });
  }

  checkoutCart(checkout, cartId: string, tripId: string, kioskId: string) {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const url = `${this.api.getUrlToCart()}/${cartId}/checkout`;
    this.api.updateCorrelationId(tripId);
    this.api.updateDeviceIdHeader(kioskId);

    return this.api.doPostCall(url, checkout);
  }

  getCart(cartId: string, tripId: string) {
    if (this.api.getQueryParamsCount().length > 0) {
      this.api.clearQueryParams();
    }

    const url = `${this.api.getUrlToCart()}/${cartId}`;
    this.api.updateCorrelationId(tripId);

    return this.api.doGetCall(url);
  }
}
