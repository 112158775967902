import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { AccessibilityService } from 'src/app/services/accessibility/accessibility.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-by-barcode',
  templateUrl: './by-barcode.component.html',
  styleUrls: ['./by-barcode.component.scss'],
})
export class ByBarcodeComponent implements OnInit, AfterViewInit {
  menuRoute = AppRoutes.MENU;

  constructor(private accessibilityService: AccessibilityService, private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const elements = this.getElements();
    this.accessibilityService.setChildElementsToRead(elements);
  }

  getElements() {
    return this.el.nativeElement.querySelectorAll('[tabindex]');
  }
}
