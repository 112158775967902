import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppState, selectCurrentTrip } from '../../../../state';

@Component({
  selector: 'app-hazmat-agriculture-warning',
  templateUrl: './hazmat-agriculture-warning.component.html',
  styleUrls: ['./hazmat-agriculture-warning.component.scss'],
})
export class HazmatAgricultureWarningComponent implements OnInit, OnDestroy {
  showAgricultureText = false;
  trip$ = this.store.pipe(select(selectCurrentTrip));
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.trip$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe((value) => {
      if (value.activeSegment) {
        this.showAgricultureText = value.activeSegment.marketType === 'North America' ? true : false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
