import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CartService } from 'src/app/services/api/cart/cart.service';
import { TripService } from '../../../services/api/trip/trip.service';
import { Cart } from '../../cart/cart.model';
import {
  AppState,
  CartActionTypes,
  CartCreated,
  CreateCart,
  ItineraryViewActionTypes,
  RelatedCartRetrieved,
  SegmentPaxSelectedForCheckin,
  selectCart,
  selectCurrentTrip,
  SetTimeoutError,
  TripChanged,
} from '../../index';
import { CheckinPax, SegmentActionTypes } from './segment.actions';
import { Logging } from '../../../services/logging/logging.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Injectable()
export class SegmentEffects {
  constructor(
    private actions$: Actions,
    public router: Router,
    private store: Store<AppState>,
    public tripService: TripService,
    public cartService: CartService,
    private logging: Logging
  ) {}

  @Effect()
  selectPaxForCheckin$ = this.actions$.pipe(
    ofType(SegmentActionTypes.SegmentPaxSelectedForCheckin),
    map((action: SegmentPaxSelectedForCheckin) => action.payload.segmentDetail),
    map((segmentDetail) => {
      segmentDetail.selected = !segmentDetail.selected;
      this.logSelectPassengerEvent(segmentDetail.selected);
      return new TripChanged({});
    })
  );

  @Effect({ dispatch: false })
  checkinPax$ = this.actions$.pipe(
    ofType(SegmentActionTypes.CheckinPax),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([_, trip]) => trip),
    switchMap((trip) => from(this.tripService.checkIn(trip)))
  );

  @Effect({ dispatch: false })
  endRelatedCartSession$ = this.actions$.pipe(
    ofType(CartActionTypes.EndRelatedCartSession),
    withLatestFrom(this.store.select(selectCart), this.store.select(selectCurrentTrip)),
    switchMap(([_, cart, trip]) => {
      if (cart && cart.relatedCarts && cart.relatedCarts.length > 0 && cart.relatedCarts[0].id) {
        cart.id = cart.relatedCarts[0].id;
        return from(this.cartService.deleteCart(trip, cart));
      } else {
        this.store.dispatch(new SetTimeoutError());
      }
    }),
    map(() => {
      this.store.dispatch(new CreateCart());
    }),
    catchError(() => {
      this.store.dispatch(new SetTimeoutError());
      return of(false);
    })
  );

  @Effect({ dispatch: false })
  createCart$ = this.actions$.pipe(
    ofType(CartActionTypes.CreateCart),
    withLatestFrom(this.store.select(selectCurrentTrip)),
    map(([_, trip]) => trip),
    switchMap((trip) =>
      from(this.cartService.createCart(trip)).pipe(
        map((cartsResult) => {
          if (cartsResult.results && cartsResult.results.length > 0 && cartsResult.results[0].relatedCarts) {
            const cart = Cart.deserializeFromJson(cartsResult.results[0]);
            if (cart.checkConcurrentCart()) {
              this.store.dispatch(new CartCreated({ cart }));
            } else if (cart.checkConcurrentRelatedCart()) {
              this.store.dispatch(new RelatedCartRetrieved({ cart }));
            }
          } else if (cartsResult.results && cartsResult.results[0] && !cartsResult.results[0].relatedCarts) {
            const cart = Cart.deserializeFromJson(cartsResult.results[0]);
            this.store.dispatch(new CartCreated({ cart }));
          }
          this.router.navigate([AppRoutes.BAGS]);
        })
      )
    )
  );

  @Effect({ dispatch: false })
  checkBagsButtonClicked$ = this.actions$.pipe(
    ofType(ItineraryViewActionTypes.CheckBagsButtonClicked),
    withLatestFrom(this.store.select(selectCart)),
    map(([_, cart]) => {
      this.store.dispatch(new CheckinPax());
      if (!cart.id) {
        this.store.dispatch(new CreateCart());
      } else {
        this.router.navigate([AppRoutes.BAGS]);
      }
    })
  );

  @Effect({ dispatch: false })
  changeSeatsButtonClicked$ = this.actions$.pipe(
    ofType(ItineraryViewActionTypes.ChangeSeatsButtonClicked),
    map(() => {
      this.store.dispatch(new CheckinPax());
      this.store.dispatch(new CreateCart());
    })
  );

  @Effect({ dispatch: false })
  checkinbuttonclicked$ = this.actions$.pipe(
    ofType(ItineraryViewActionTypes.CheckInButtonClicked),
    map(() => {
      this.store.dispatch(new CheckinPax());
      this.router.navigate([AppRoutes.HAZMAT_PROHIBITED]);
    })
  );

  logSelectPassengerEvent(selected: boolean) {
    if (selected) {
      this.logging.infoUiItineraryPagePassengerSelected(0);
    } else {
      this.logging.infoUiItineraryPagePassengerUnselected(0);
    }
  }
}
