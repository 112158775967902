import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { StartOverComponent } from '../../../../shared/start-over/start-over.component';
import { AccessibilityService } from '../../../../services/accessibility/accessibility.service';

@Component({
  selector: 'app-locate-reservation-title',
  templateUrl: './locate-reservation-title.component.html',
  styleUrls: ['./locate-reservation-title.component.scss'],
})
export class LocateReservationTitleComponent implements OnInit, AfterViewInit {
  public size = 'app-text--xlarge-size';
  @Input() public title: string;
  @Input() public paxFirstName: string;
  @Input() public paxLastName: string;
  @Input() set small(value) {
    if (value) {
      this.size = 'app-text--small-size';
    }
  }
  @Input() set smaller(value) {
    if (value) {
      this.size = 'app-text--xsmall-size';
    }
  }

  @ViewChild(StartOverComponent, { static: false }) startOverElement: StartOverComponent;
  constructor(private accessibilityService: AccessibilityService, private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    const elements = [this.startOverElement.buttonElement.nativeElement];
    this.accessibilityService.setParentElementsToRead(elements);
  }
}
