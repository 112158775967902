import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { Passenger, PaxEditInfoUpdated, selectCurrentPassenger, ResetContent, KeyboardState } from '../../../../state';
import { Logging } from '../../../../services/logging/logging.service';

@Component({
  selector: 'app-redress-entry',
  templateUrl: './redress-entry.component.html',
  styleUrls: ['./redress-entry.component.scss'],
})
export class RedressEntryComponent implements OnInit, OnDestroy {
  passenger$: Observable<Passenger>;

  public showModal = false;
  public keyboardSubscription: Subscription;
  public redress = null;

  constructor(private store: Store<KeyboardState>, private location: Location, private logging: Logging) {}

  ngOnInit() {
    this.logging.infoUiEnterRedressPageDisplayed(0);
    this.passenger$ = this.store.pipe(select(selectCurrentPassenger));
    this.store.dispatch(new ResetContent());
    this.validateKeyboardEntry();
  }

  ngOnDestroy() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
  }

  validateKeyboardEntry() {
    const keyboardSelector = this.store.pipe(select('keyboard'));
    this.keyboardSubscription = keyboardSelector.subscribe((value) => {
      this.redress = value.content;
    });
  }

  onContinueClick() {
    this.logging.infoUiEnterRedressPageContinueSelected(0);
    this.store.dispatch(new PaxEditInfoUpdated({ redress: this.redress }));
    this.location.back();
  }
}
