import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  AppState,
  DisplayFlightInfoForKioskOrigin,
  FlightStatus,
  selectCurrentTripFlightStatus,
} from '../../../../state';
import { HaCussService } from '../../../../services/ha-cuss/ha-cuss.service';
import { Logging } from '../../../../services/logging/logging.service';

@Component({
  selector: 'app-non-printed-bags',
  templateUrl: './non-printed-bags.component.html',
  styleUrls: ['./non-printed-bags.component.scss'],
})
export class NonPrintedBagsConfirmationComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  flightStatus$: Observable<FlightStatus>;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private haCussService: HaCussService,
    protected logging: Logging
  ) {
    this.flightStatus$ = this.store.pipe(select(selectCurrentTripFlightStatus));
  }

  ngOnInit() {
    this.logging.infoUiCheckinComplete();
    this.store.dispatch(new DisplayFlightInfoForKioskOrigin());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.haCussService.enableLookupDevices();
    this.haCussService.setAvailable();
  }
}
