import { Flight } from '../flight/flight.model';

export class SegmentFlight {
  id: string;
  boardingZone: string | null;
  flightId: string | Flight | null;
  isPassportRequired: boolean | false;
  tsaPreCheck: boolean | false;
  lounge: string | null;
  seatCategory: string | null;
  seatNumber: string | null;
  boardingPassData: string | null;
  ticketNumber: string | null;
  boardingPassPassengerData: string | null;
  lapInfantBoardingPassData: string | null;
  segmentId: string | null;

  static deserializeFromJson(json): SegmentFlight {
    return Object.assign(new SegmentFlight(), json);
  }
}
