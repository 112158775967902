import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SegmentDetailAndPax, Trip } from 'src/app/state';
import { Logging } from '../../../../services/logging/logging.service';

@Component({
  selector: 'app-itinerary-passenger-details',
  templateUrl: './itinerary-passenger-details.component.html',
  styleUrls: ['./itinerary-passenger-details.component.scss'],
})
export class ItineraryPassengerDetailsComponent {
  public currentTrip;
  public paxsAndSegmentDetails;
  public seatPreference;
  public isNonRevSpaceAvailable: boolean;
  public specialItems;

  constructor(private logging: Logging) {}

  @Input() set trip(trip: Trip) {
    this.currentTrip = trip;
    this.getPax();
  }

  @Output() passengerCheckInSelection = new EventEmitter<any>();
  @Output() editPassengerClick = new EventEmitter<any>();

  getPax() {
    if (this.currentTrip) {
      this.paxsAndSegmentDetails = this.currentTrip.getSegmentDetailsAndPax();
      this.isNonRevSpaceAvailable = this.currentTrip.isNonRevSpaceAvailable();
    }
  }

  doesThisPassengerHasAnyInfo(segmentDetailAndPax: SegmentDetailAndPax) {
    if (
      segmentDetailAndPax.passenger.hawaiianMilesNumber ||
      segmentDetailAndPax.passenger.redressNumber ||
      segmentDetailAndPax.passenger.knownTravelerNumber
    ) {
      return true;
    }
    return false;
  }

  onChangePaxCheckedInSelectionEv(segmentDetail) {
    // at least one pax should be selected
    const numOfPaxsSelectedForCheckin = this.currentTrip.activeSegment.getNumOfPaxsSelectedForCheckin();
    if (segmentDetail.selected && numOfPaxsSelectedForCheckin === 1) {
      return;
    }

    this.passengerCheckInSelection.emit({ segmentDetail });
  }

  editPassenger(passengerId: string) {
    this.logging.infoUiItineraryPageAddHMKTNRedressSelected(0);
    this.editPassengerClick.emit({ passengerId });
  }
}
