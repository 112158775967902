import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState, selectCurrentTrip, Trip } from '../../../../state';

@Component({
  selector: 'app-confirmation-agriculture-warning',
  templateUrl: './confirmation-agriculture-warning.component.html',
  styleUrls: ['./confirmation-agriculture-warning.component.scss'],
})
export class ConfirmationAgricultureWarningComponent implements OnInit, OnDestroy {
  public showAgricultureText = false;
  trip$: Observable<Trip>;
  public trip;

  constructor(private store: Store<AppState>) {
    this.trip$ = this.store.pipe(select(selectCurrentTrip));
  }

  ngOnInit() {
    this.trip = this.trip$.pipe(take(1)).subscribe((value) => {
      if (value.activeSegment) {
        this.showAgricultureText = value.activeSegment.marketType === 'North America' ? true : false;
      }
    });
  }

  ngOnDestroy() {
    if (this.trip) {
      this.trip.unsubscribe();
    }
  }
}
