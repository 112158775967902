import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '../../services/ui/loading.service';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isError = false;
  public loadingSubscriber;
  constructor(public loadingService: LoadingService, public router: Router) {}

  ngOnDestroy() {
    if (this.loadingSubscriber) {
      this.loadingSubscriber.unsubscribe();
    }
  }

  ngOnInit() {
    this.loadingSubscriber = this.loadingService.isLoading.subscribe((x) => {
      this.isLoading = x;
      this.isError = /error/.test(this.router.url);
    });
    if (this.router.url === AppRoutes.LOADING) {
      this.isLoading = true;
    }
  }
}
