import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { AppState, NextSegmentSelected } from '../../../state';
import { Logging } from '../../../services/logging/logging.service';
import { AppRoutes } from 'src/app/app-routing.module';

@Component({
  selector: 'app-more-segments-checkin',
  templateUrl: './more-segments-checkin.component.html',
  styleUrls: ['./more-segments-checkin.component.scss'],
})
export class MoreSegmentsCheckinComponent implements OnInit {
  constructor(private store: Store<AppState>, private router: Router, private logging: Logging) {}

  ngOnInit() {
    this.logging.infoUiNextSegmentCheckinDisplayed(0);
  }

  onExitSegmentEv() {
    this.logging.infoUiNextSegmentCheckInNoSelected(0);
    this.router.navigate([AppRoutes.CONFIRMATION]);
  }

  onShowNextSegmentEv() {
    this.logging.infoUiNextSegmentCheckInYesSelected(0);
    this.store.dispatch(new NextSegmentSelected());
  }
}
