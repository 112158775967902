import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { SelectLanguage } from '../../../state/language/language.action';
import { LanguageState } from '../../../state';
import { Logging } from '../../../services/logging/logging.service';
import { DeviceService } from '../../../services/ha-cuss/device.service';
import { locatedPnr } from '../../../services/emitters/session-event-emitters';
import { HaCussService } from '../../../services/ha-cuss/ha-cuss.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<LanguageState>,
    private logging: Logging,
    private location: Location,
    public deviceService: DeviceService,
    public haCussService: HaCussService
  ) {}

  ngOnInit() {
    this.logging.infoUiChangeLanguageDisplayed(0);
    this.deviceService.disableLookupDevices();
  }

  ngOnDestroy(): void {
    locatedPnr
      .subscribe((value) => {
        if (value) {
          return;
        } else {
          this.haCussService.enableLookupDevices();
        }
      })
      .unsubscribe();
  }

  selectLang(lang: string) {
    this.logging.infoUiChangeLanguageSelected(lang, 0);
    this.store.dispatch(new SelectLanguage(lang));
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  onStartOverClick() {
    this.logging.infoUiChangeLangPageStartOver(0);
  }

  onGoBackClick() {
    this.logging.infoUiChangeLangPageGoBack(0);
  }
}
