import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { isPassportInReaderTooLong } from '../../services/emitters/session-event-emitters';

@Component({
  selector: 'app-remove-passport',
  templateUrl: './remove-passport.component.html',
  styleUrls: ['./remove-passport.component.scss'],
})
export class RemovePassportComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];

  public showPopup = false;

  ngOnInit() {
    this.subscriptions.push(
      isPassportInReaderTooLong.subscribe((value) => {
        this.showPopup = value;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
