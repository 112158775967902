import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState, SessionEnd, SetNotFoundError } from '../index';
import { SetReferToAgentDontPrintError, SetTimeoutError } from './error.action';
import { getFirstParameterThatMatches } from '../../utils/helpers';
import { Logging } from '../../services/logging/logging.service';
import { LoggingInterceptor } from '../../services/logging/logging-interceptor';
import { ToggleState } from '..';
import { selectPnrLocatorFlow } from '..';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/app-routing.module';

const LOCATE_METHODS = ['confirmationCode', 'hawaiianMilesNumber', 'ticketNumber'];

@Injectable()
export class ErrorInterceptor extends LoggingInterceptor implements HttpInterceptor {
  public lookupMethod: string;

  constructor(private store: Store<AppState>, private router: Router, protected logging: Logging) {
    super(logging);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store
      .select(selectPnrLocatorFlow)
      .subscribe((pnr) => (this.lookupMethod = pnr.lookupMethod))
      .unsubscribe();
    if (!environment.production) {
      this.logging.debugApiRequestStarted(request);
    }
    let shouldDispatchLoading = false;
    if (request.body) {
      shouldDispatchLoading = this.validateRequestForLoading(request, shouldDispatchLoading);
    }
    shouldDispatchLoading ? this.callToggleState(request, false) : this.callToggleState(request, true);

    return next.handle(request).pipe(
      tap(
        (event) => {
          // --- Logging stuff
          try {
            if (event instanceof HttpResponse) {
              if (!environment.production) {
                this.logging.debugApiRequestEnded(request, event);
              }
              this.callToggleState(request, false);
              this.handlePnrLookupResponse(request, event, this.lookupMethod);
              this.handlePatchTripsResponse(request, event);
              this.handlePatchPaxResponse(request, event);
              this.handleAlertReasonCodeResponse(request, event);
              this.handleCatalogsResponse(request, event);
              this.handleBagSaveSuccessful(request, event);
              this.handleGetCartSuccessful(request, event);
            }
          } catch (e) {
            this.logging.logException(e);
          }
          // --- End of logging stuff
          if (event instanceof HttpResponse) {
            const { body } = event;
            if (body) {
              if (body.count === 0) {
                this.store.dispatch(new SetTimeoutError());
              }
            }
          }
        },
        (error) => {
          const { url } = error;
          // --- Logging stuff
          try {
            if (error instanceof HttpErrorResponse) {
              if (!environment.production) {
                this.logging.debugApiRequestEnded(request, error);
              }
              this.callToggleState(request, false);
              this.logging.errorApiTrackException(request, error);
              this.handleCheckinFailed(request, error);
              this.handlePaxUpdateFailed(request, error);
              this.handleCatalogsFailed(request, error);
              this.handleBagSaveFailed(request, error);
              this.handleGetCartFailed(request, error);
            }
          } catch (e) {
            this.logging.logException(e);
          }
          // --- End of logging stuff
          if (error instanceof HttpErrorResponse) {
            if (
              request.method === 'DELETE' ||
              this.checkCartConcurrency(error) ||
              /machine-config/.test(url) ||
              /launcher/.test(url) ||
              /bags/.test(url)
            ) {
              return;
            }

            if (/(\/passenger|\/catalogs)/.test(url)) {
              throw error;
            }

            switch (error.status) {
              case 409:
                if (this.lookupMethod === 'creditCard' && error.error.count > 1) {
                  this.router.navigate([AppRoutes.LOCATE_RESERVATION_BIRTHDAY]);
                  return;
                }

                this.logging.infoApiPnrFailed(request, error);

                if (error.error && error.error.count > 0) {
                  this.store.dispatch(new SetReferToAgentDontPrintError());
                  throw error;
                }

                const entry = getFirstParameterThatMatches(LOCATE_METHODS, url);
                if (entry) {
                  this.store.dispatch(new SetNotFoundError({ entry }));
                  throw error;
                }

                this.store.dispatch(new SetReferToAgentDontPrintError());
                throw error;
              default:
                this.store.dispatch(new SetTimeoutError());
                throw error;
            }

            // Make sure logging gets it.
            throw error;
          }
        }
      )
    );
  }

  private validateRequestForLoading(request: HttpRequest<any>, shouldDispatchLoading: boolean) {
    if (/(\/bags)/.test(request.url)) {
      shouldDispatchLoading = true;
    } else if (
      request.body.documentType !== null &&
      request.body.documentType !== undefined &&
      /(\/documents)/.test(request.url)
    ) {
      shouldDispatchLoading = request.body.documentType.match(/^(receipt)$/);
    }
    return shouldDispatchLoading;
  }

  callToggleState(request, state) {
    if (request.method !== 'DELETE') {
      this.store.dispatch(new ToggleState({ url: request.url, state }));
    }
  }

  checkCartConcurrency(response) {
    if (response.error && response.error.errors) {
      const codeObj = response.error.errors.map((errorObj) => errorObj.code);
      if (
        (/\/carts/.test(response.url) && codeObj.indexOf(400) >= 0) ||
        (/\/checkout/.test(response.url) && codeObj.indexOf(500) >= 0 && !/payment/.test(this.router.url))
      ) {
        this.store.dispatch(new SessionEnd());
        return true;
      }
    }
    return false;
  }
}
