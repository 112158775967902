import { KeyboardActionTypes } from './keyboard.action';

export interface KeyboardState {
  content?: string;
  goBack?: boolean;
  append?: boolean;
}

export const initialState: KeyboardState = {
  content: '',
  goBack: false,
  append: false,
};

export function keyboardReducer(state = initialState, action): KeyboardState {
  switch (action.type) {
    case KeyboardActionTypes.SetContent:
      return {
        content: action.entry,
        goBack: false,
        append: false,
      };

    case KeyboardActionTypes.AppendContent:
      return {
        content: state.content + action.entry,
        goBack: false,
        append: true,
      };

    case KeyboardActionTypes.ResetContent:
      return {
        content: '',
        goBack: false,
        append: false,
      };

    case KeyboardActionTypes.GoBack:
      return {
        content: state.content,
        goBack: true,
        append: false,
      };

    case KeyboardActionTypes.ResetState:
      return {
        content: state.content,
        goBack: false,
        append: false,
      };

    default:
      return state;
  }
}
